import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { createQuery, format } from "../providers/database";
import { firebaseApp } from "../providers/firebase";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { errorLogger } from "../providers/clientLogger";
import { User } from "../dataTypes/User";

export const db = getFirestore(firebaseApp);

export function useAffiliations(id: string) {
  return useQuery(
    ["allAffiliations", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.users}/${id}/${subCollectionNames.affiliations}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function addUserAffiliation(
  userId: string,
  branch: string,
  affiliation: string,
) {
  return addDoc(
    collection(
      db,
      `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
    ),
    { branch: branch, affiliation: affiliation },
  );
}

export function deleteUserAffiliation(userId: string, affiliationId: string) {
  return deleteDoc(
    doc(
      db,
      `${collectionNames.users}/${userId}/${subCollectionNames.affiliations}`,
      affiliationId,
    ),
  );
}

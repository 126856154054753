import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import {
  useDropzone,
  DropzoneOptions,
  FileRejection,
  DropEvent,
} from "react-dropzone";
import SuccessCircleCheck from "../success/SuccessCircleCheck";

function DragAndDropVariantA({ setFile }: { setFile: Function }) {
  // onDragEnter, onDragLeave, onDragOver, onDrop
  const [eventState, setEventState] = useState();
  const [fileName, setFileName] = useState();

  const dragDropEvents = {
    onDragEnter: "onDragEnter",
    onDragLeave: "onDragLeave",
    onDragOver: "onDragOver",
    onDrop: "onDrop",
    onDropAccepted: "onDropAccepted",
  };

  const dropEventStyles = () => {
    if (eventState === dragDropEvents.onDragEnter)
      return `bg-instant-teams-green-L2`;
    if (eventState === "onDragLeave") return;
    if (eventState === "onDragOver") return `bg-instant-teams-green-L2`;
    if (eventState === "onDrop") return;
  };

  const isDropAccepted = eventState === dragDropEvents.onDropAccepted;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length < 1) {
      return;
    }
    setEventState(dragDropEvents.onDrop);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      setFile(file);
      const { size } = file;
      // add a files size limit
      // filename used in the ready message only
      setFileName(file.name);

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
      },
      multiple: false,
      maxFiles: 1,
      onDragEnter: () => {
        setEventState(dragDropEvents.onDragEnter);
        console.log("entering dropzone");
      },
      onDragLeave: () => {
        setEventState(dragDropEvents.onDragLeave);
        console.log("leaving dropzone");
      },
      onDragOver: () => {
        setEventState(dragDropEvents.onDragOver);
        console.log("over dropzone");
      },
      onDropAccepted: () => {
        setEventState(dragDropEvents.onDropAccepted);
      },
    });

  return (
    <>
      <section>
        <div {...getRootProps()}>
          <input {...getInputProps()} type="file" />
          <div
            className={`p-1 shadow-md bg-white border divide-x-4 border-instant-teams-teal-Main border-dashed rounded transition-colors ${dropEventStyles()}`}
          >
            {isDropAccepted ? (
              <SuccessCircleCheck message={`Ready to upload ${fileName}!`} />
            ) : (
              <div className="flex flex-col justify-center">
                {" "}
                <FontAwesomeIcon
                  className="p-5 rounded text-instant-teams-teal-Main"
                  icon={faUpload}
                  size="2xl"
                />
                <p className="text-instant-teams-blue-Main text-center">
                  Drag & Drop or Browse to Upload
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default DragAndDropVariantA;

import Auth from "../../../auth/Auth";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import { optionsByType } from "../../../auth/signInOptions";
import { Link } from "react-router-dom";
import DownloadOurMobileApp from "../parts/DownloadOurMobileApp";
import RotatorDisplay from "../../../../pages/seekerRotator/parts/rotatorDisplay";
import useModal from "../../../../hooks/useModal";

function SeekerAuthSection({
  type = "",
  providers = [],
  afterAuthPath,
}: {
  type: string;
  providers: string[];
  afterAuthPath: string;
}) {
  const { isShowing: show12MLogin, toggle: toggle12MLogin } = useModal();
  const marketUrl = process.env.REACT_APP_MARKET_URL;
  const protocol = window.location.protocol;

  const options = type
    ? optionsByType[type as keyof typeof optionsByType]
    : null;
  return (
    <>
      <div className="flex min-h-screen mt-8 bg-white overflow-hidden">
        <div className="w-2/3 xs:w-full sm:w-full flex flex-col items-center md:w-1/2  justify-center ">
          <h1 className="text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
            Career Seeker {options?.title}
          </h1>
          <Auth
            type={type}
            buttonAction={options?.buttonAction}
            providers={providers}
            afterAuthPath={afterAuthPath}
            key={type}
          />
          {options?.title === "Login" && (
            <>
              <div className="flex flex-col items-center justify-center  mb-2 w-48 max-w-md">
                <p className='text-xxs uppercase mt-4'>Login with</p>
                <img
                  src='/images/12-mil-plus/12-mil-logo.png'
                  onClick={toggle12MLogin}
                  alt="12 Million Plus"
                  className="w-24 mb-4 cursor-pointer"
                />
                <Link
                  to="/seeker/create-account"
                  className="my-2 underline text-gray-500"
                >
                  {"Create Account"}
                </Link>
                <Link
                  to="/auth/forgotpass"
                  className="my-2 underline text-gray-500"
                >
                  {"Forgot Password?"}
                </Link>
              </div>
              <DownloadOurMobileApp />
              <div className="mt-10 flex flex-col items-center">
                <a
                  href={`${protocol}//${marketUrl}/auth/signin`}
                  className="flex items-center justify-center text-center mb-6 font-semibold underline"
                >
                  Looking to Hire? Click here!
                </a>
              </div>
              {show12MLogin && (
                <DialogWrapper title="" titleColor={"bg-community-colors-secondary"} bgColor={"bg-community-colors-secondary"} onClose={toggle12MLogin}>
                  <div className="flex flex-col justify-center items-center ">
                    <div className="my-2">
                      <img
                        src="/images/12-mil-plus/12-mil-logo.png"
                        alt="12 Million +"
                        className="w-24 h-24 mb-4 mt-1 items-center text-center rounded-full "
                      />
                    </div>
                    <Auth
                      type={type}
                      buttonAction={options?.buttonAction}
                      providers={providers}
                      afterAuthPath={afterAuthPath}
                      key={type}
                      buttonColor={"bg-community-colors-button"}
                      buttonHoverColor={"hover:bg-community-colors-buttonHover"}
                    />
                  </div>
                </DialogWrapper>
              )}
            </>
          )}
        </div>
        <div className="w-1/3 min-h-screen right-0 fixed overflow-hidden sm:hidden xs:hidden">
          <RotatorDisplay />
        </div>
      </div>
    </>
  );
}

export default SeekerAuthSection;

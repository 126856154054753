import { useUser } from "../../providers/database";
import { firstNameOnly } from "../../util/strings/stringManipulation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Affiliations from "../user/details/parts/Affiliations";
import {
  useApplication,
  useApplicationPositions,
  useApplicationStatusHistory,
} from "../../data/applications";
import { Application } from "../../dataTypes/Application";
import { useLookup } from "../../data/lookup";
import SeekerPositionListItem from "../seeker/details/parts/seekerPositionListItem";
import { useJobDescription } from "../../data/jobDescriptions";

import { isArray } from "lodash";
import Tooltip from "../custom-ui/tooltip/tooltip";
import { useState, useEffect } from "react";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { useFindApplicationStatus } from "../../data/status";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import useModal from "../../hooks/useModal";
import ApplicationDialogWrapper from "../custom-ui/dialog/ApplicationDialog";
import RedactedApplicationModal from "./RedactedApplicationModal";

function RedactedApplicationItem({
  application,
  showJobDescription,
}: {
  application: Application;
  showJobDescription: boolean;
}) {
  const { isShowing, toggle } = useModal();
  const [statusDate, setStatusDate] = useState("");

  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    application.resumeId,
  );
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(application.objectID || application?.id);

  const { data: jobDescription = {}, status: jobDescriptionQueryStatus } =
    useJobDescription(application.jobId);

  const { data: applicationStatusHistory } = useApplicationStatusHistory(
    application?.objectID,
  );

  const { data: statusData = {}, status: statusQueryStatus } =
    useFindApplicationStatus(application?.status);

  const { data: countries } = useLookup("countries");

  const seekerCountry = countries?.find(
    (country: any) => country?.code === applicant?.address?.country,
  );
  const { data: states } = useLookup("state");

  const seekerState = states?.find(
    (state: any) => state?.code === applicant?.address?.state,
  );

  const { data: positions } = useApplicationPositions(
    application.objectID || application?.id,
  );

  const colorJobListingVariants: any = {
    ready: "bg-instant-teams-tags-BLUE",
    bench: "bg-instant-teams-tags-ROSE",
    underReview: "bg-instant-teams-tags-GREEN",
    invited: "bg-instant-teams-tags-GOLD",
    presented: "bg-instant-teams-tags-TEAL",
    applied: "bg-instant-teams-tags-ORANGE",
    conversation: "bg-instant-teams-tags-BLUE",
    knockout: "bg-instant-teams-purple-Main",
  };
  useEffect(() => {
    if (
      !isArray(applicationStatusHistory) ||
      applicationStatusHistory.length === 0
    ) {
      setStatusDate("Unchanged");
    } else {
      setStatusDate(
        DisplayTimestamp(applicationStatusHistory[0].effectiveDate),
      );
    }
  }, [applicationStatusHistory]);

  return (
    <>
      <div
        key={application?.objectID || application?.id}
        onClick={() => toggle()}
        className={
          TailwindHoverEnlarge(103) +
          `shadow-lg flex bg-white rounded-lg min-h-full mb-4 cursor-pointer`
        }
      >
        {applicantQueryStatus === "success" && (
          <div className={`w-full grid grid-cols-6 gap-2 h-full`}>
            <div className="cursor-pointer w-full flex items-center justify-center mb-2 p-2 col-span-2 h-full bg-instant-teams-teal-Main pt-2 pb-4   rounded-l-lg ">
              <div className="flex flex-col items-center   ">
                <p className="uppercase text-white text-xl pb-2">{`${firstNameOnly(
                  applicant?.name,
                )}`}</p>
                <div className=" space-x-1 w-full text-white mb-2 h-8  ">
                  <FontAwesomeIcon size="xl" icon={faMapMarkerAlt} />
                  <span className="text-lg">
                    {seekerState
                      ? `${seekerState?.label}, ${seekerCountry?.label}`
                      : `${seekerCountry?.label}`}
                  </span>
                </div>
                <div className="flex font-black -mt-1 text-instant-teams-teal-D1 ">
                  <Affiliations
                    userId={application.resumeId}
                    background={"darkBackground"}
                  />
                </div>
              </div>
            </div>
            <div className="text-sm col-span-2 mx-auto py-4 h-36">
              {showJobDescription && (
                <p className="text-base text-black pb-2 pt-1">
                  {jobDescription?.title}
                </p>
              )}
              {positions &&
                positions.length > 0 &&
                positions.map((position: any) => {
                  if (position.active === true) {
                    return (
                      <SeekerPositionListItem
                        key={position.id}
                        positionId={position.id}
                        jobId={application.jobId}
                      />
                    );
                  }
                })}
            </div>

            <div className="w-full h-full col-span-2 pt-2 pl-4 pr-4 text-sm pb-2">
              <Tooltip text={statusData?.description || "Current Status"}>
                <div
                  className={`items-center w-full mt-2 rounded-md text-white mx-auto p-1 mb-3 ${
                    colorJobListingVariants[jobApplicationData?.status] ||
                    "bg-instant-teams-purple-Main"
                  }`}
                >
                  <p className="text-center">{statusData?.label}</p>
                </div>
              </Tooltip>
              <div>Status Change: </div>
              <div>{statusDate} </div>
            </div>
          </div>
        )}
      </div>
      {isShowing ? (
        <>
          <ApplicationDialogWrapper
            toggle={() => toggle()}
            open={isShowing}
            title={firstNameOnly(applicant?.name)}
          >
            <RedactedApplicationModal
              toggle={toggle}
              application={application}
            />
          </ApplicationDialogWrapper>
        </>
      ) : null}
    </>
  );
}

export default RedactedApplicationItem;

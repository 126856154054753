import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApplication } from "../../../data/applications";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer } from "../../../data/employer";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import AutoSaveSelectByRole from "../../custom-ui/input-fields/auto-save-select-by-role";
import { useAuth } from "../../../providers/auth";

function ApplicationSidebarHeader() {
  const { userClaims } = useAuth();
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const [jobId, setJobId] = useState("");
  const [employerId, setEmployerId] = useState("");
  const [logo, setLogo] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [title, setTitle] = useState("");

  const { data: application } = useApplication(applicationId);
  const { data: jobData, status: jobStatus } = useJobDescription(jobId);
  const { data: employer, status: employerStatus } = useEmployer(employerId);

  useEffect(() => {
    if (jobData) {
      setTitle(jobData?.title);
    }
  }, [jobData]);

  useEffect(() => {
    if (employer) {
      setLogo(employer?.logo);
      setDisplayName(employer?.displayName);
    }
  }, [employer]);

  useEffect(() => {
    if (application) {
      setJobId(application?.jobId);
      setEmployerId(application?.employer);
    }
  }, [application]);

  return (
    <div className="">
      <div className="items-center pt-4 pr-3 pb-3">
        <img
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
        />
        <div className="text-center text-xl font-bold pb-1 pt-3">
          {displayName}
        </div>
        <div className="text-center text-xl font-bold pb-5">{title}</div>
      </div>
      <div className="items-center pl-3 pr-3 pb-3">
        {userClaims?.claims?.type === "A" && applicationId && (
          <div className="mb-6">
            <AutoSaveSelectByRole
              value={application?.TAC}
              role="tac"
              collection="Applications"
              recId={applicationId}
              required={false}
              name="TAC"
              display="Talent Acquisition Coordinator"
              placeholder="Talent Acquisition Coordinator"
              directions=""
            />
          </div>
        )}
        {userClaims?.claims?.type === "A" && application?.status && (
          <AutoSaveStatusSelector
            status={application?.status}
            collection="Applications"
            recId={applicationId}
            required={false}
            name="status"
            display="Status"
            placeholder=""
            directions=""
          />
        )}
      </div>
    </div>
  );
}

export default ApplicationSidebarHeader;

import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { Invitation } from "../dataTypes/Invitation";
import { collectionNames } from "./dictionary/collectionNames";
import { errorLogger } from "../providers/clientLogger";

/**** Meetings ****/
// Subscribe to meeting data
export function useMeeting(meetingId: string) {
  return useQuery(
    ["meeting", { meetingId }],
    createQuery(() => doc(db, collectionNames.meetings, meetingId)),
    { enabled: !!meetingId },
  );
}

export function useMeetingsByRequestor(userId: string) {
  return useQuery(
    ["ScheduledMeetings", { userId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
        where("status", "==", "scheduled"),
        orderBy("meetingDetails.startTime", "asc"),
      ),
    ),
    { enabled: !!userId },
  );
}
export function useAllMeetingsByRequestor(userId: string) {
  return useQuery(
    ["AllMeetingsByUser", { userId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
      ),
    ),
    { enabled: !!userId },
  );
}

export function useAllMeetingsByRequestorFuture(userId: string) {
  const today = Timestamp.now();
  return useQuery(
    ["AllMeetingsByUserFuture", { userId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
        where("meetingDetails.startTime", ">=", today),
        orderBy("meetingDetails.startTime", "asc"),
      ),
    ),
    { enabled: !!userId },
  );
}

export function useAllMeetingsByRequestorFutureByStatus(userId: string, status: string) {
  const today = Timestamp.now();
  return useQuery(
    ["AllMeetingsByUserFutureByStatus", { userId, status }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
        where("status", "==", status),
        where("meetingDetails.startTime", ">=", today),
        orderBy("meetingDetails.startTime", "asc"),
      ),
    ),
    { enabled: !!userId && !!status },
  );
}

export function useAllMeetingsByRequestorPastByStatus(userId: string, status: string) {
  const today = Timestamp.now();
  return useQuery(
    ["AllMeetingsByUserFutureByStatus", { userId, status }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
        where("status", "==", status),
        where("meetingDetails.startTime", "<=", today),
        orderBy("meetingDetails.startTime", "asc"),
      ),
    ),
    { enabled: !!userId && !!status },
  );
}

export function usePendingMeetingsByRequestor(userId: string) {
  const today = Timestamp.now();
  return useQuery(
    ["AllPendingMeetingsByRequestor", { userId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("invitedBy", "==", userId),
        where("status", "in", ["sent", "pending"]),
        orderBy("createdAt", "asc"),
      ),
    ),
    { enabled: !!userId },
  );
}


export function useMeetingsByInvitee(userId: string) {
  return useQuery(
    ["Meetings-Seeker", { userId }],
    createQuery(() =>
      query(
        collection(db, `${collectionNames.meetings}`),
        where("inviteeId", "==", userId),
        where("status", "==", "scheduled"),
        orderBy("meetingDetails.startTime", "asc"),
      ),
    ),
    { enabled: !!userId },
  );
}

// Subscribe to meeting data
export function useMeetingsByApplicationId(applicationId: string) {
  return useQuery(
    ["meetingsByApplicationId", { applicationId }],
    createQuery(() =>
      query(
        collection(db, collectionNames.meetings),
        where("refId", "==", applicationId),
      ),
    ),
    { enabled: !!applicationId },
  );
}

export function useMeetingsOneOnOne({
  refId,
  refType,
  invitedBy,
  inviteeId,
}: {
  refId: string;
  refType: string;
  invitedBy: string;
  inviteeId: string;
}) {
  return useQuery(
    ["activeMeetings", { refId, refType, invitedBy, inviteeId }],
    createQuery(() =>
      query(
        collection(db, collectionNames.meetings),
        where("refType", "==", refType),
        where("refId", "==", refId),
        where("inviteeId", "==", inviteeId),
        where("invitedBy", "==", invitedBy),
        where("status", "not-in", ["complete", "cancelled", "expired"]),
      ),
    ),
    { enabled: !!refType && !!refId && !!invitedBy && !!inviteeId },
  );
}

export function useMeetingsOneOnOneInactive({
  refId,
  refType,
  invitedBy,
  inviteeId,
}: {
  refId: string;
  refType: string;
  invitedBy: string;
  inviteeId: string;
}) {
  return useQuery(
    ["inactiveMeetings", { refId, refType, invitedBy, inviteeId }],
    createQuery(() =>
      query(
        collection(db, collectionNames.meetings),
        where("refType", "==", refType),
        where("refId", "==", refId),
        where("inviteeId", "==", inviteeId),
        where("invitedBy", "==", invitedBy),
        where("status", "in", ["complete", "cancelled", "expired"]),
      ),
    ),
    { enabled: !!refType && !!refId && !!invitedBy && !!inviteeId },
  );
}

export function createMeetingInvitation({
  refType,
  refId,
  title,
  meetingData,
}: {
  refType: string;
  refId: string;
  title: string;
  meetingData: {
    meetingCode: string;
    extended: any;
    inviteeId: string;
    inviteeEmail: string;
    invitedBy: string;
    calendlyLink?: string;
    dateTimeOptions?: any[];
    meetingDuration?: number;
    status: string;
  };
}) {
  // collect required meeting fields
  // extract those that may not always apply and conditionally send
  const { calendlyLink, dateTimeOptions, meetingDuration, ...meeting } =
    meetingData ?? {};

  return addDoc(collection(db, collectionNames.meetings), {
    // refType and refId allow us to determine the relative context of the meeting
    // e.g. refType: application, refId: s2lkApplicationIdewh7M - refId is an applicationId
    // e.g. refType: careerSeeker, refId: vr89ZkiGInviteeIdwZC0xRNA3 - refId is a careerSeekerId
    refType,
    refId,
    meetingDetails: { title },
    ...(calendlyLink && { calendarLink: calendlyLink }),
    ...(dateTimeOptions && { inAppScheduleOptions: dateTimeOptions }),
    ...(meetingDuration && { inAppScheduleDuration: meetingDuration }),
    ...meeting,
    createdAt: Timestamp.now(),
  });
}
// For writing calendly details to meeting document
// we use these uri's to get data about the calendly event
export function useMeetingsByUserId(userId: string) {
  return useQuery(
    ["meetingsByUserId", { userId }],
    createQuery(() =>
      query(
        collection(db, collectionNames.meetings),
        where("inviteeId", "==", userId),
      ),
    ),
    { enabled: !!userId },
  );
}

export function createMeetingEvent(eventDetails: {
  eventUri: string;
  eventInviteeUri: string;
  meetingId: string;
}) {
  const { meetingId, ...details } = eventDetails ?? {};
  return updateDoc(doc(db, collectionNames.meetings, meetingId), {
    "extended.calendly": details,
  });
}

export function scheduleMeeting(meetingDetails: {
  meetingId: string;
  startTime: Date;
  endTime: Date;
}) {
  const { meetingId, startTime, endTime } = meetingDetails ?? {};
  console.log("MINE: ", meetingId, startTime, endTime);
  return updateDoc(doc(db, collectionNames.meetings, meetingId), {
    "meetingDetails.startTime": startTime,
    "meetingDetails.endTime": endTime,
  });
}

export function cancelMeeting({
  meetingId,
  status,
  cancelReason,
}: {
  meetingId: string;
  status: string;
  cancelReason?: string;
}) {
  return updateDoc(doc(db, collectionNames.meetings, meetingId), {
    status,
    "extended.cancelReason": cancelReason,
    "extended.cancelledAt": Timestamp.now(),
  });
}

export function rescheduleMeetingRequest({
  meetingId,
  status,
  rescheduleReason,
}: {
  meetingId: string;
  status: string;
  rescheduleReason?: string;
}) {
  return updateDoc(doc(db, collectionNames.meetings, meetingId), {
    status,
    "extended.rescheduleReason": rescheduleReason,
    "extended.rescheduledAt": Timestamp.now(),
  });
}

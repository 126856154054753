import { requireAuth, useAuth } from "../../../providers/auth";
import ExternalJobApplicants from "../../jobs/externalView/parts/ExternalJobApplicants";
import AdminJobApplicants from "../../jobs/parts/AdminJobAplications";
// @ts-ignore
import { InstantSearch } from "react-instantsearch-hooks-web";
import algoliasearch from "algoliasearch/lite";
import TacJobApplicants from "../../jobs/parts/TacJobApplicationListing";

const searchClient = (() => {
  if (!process.env.REACT_APP_ALGOLIA_APP_ID) {
    throw new Error(
      "REACT_APP_ALGOLIA_APP_ID environment variable not defined",
    );
  }
  if (!process.env.REACT_APP_ALGOLIA_API_KEY) {
    throw new Error(
      "REACT_APP_ALGOLIA_API_KEY environment variable not defined",
    );
  }

  return algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
  );
})();

const TacJobApplications = () => {
  const { userClaims } = useAuth();

  return (
    <div className="min-h-screen">
      <InstantSearch indexName="Applications" searchClient={searchClient}>
        <TacJobApplicants />
      </InstantSearch>
    </div>
  );
};

export default requireAuth(TacJobApplications);

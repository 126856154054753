import useApplicationRecordings from "../../hooks/useApplicationRecordings";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleQuestion,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useApplication } from "../../data/applications";
import ButtonWithLoading from "../custom-ui/button/ButtonWithLoading";
import { updateStatus } from "../../data/applications";
import { useAuth } from "../../providers/auth";
import { useJobDescription } from "../../data/jobDescriptions";
import { errorLogger } from "../../providers/clientLogger";
import EInterviewDetailView from "./EInterviewDetailView";
import OveralProgress from "../application/parts/OverallProgress";

function DetailView({
  title,
  index,
  result,
}: {
  title: string;
  index: any;
  result: string;
}) {
  const { user } = useAuth();
  function getResult(result: string) {
    switch (result) {
      case "pending":
        return (
          <FontAwesomeIcon icon={faCircleQuestion} size="xl" color="gray" />
        );
      case "success":
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=" text-instant-teams-green-Main"
            size="xl"
          />
        );
      case "failed":
        return (
          <FontAwesomeIcon
            icon={faCircleXmark}
            style={{ color: "#FF8A24" }}
            size="xl"
          />
        );

      default:
        errorLogger("Missing result switch case", {
          component: "DetailView ReviewAndSubmitView",
          flow: "Detail View switch(result)",
          userId: user.uid,
        });
        break;
    }
  }
  return (
    <div className="flex justify-between lg:w-full my-5" key={index}>
      <p className="pl-4 w-64">{title}</p>

      {getResult(result)}
    </div>
  );
}
function ReviewAndSubmitView({
  succeeded,
  badges,
}: {
  succeeded: Boolean;
  badges: any;
}) {
  const navigate = useNavigate();
  const { applicationId = "" } = useParams();
  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { data: jobData } = useJobDescription(applicationData?.jobId);
  const { user } = useAuth();
  const { recordings } = useApplicationRecordings(
    applicationId,
    applicationData?.jobId,
  );
  const [submitting, setSubmitting] = useState(false);
  const [resumeSelected, setResumeSelected] = useState(false);

  useEffect(() => {
    if (!isApplicationDataLoading) {
      // return true when neither are selected
      const isResumeSelected =
        applicationData?.resumeFileId === "" &&
        applicationData?.manualResumeSelected === false;

      setResumeSelected(isResumeSelected);
    }
  }, [applicationData]);
  const disabled =
    (jobData?.requireResume && resumeSelected) ||
    recordings.length === 0 ||
    !succeeded ||
    submitting ||
    recordings.some(({ answer }: any) => !answer);

  const submitApplication = async () => {
    if (!user) return;
    setSubmitting(true);
    try {
      await updateStatus(
        applicationData?.id,
        jobData.sample ? "closed" : "ready",
      );
      setSubmitting(false);
      navigate(`/mission-complete/${applicationData?.id}`);
    } catch (error: any) {
      errorLogger(error.message, {
        component: "ApplicationDetailsPage submitApplication()",
        flow: "Submit Application/ Seeker Applications",
        userId: user.uid,
      });
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="my-5">
        {applicationData?.overallProgress !== 100 ? (
          <OveralProgress application={applicationData} />
        ) : (
          <ButtonWithLoading
            pending={submitting}
            disabled={disabled || submitting}
            onClick={submitApplication}
            value={"Submit"}
          />
        )}
      </div>

      {disabled ? (
        <p className="text-instant-teams-blue-Main">
          Please complete the following requirements for this application:
        </p>
      ) : (
        <div className="text-instant-teams-blue-Main">
          <p>
            Way to go! From our end, it looks like everything is complete and
            ready to go.
          </p>
          <p>
            Once you&apos;ve made a final review, please submit your
            application.
          </p>
        </div>
      )}

      <div className="text-instant-teams-blue-Main">
        <div className="flex justify-between lg:w-full my-5">
          <p className="font-semibold">Pre-screen Questions</p>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=" text-instant-teams-green-Main"
            size="xl"
          />
        </div>
        {jobData?.requireResume ? (
          <div className="flex justify-between lg:w-full my-5">
            <p className="font-semibold">Resume</p>
            {applicationData?.resumeFileId !== "" ||
            applicationData?.manualResumeSelected !== false ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className=" text-instant-teams-green-Main"
                size="xl"
              />
            ) : (
              <FontAwesomeIcon icon={faCircleQuestion} size="xl" color="grey" />
            )}
          </div>
        ) : null}

        <div className="my-5 font-semibold">E-Interview</div>
        {recordings &&
          recordings.map(({ displayName, id, answer }: any, index: any) => {
            return (
              <EInterviewDetailView
                title={displayName ? displayName : `Question ${index + 1}`}
                isCompleted={!!answer}
                index={index}
              />
            );
          })}
        <div className="mt-5 font-semibold">Skill Assessments</div>

        {badges &&
          badges.map(({ result, skill }: any, index: any) => {
            return (
              <DetailView title={skill?.name} result={result} index={index} />
            );
          })}
      </div>
    </div>
  );
}

export default ReviewAndSubmitView;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  limit,
  deleteDoc,
} from "firebase/firestore";

import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";

// Community Dashboard Queries
export function useCommunityAttempts(email) {
  return useQuery(
    ["comm_attempts", { email }],
    createQuery(() =>
      query(
        collection(db, collectionNames.verifications),
        where("email", "==", email),
        orderBy("time", "desc"),
      ),
    ),
    { enabled: !!email },
  );
}

export function useCommunityFailures(email) {
  return useQuery(
    ["comm_errors", { email }],
    createQuery(() =>
      query(collection(db, "oauth2_failure_logs"), where("email", "==", email)),
    ),
    { enabled: !!email },
  );
}

export function useCommunityDeclines(email) {
  return useQuery(
    ["comm_declines", { email }],
    createQuery(() =>
      query(
        collection(db, collectionNames.declineDisagree, email),
      ),
    ),
    { enabled: !!email },
  );
}

export function useCommunityAuth(userId) {
  return useQuery(
    ["comm_auth", { userId }],
    createQuery(() =>
      query(collection(db, "oauth2_auth_info"), where("user_id", "==", userId)),
    ),
    { enabled: !!userId },
  );
}

export function useCommunityAuthByToken(token) {
  return useQuery(
    ["comm_token", { token }],
    createQuery(() =>
      query(
        collection(db, "oauth2_access_tokens"),
        where("auth_info_id", "==", token),
      ),
    ),
    { enabled: !!token },
  );
}
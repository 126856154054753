// initialized firebase app
import { firebaseApp } from "../providers/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import cloudFunctionNames from "./dictionary/cloudFunctionNames";

const functions = getFunctions(firebaseApp);

export const addressValidation = httpsCallable(
  functions,
  cloudFunctionNames.addressValidation,
);

export const offersDataAggregationCallable = httpsCallable(
  functions,
  cloudFunctionNames.offersDataAggregationCallable,
);

export const deptDashboard = httpsCallable(
  functions,
  cloudFunctionNames.deptDashboard,
);

export const usersByType = httpsCallable(
  functions,
  cloudFunctionNames.usersByType,
);

export const newUsersByDay = httpsCallable(
  functions,
  cloudFunctionNames.newUsersByDay,
);

export const newOpeningsByDay = httpsCallable(
  functions,
  cloudFunctionNames.newOpeningsByDay,
);

export const collectionCounts = httpsCallable(
  functions,
  cloudFunctionNames.collectionCounts,
);

export const sliLogger = httpsCallable(functions, cloudFunctionNames.sliLogger);

export const resetPassword = httpsCallable(
  functions,
  cloudFunctionNames.resetPassword,
);

export const updateResetPassword = httpsCallable(
  functions,
  cloudFunctionNames.updateResetPassword,
);

export const fetchEarnedBadges = httpsCallable(
  functions,
  cloudFunctionNames.fetchEarnedBadges,
);

export const PositionMetrics = httpsCallable(
  functions,
  cloudFunctionNames.positionMetrics,
);

export const outreachList = httpsCallable(
  functions,
  cloudFunctionNames.outreachList,
);

export const getOpenJobMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getOpenJobMetrics,
);

export const getPositionMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getPositionMetrics,
);

export const getApplicationTrendsByDay = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationTrendsByDay,
);

export const getTop10CoursesByCompletion = httpsCallable(
  functions,
  cloudFunctionNames.getTop10CoursesByCompletion,
);

export const getNewRegistrationMetrics = httpsCallable(
  functions,
  cloudFunctionNames.getNewRegistrationMetrics,
);

export const getAssessmentMetricsByStatus = httpsCallable(
  functions,
  cloudFunctionNames.getAssessmentMetricsByStatus,
);

export const getApplicationsPerPosition = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationsPerPosition,
);

export const getApplicationTrendsByDayByJobId = httpsCallable(
  functions,
  cloudFunctionNames.getApplicationTrendsByDayByJobId,
);

export const cleanupUserRole = httpsCallable(
  functions,
  cloudFunctionNames.cleanupUserRole,
);

export const cleanupOrphanedAuth = httpsCallable(
  functions,
  cloudFunctionNames.cleanupOrphanedAuth,
);

export const commCreateUser = httpsCallable(
  functions,
  cloudFunctionNames.commCreateUser,
);

export const commCheckUser = httpsCallable(
  functions,
  cloudFunctionNames.commCheckUser,
);

export const csvUsers = httpsCallable(functions, cloudFunctionNames.csvUsers);

export const deleteApplicationRecursively = httpsCallable(
  functions,
  cloudFunctionNames.deleteApplicationRecursively,
);

import React, { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import PDFViewer from "../../pdf/pdfViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { updateAutoSave } from "../../../data/utility";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useEmployer } from "../../../data/employer";
import { useShareByTypeAndId } from "../../../data/share";
import { useAuth } from "../../../providers/auth";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
import EditSocialMeta from "../../socials/EditSocailMeta";
import { getRoot } from "../../../util/urlHelper";
import { Timestamp } from "firebase/firestore";
import { createShare } from "../../../data/share";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import {
  faPenToSquare,
  faEye,
  faRotate,
  faTrash,
  faPlusCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { renderJobToStaticHTML } from "../../../util/static/renderStatic";
import JobListingShare from "../../socials/JobListingDetails";
import { useActiveJobPositions } from "../../../data/positions";
import { useJobDescriptionSkills } from "../../../data/jobDescriptions";
import { useEmployerData } from "../../../hooks/useApplicationEmployerData";

const JobListingSocials = ({ id }: { id: string }) => {
  const { user } = useAuth();
  const { data: job = {} } = useJobDescription(id);
  const { employerId, employerData: employer } = useEmployerData({
    jobData: job,
    originalEmployerId: job?.employer,
  });

  const { data: share = {} } = useShareByTypeAndId("job", id);
  const { data: jobPositions } = useActiveJobPositions(id);
  const { data: jobDescriptionSkills } = useJobDescriptionSkills(id);
  const [shareId, setShareId] = useState(share[0]?.id ?? "");
  const [baseUrl, setBaseUrl] = useState(getRoot());
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    setShareId(share[0]?.id ?? "");
  }, [share]);

  async function shareJob() {
    const shareInfo = {
      type: "job",
      typeId: job?.id,
      data: {
        employer: {
          logo: employer?.logo,
          displayName: employer?.displayName,
        },
        job: {
          title: job?.title,
          description: job?.description,
        },
        jobPositions: jobPositions,
        jobDescriptionSkills: jobDescriptionSkills,
      },
      who: user?.uid,
      // title: "",
      // description: "",
      active: true,
      createdAt: Timestamp.fromDate(new Date()),
    };
    if (shareId) {
      await updateAutoSave("Shares", shareId, shareInfo);
    } else {
      await createShare(shareInfo);
    }
    toggle();
  }

  async function removeShareJob() {
    await updateAutoSave("Shares", shareId, {
      active: false,
    });
  }

  async function renderShareJob() {
    const staticFile = await renderJobToStaticHTML(
      share,
      job,
      employer,
      jobPositions,
      jobDescriptionSkills,
    );
  }

  const SOCIALSWITCH = process.env.REACT_APP_SOCIAL_SHARE;

  return (
    <div className="w-full">
      {SOCIALSWITCH !== undefined && SOCIALSWITCH === "true" && (
        <>
          {share && share.length && share[0].active ? (
            <div className="text-center mb-4">
              <GreenUnderlineHeading title={"Socials"} noMargin={true}>
                <>
                  <button
                    className="text-instant-teams-blue-Main mr-4"
                    type="button"
                    onClick={toggle}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare as IconProp}
                      className="h-6 w-6"
                    />
                  </button>
                  <a
                    href={`${baseUrl}social/${share[0].id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="text-instant-teams-blue-Main mr-4"
                      type="button"
                    >
                      <FontAwesomeIcon
                        icon={faEye as IconProp}
                        className="h-6 w-6"
                      />
                    </button>
                  </a>
                  <button
                    className="text-instant-teams-blue-Main mr-4"
                    type="button"
                    onClick={renderShareJob}
                  >
                    <FontAwesomeIcon
                      icon={faRotate as IconProp}
                      className="h-6 w-6"
                    />
                  </button>
                  <button
                    className="text-instant-teams-blue-Main"
                    type="button"
                    onClick={removeShareJob}
                  >
                    <FontAwesomeIcon
                      icon={faTrash as IconProp}
                      className="h-6 w-6"
                    />
                  </button>
                </>
              </GreenUnderlineHeading>
              {/* <div className="mt-4">
                <GreenUnderlineHeading title={"Share To: "} noMargin={true}>
                  <div className='w-full grid grid-cols-3 gap-4'>
                    <FacebookShareButton
                      url={`${baseUrl}social/${share[0].id}`}
                      quote={share?.title || "New Job Announcement"}
                      hashtag={share?.hashtags || "#instantteams"}
                    >
                      <FacebookIcon size={28} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={`${baseUrl}social/${share[0].id}`}
                      title={share?.title || "New Job Announcement"} 
                      summary={share?.description || "Look at this great Job on Instant Teams Marketplace"}
                      source="Instant Teams Marketplace"
                    >
                      <LinkedinIcon size={28} round />
                    </LinkedinShareButton>
                    <TwitterShareButton 
                      url={`${baseUrl}social/${share[0].id}`}
                      title={job?.title || "New Job Announcement"}
                      via="Instant Teams Marketplace"
                      hashtags={share?.hashtags || "#instant-teams"}
                    >
                      <TwitterIcon size={28} round />
                    </TwitterShareButton>            
                  </div>
                </GreenUnderlineHeading>
              </div> */}
            </div>
          ) : (
            <GreenUnderlineHeading title={"Socials"} noMargin={true}>
              <button
                className="text-instant-teams-blue-Main"
                type="button"
                onClick={shareJob}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle as IconProp}
                  className="h-4 w-4"
                />
              </button>
            </GreenUnderlineHeading>
          )}
        </>
      )}
      {isShowing && share[0] && (
        <DialogWrapper
          title="Share Details"
          onClose={toggle}
          size="w-1/3 h-auto mt-36"
        >
          <EditSocialMeta share={share[0]} />
        </DialogWrapper>
      )}
    </div>
  );
};

export default JobListingSocials;

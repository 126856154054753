import React, { useEffect, useState } from "react";
import { useComponent } from "../../../data/academy";
import { getTierColor } from "../../../util/assessments/level";

function DisplayBadges({
  assessmentId,
  tier = null,
  size = 24,
  enrollment = null,
}: {
  assessmentId: string;
  tier?: string | null;
  size?: number;
  enrollment?: any;
}) {
  // test gorilla assessmentId's are numbers. When using them to search their corresponding record in firebase convert to a string,
  // as firebase document id's can only be strings

  const { data: assessment } = useComponent(assessmentId?.toString());
  const [overlayImage, setOverlayImage] = useState(null);

  useEffect(() => {
    if (assessment?.tier && enrollment && enrollment.status === "completed") {
      if (
        assessment.tier.one <= enrollment?.average &&
        assessment.tier.two > enrollment?.average
      ) {
        setOverlayImage("Proficient");
      } else if (
        assessment.tier.two <= enrollment?.average &&
        assessment.tier.three > enrollment?.average
      ) {
        setOverlayImage("Advanced");
      } else if (assessment.tier.three <= enrollment?.average) {
        setOverlayImage("Experienced");
      } else {
        if (enrollment && enrollment?.personality !== null) {
          setOverlayImage(enrollment?.personality);
        } else {
          setOverlayImage("Basic");
        }
      }
    }
    // if tier is present then this badge component is being
    // viewed from a job
    if (tier) {
      if (tier === "one") {
        setOverlayImage("Proficient");
      } else if (tier === "two") {
        setOverlayImage("Advanced");
      } else if (tier === "three") {
        setOverlayImage("Experienced");
      }
    }
  }, [assessment, enrollment]);

  return (
    <div className="items-center rounded-b-lg relative">
      <img
        src={assessment?.badge || "/images/badge.png"}
        alt={assessment?.name}
        className={`mx-auto w-${size} max-h-${size} rounded-xl`}
      />
      {enrollment && enrollment !== null && overlayImage !== null && (
        <div className="absolute left-0 bottom-0 w-full text-center">
          <span
            className={`text-white text-xs ${getTierColor(
              overlayImage,
            )} rounded-full px-2 py-1 mb-4`}
          >
            {overlayImage}
          </span>
        </div>
      )}
      {tier !== null && overlayImage !== null && (
        <div className="absolute left-0 bottom-0 w-full text-center">
          <span
            className={`text-white text-xs ${getTierColor(
              overlayImage,
            )} rounded-full px-2 py-1 mb-4`}
          >
            {overlayImage}
          </span>
        </div>
      )}
    </div>
  );
}

export default DisplayBadges;

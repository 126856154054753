import {
  useQuery,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  getDocs,
  query,
  getDoc,
  collection,
  getFirestore,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";

/**** Status ****/
// Find a status
export async function useStatusOptionsSelect(collectionName, id) {
  const _query = query(doc(db, collectionName, id));
  const currentStatus = await getDoc(_query);
  const options = currentStatus.data().options;
  const selectValues = [];
  for (const docId of options) {
    const newDoc = doc(db, collectionName, docId);
    if (newDoc.exists) {
      selectValues.push(newDoc.data());
    }
  }
  return selectValues;
}

export function useFindJobStatus(code) {
  return useQuery(
    ["Job Status Lookup", { code }],
    createQuery(() => doc(db, "JobStatus", code)),
    { enabled: !!code },
  );
}

export function useFindApplicationStatus(code) {
  return useQuery(
    ["Application Status Lookup", { code }],
    createQuery(() => doc(db, "ApplicationStatus", code)),
    { enabled: !!code },
  );
}

export function useFindOfferStatus(code) {
  return useQuery(
    ["Offer Status Lookup", { code }],
    createQuery(() => doc(db, "OfferStatus", code)),
    { enabled: !!code },
  );
}

export function useFindEmploymentStatus(code) {
  return useQuery(
    ["Employment Status Lookup", { code }],
    createQuery(() => doc(db, "EmploymentStatus", code)),
    { enabled: !!code },
  );
}

export function useFindMeetingStatus(code) {
  return useQuery(
    ["Meeting Status Lookup", { code }],
    createQuery(() => doc(db, "MeetingStatus", code)),
    { enabled: !!code },
  );
}

export function useAllStatuses(statusCollection) {
  return useQuery(
    ["allStatuses", { statusCollection }],
    createQuery(() => {
      return query(collection(db, statusCollection));
    }),
    { enabled: true },
  );
}

import { navBarRoute } from "../dataTypes/ReactRouter";
// routes for the navbar, that get passed into a NavLink component
// routes render based on userType
export const ALL_ADMIN = [
  "sadmin",
  "admin",
  "sales",
  "tac",
  "csss",
  "pops",
  "learn",
  "finance",
  "marketing",
  "general",
];
export const navbarRoutes: navBarRoute[] = [
  {
    // admin routes
    id: "adminDashboard",
    link: "/dashboard",
    name: "Dashboard",
    viewer: ALL_ADMIN,
  },
  {
    id: "adminEmployers",
    link: "/employers",
    name: "Employers",
    viewer: ALL_ADMIN,
  },
  {
    id: "adminOpenings",
    link: "/jobs",
    name: "Jobs",
    viewer: ALL_ADMIN,
  },
  {
    id: "seekerManagement",
    link: "/seekers",
    name: "Seekers",
    viewer: ALL_ADMIN,
  },
  {
    id: "adminAcademy",
    link: "/academy",
    name: "Academy",
    viewer: ALL_ADMIN,
  },
  {
    id: "adminL&D",
    link: "/learning",
    name: "L&D",
    viewer: ["admin", "sadmin", "learn"],
  },
  {
    id: "adminUserManagement",
    link: "/users",
    name: "Users",
    viewer: ["sadmin", "admin"],
  },
  // employer routes
  {
    id: "employerDashboard",
    link: "/dashboard",
    name: "Dashboard",
    viewer: ["GEN", "BILL", "PRI"],
  },
  {
    id: "company",
    link: "/company",
    name: "Company",
    viewer: ["GEN", "PRI"],
  },
  {
    id: "employerJobs",
    link: "/jobs",
    name: "Jobs",
    viewer: ["GEN", "BILL", "PRI"],
  },
  {
    id: "ExternalUserManagemet",
    link: "/users",
    name: "Users",
    viewer: ["PRI"],
  },
  {
    id: "companyNotes",
    link: "/notes",
    name: "Notes",
    viewer: ["PRI", "GEN", "BILL"],
  },
  // seeker routes

  {
    id: "seekerJobs",
    link: "/jobs",
    name: "Jobs",
    viewer: ["SKR", "RTM", "COMM"],
  },
  {
    id: "seekerCompanies",
    link: "/companies",
    name: "Companies",
    viewer: ["SKR", "RTM", "COMM"],
  },
  {
    id: "seekerApplications",
    link: "/applications",
    name: "Applications",
    viewer: ["SKR", "RTM", "COMM"],
  },
  {
    id: "seekerOffers",
    link: "/offers",
    name: "Offers",
    viewer: ["SKR", "RTM", "COMM"],
  },
  {
    id: "seekerAcademy",
    link: "/academy",
    name: "Academy",
    viewer: ["SKR", "RTM", "COMM"],
  },
];

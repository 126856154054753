import { firstNameOnly } from "../../util/strings/stringManipulation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Affiliations from "../user/details/parts/Affiliations";
import { useLookup } from "../../data/lookup";
import { useUser } from "../../data/user";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { useState } from "react";
import ModalInterviewItem from "./parts/ModalInterviewItem";
import { collectionNames } from "../../data/dictionary/collectionNames";
import ModalInterviewAnswerItem from "./parts/ModalInterviewAnswerItem";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";

import ExternalNotesModalView from "./parts/ExternalNotesModalView";
import DeclineApplication from "../jobs/parts/DeclineApplication";
import ScheduleMeetingActions from "../live/ScheduleMeetingActions";
import { useJobDescription } from "../../data/jobDescriptions";
import { useEmployer } from "../../data/employer";
import { useApplicationPositions } from "../../data/applications";
import ApplicationPositionItem from "./ApplicationPositionItem";
import { useMeetingsByApplicationId } from "../../data/meetings";
import MeetingList from "../live/MeetingList";

import SaveForLaterApplicationModal from "../jobs/parts/SaveForLaterApplication";
import ButtonWithLoading from "../custom-ui/button/ButtonWithLoading";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";
import { updateAutoSave } from "../../data/utility";
import { createNotification } from "../../data/notifications";
import { createNote } from "../../data/notes";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../providers/auth";
import { useNavigate } from "react-router-dom";
import ExternalBadgesModalView from "./parts/ExternalBadgesModalView";

function RedactedApplicationModal({
  toggle,
  application,
}: {
  toggle: Function;
  application: any;
}) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userName = user?.preferredName ?? user?.name;
  const [preOfferPending, setPreOfferPending] = useState(false);
  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    application.resumeId,
  );

  const { data: jobDescriptionData } = useJobDescription(application?.jobId);

  const { data: employerData } = useEmployer(application?.employer);

  const { data: positions } = useApplicationPositions(application?.objectID);

  // Application meeting list
  const { data: meetings = [] } = useMeetingsByApplicationId(
    application?.objectID,
  );
  // Filter meeting list
  const scheduledMeetings = meetings.filter(
    (meeting: any) => meeting.status === "scheduled",
  );

  const rescheduleRequest = meetings.filter(
    (meeting: any) => meeting.status === "reschedule",
  );

  const awaitingResponse = meetings.filter(
    (meeting: any) => meeting.status === "sent" || meeting.status === "pending",
  );

  const complete = meetings.filter(
    (meeting: any) => meeting.status === "complete",
  );

  const cancelledAndExpired = meetings.filter(
    (meeting: any) =>
      meeting.status === "cancelled" || meeting.status === "expired",
  );

  const sortedMeetings = [
    ...rescheduleRequest,
    ...scheduledMeetings,
    ...awaitingResponse,
    ...complete,
    ...cancelledAndExpired,
  ];

  const { data: countries } = useLookup("countries");

  const seekerCountry = countries?.find(
    (country: any) => country?.code === applicant?.address?.country,
  );
  const { data: states } = useLookup("state");
  const { isShowing: rescindOffer, toggle: toggleRescindApplication } =
    useModal();
  const { isShowing: isSaveForLaterShowing, toggle: toggleSaveForLater } =
    useModal();

  const seekerState = states?.find(
    (state: any) => state?.code === applicant?.address?.state,
  );
  const { requiredSkills = [] } = application ?? {};
  const openSaveForLaterModal = () => {
    toggleSaveForLater();
  };

  const handleRescidOffer = async () => {
    toggleRescindApplication();
  };
  const [question, setQuestion] = useState("");

  const bpoApproveSeeker = async () => {
    let data = {
      isPublic: false,
      timestamp: Timestamp.now(),
      type: "StatusNote",
      who: user.uid,
      note: `Seeker has been aproved by ${userName}.`,
    };

    setPreOfferPending(true);
    await updateAutoSave(collectionNames.applications, application?.objectID, {
      status: "preOffer",
    });

    await createNote(application?.objectID, collectionNames.applications, data);
    await createNotification({
      group: "tac",
      title: "New Seeker Approved",
      body: `${userName} approved ${applicant?.name} for preOffer.`,
      createdBy: `${user?.uid}`,
      isPushNotification: true,
      url: `applications/${application?.objectID}/details`,
    });
    setTimeout(() => {
      toast.success("Seeker has been approved!");
      setPreOfferPending(false);
      navigate(0);
    }, 2000);
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-2 gap-5">
          {/* 1st left col */}
          <div className=" col-span-1  w-full flex items-center  mb-2 p-2  h-full pt-2 pb-4   rounded-l-lg ">
            <div className="flex flex-col    ">
              <p className="uppercase text-instant-teams-teal-Main font-semibold text-xl pb-2">{`${firstNameOnly(
                applicant?.name,
              )}`}</p>
              <div className=" space-x-1 w-full text-instant-teams-blue-Main  mb-2 h-8  ">
                <FontAwesomeIcon
                  size="xl"
                  icon={faMapMarkerAlt}
                  className=" text-instant-teams-teal-Main"
                />
                <span className="text-lg">
                  {seekerState
                    ? `${seekerState?.label}, ${seekerCountry?.label}`
                    : `${seekerCountry?.label}`}
                </span>
              </div>
              <div className="flex font-black -mt-1 text-instant-teams-teal-D1 ">
                <Affiliations
                  userId={application.resumeId}
                  background={"lightBackgroud"}
                />
              </div>
            </div>
          </div>
          {/* 1st right col */}
          <div className="w-full flex flex-col justify-center">
            <MeetingList meetings={sortedMeetings} />
            {/* If a meeting is in awaiting, hide the button */}
            {awaitingResponse?.length <= 0 ? (
              <div className="w-1/2 flex mx-auto  ">
                <ScheduleMeetingActions
                  refType="application-meeting" // different types of meetings assoc w/ reftypes
                  refId={application?.objectID}
                  inviteeId={application?.resumeId}
                  title={`${employerData?.displayName} - ${jobDescriptionData?.title}`}
                  extended={{
                    employerId: application?.employer,
                    jobId: application?.jobId,
                  }}
                />
              </div>
            ) : (
              // without this button, the modal will start at the bottom, rendering at the buttons
              // alternativelly we could update the buttons at the bottom to div.
              <button className=" bg-white text-white"> </button>
            )}
          </div>
          {/* 2nd left col */}
          <div>
            <GreenUnderlineHeading title="Skills" />

            <div className="grid grid-cols-4">
              {requiredSkills &&
                requiredSkills.map((skill: any) => (
                  <ExternalBadgesModalView
                    key={skill?.id}
                    skill={skill}
                    applicant={applicant}
                  />
                ))}
            </div>
          </div>
          {/* 2nd Right */}
          <div className="">
            <ExternalNotesModalView application={application} />
          </div>
          {/* 3rd, full width */}
          <div className=" col-span-2">
            <GreenUnderlineHeading title="E-Interviews" />
            <div className="grid grid-cols-10 gap-5 min-h-[600px]">
              <ModalInterviewItem
                application={application}
                returnItem={setQuestion}
              />
              {question && (
                <ModalInterviewAnswerItem
                  application={application}
                  question={question}
                />
              )}
            </div>
          </div>
        </div>
        {/* Buttons  */}
        <div className="flex justify-center mx-auto w-1/2  align-middle  mt-5 space-x-5">
          {jobDescriptionData?.solutionType === "DH" ? (
            <a
              href={`/offers/new/${application?.objectID}`}
              target="_blank"
              rel="noreferrer"
              className="w-1/3"
            >
              <button className=" cursor-pointer  w-full  py-2 uppercase   text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none">
                Send Offer
              </button>
            </a>
          ) : (
            <button
              onClick={bpoApproveSeeker}
              className=" cursor-pointer  w-1/3  py-2 uppercase   text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
            >
              {preOfferPending ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  ></path>
                </svg>
              ) : (
                "Approve"
              )}
            </button>
          )}
          <button
            className=" cursor-pointer   w-1/3 max-h-12   py-2 uppercase   text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
            onClick={openSaveForLaterModal}
          >
            Save for Later
          </button>

          <button
            className=" cursor-pointer w-1/3 max-h-12 py-2 uppercase    text-white bg-instant-teams-red-Main rounded-lg border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
            onClick={() => handleRescidOffer()}
          >
            Decline
          </button>
        </div>
      </div>
      {/* decline application Modal */}
      {rescindOffer ? (
        <DialogWrapper
          title="Decline Application"
          size="h-1/2 w-1/3"
          onClose={toggleRescindApplication}
        >
          <DeclineApplication
            collection={collectionNames.applications}
            toggleRescindApplication={toggleRescindApplication}
            passedApplicationId={application?.objectID}
          />
        </DialogWrapper>
      ) : null}
      {/* save for later modal */}
      {isSaveForLaterShowing ? (
        <DialogWrapper
          title="Career Seeker Save for Later"
          size="h-1/2 w-1/4"
          onClose={toggleSaveForLater}
        >
          <SaveForLaterApplicationModal
            application={application}
            toggle={toggle}
          />
        </DialogWrapper>
      ) : null}
      <ToastAlert />
    </>
  );
}

export default RedactedApplicationModal;

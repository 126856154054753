import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/auth";
import { createNote } from "../../../../data/notes";
import { Transition, Dialog } from "@headlessui/react";
import { Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { updateAutoSave } from "../../../../data/utility";
import { updateStatusHistory } from '../../../../data/utility';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../providers/database";
import { useAllStatuses } from "../../../../data/status";

function StatusNote({
  onDone,
  collection,
  id,
  name,
  placeholder,
  status,
  required,
  directions,
  statusCollection,
  minLength = 20,
}) {
  const { user, userClaims } = useAuth();
  const [buttonText, setButtonText] = useState("Please complete the form");
  const [formDisabled, setFormDisabled] = useState(true);
  const [value, setValue] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [check, setCheck] = useState(status);
  const [statusID, setStatusID] = useState("0");
  const [currentStatus, setCurrentStatus] = useState([]);
  const [options, setOptions] = useState([]);
  const [saved, setSaved] = useState(false);
  const [countCheck, setCountCheck] = useState(false);
  const [passed, setPassed] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const { data: allStatusData } = useAllStatuses(statusCollection);

  useEffect(() => {
    setStatusID(status);
  }, [status]);

  // talk to Amil or Chris
  useEffect(() => {
    if (
      allStatusData !== null &&
      allStatusData !== undefined &&
      allStatusData !== ""
    ) {
      const selectedOption = allStatusData.find(
        (status) => status.id === statusID,
      );
      setSelectedDescription(selectedOption?.description);
    }
  }, [statusID, allStatusData]);

  useEffect(() => {
    if (
      statusCollection !== null &&
      statusCollection !== undefined &&
      statusCollection !== "" &&
      status !== null &&
      status !== undefined &&
      status !== "" &&
      allStatusData !== null &&
      allStatusData !== undefined &&
      allStatusData !== ""
    ) {
      const activeStatusCollection = doc(db, statusCollection, status);
      let options = [];
      let optionIds = "";
      // sadmin power to change statuses to whatever
      const sadminOptions = Object.values(allStatusData).map((obj) => obj.id);
      const updateStatus = [];
      async function fetchOptions(docRef, myStatusCollection) {
        await getDoc(docRef)
          .then(async (data) => {
            setCurrentStatus({
              id: data.id,
              label: data.data().label,
              description: data.data().description,
            });
            // check roles and give options based on roles
            if (userClaims?.claims?.role === "sadmin") {
              options = sadminOptions;
            } else {
              options = data.data().options;
            }

            optionIds = options.map((value) => value);
            for (const id in optionIds) {
              const option = await getDoc(
                doc(db, myStatusCollection, optionIds[id]),
              );
              updateStatus.push({
                id: option.id,
                label: option.data().action,
                sort: option.data().sortOrder,
                description: option.data().description,
              });
              const sortedArray = [...updateStatus].sort((a, b) =>
                a.sort > b.sort ? 1 : -1,
              );
              setOptions(sortedArray);
            }
            console.log(options);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      fetchOptions(activeStatusCollection, statusCollection);
    }
  }, [status, statusCollection, allStatusData]);

  useEffect(() => {
    setCharacterCount(value.length);
  }, [value]);

  useEffect(() => {
    if (statusID !== check && countCheck) {
      setPassed(true);
    } else {
      setPassed(false);
    }
    console.log(statusID, check, countCheck);
  }, [statusID, check, countCheck]);

  useEffect(() => {
    if (characterCount >= minLength) {
      setCountCheck(true);
    } else {
      setCountCheck(false);
    }
  }, [characterCount, value, minLength]);

  useEffect(() => {
    if (passed) {
      setFormDisabled(false);
      setButtonText("Save");
    } else {
      setFormDisabled(true);
      setButtonText("Please complete the form");
    }
  }, [passed]);

  const saveNote = async () => {
    const data = {
      collection: collection,
      id: id,
      type: "StatusNote",
      timestamp: Timestamp.now(),
      who: user.id,
      isPublic: isChecked,
      note: value,
    };
    setButtonText("Saving...");
    await createNote(id, collection, data)
      .then(() => {
        setButtonText("Save Complete");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = async () => {
    if (passed) {
      setCheck(statusID);
      console.log(collection, id, name, value, check, statusID);
      await updateAutoSave(collection, id, { [name]: statusID });
      const history = {
        alteredBy: user.id,
        effectiveDate: Timestamp.now(),
        from: status,
        status: statusID,
      }
      await updateStatusHistory(collection, id, history);
      await saveNote();
      setSaved(true);
      onDone("Status Updated", statusID);
    }
  };

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          className="overflow-y-auto fixed inset-0 z-50"
          onClose={() => onDone()}
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Status Transition
                </Dialog.Title>
                <select
                  name={name}
                  value={statusID || 0}
                  onChange={(e) => {
                    setStatusID(e.target.value);
                  }}
                  onBlur={(e) => setStatusID(e.target.value)}
                  className="form-input shadow-md rounded-md w-full p-3 mb-5"
                >
                  <option disabled className="p-3">
                    Select Transition
                  </option>
                  {Object.keys(options || {}).length > 0
                    ? options.map((key) => (
                        <option key={key.label} value={key.id} className="p-3">
                          {key.label}
                        </option>
                      ))
                    : null}
                </select>
                <p className="text-xs -mt-2 pl-1">
                  {selectedDescription
                    ? selectedDescription
                    : currentStatus?.description}
                </p>

                {saved && (
                  <span className="absolute right-4 top-2 text-xs">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "green" }}
                    />
                  </span>
                )}
                <div className="my-2">
                  Reason{"  "}
                  <span className="text-red-500 text-xs float-right">
                    **Required to make transition
                  </span>
                </div>
                <p className="text-gray-600 text-xs float-right">
                  Character Count: {characterCount}
                  {!countCheck && (
                    <span className="text-red-500">
                      {" "}
                      (minimum: {minLength})
                    </span>
                  )}
                </p>
                <div className="mb-2">
                  <textarea
                    className={
                      !countCheck
                        ? "border-red-500 py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded"
                        : "py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded border border-gray-300 outline-none"
                    }
                    name="note"
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <div className="mb-4 float-right">
                    Share update?
                    <input
                      className="ml-4 float-right mt-1"
                      type="checkbox"
                      name="isPublic"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                  </div>

                  <button
                    className={
                      formDisabled
                        ? "py-2 px-4 w-full text-white bg-red-700 rounded border-0 hover:bg-red-900 focus:outline-none outline-none z-50"
                        : "py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none z-50"
                    }
                    type="submit"
                    onClick={handleSave}
                    disabled={formDisabled}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default StatusNote;

import { getLookupLabel } from "../../../util/search/getLookupLabel";
import { toUSCurrency } from "../../../util/helpers/toUSCurrency";
import { useLookup } from "../../../data/lookup";
import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import Popover from "../../custom-ui/popover/Popover";
import { useAuth } from "../../../providers/auth";
import PreviewSkillItem from "./PreviewMobileSkillItem";
import { LookUp } from "../../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTimeFromMinute,
  getTimeZone,
} from "../../../util/timezone/timezone";
import "../../../styles/dangerouslySet.css";
import PreviewMobileSkillItem from "./PreviewMobileSkillItem";
function JobPreviewMobilePart({
  employer,
  job,
  position,
  skills,
  timezone,
  KylePopover,
}: {
  employer: any;
  job: any;
  position: any;
  skills: any;
  timezone: any;
  KylePopover: any;
}) {
  const { user } = useAuth();
  const [payTypes, setPayTypes] = useState([]);

  const { data: classification = [] } = useLookup("classification");
  const { data: model = [] } = useLookup("model");
  const selectedWorkTypes = getLookupLabel(
    position?.information?.classification,
    classification,
    "classification",
  );
  const workSettings = getLookupLabel(
    position?.information?.model,
    model,
    "model",
  );
  const { data: hardwares = [] } = useLookup("hardware");
  const { data: payType = [] } = useLookup("payType");
  useEffect(() => {
    if (job) {
      setPayTypes(Object.keys(position?.information?.payType));
    }
    return () => setPayTypes([]);
  }, [job]);

  const jobHardwares = hardwares.filter((hw: any) =>
    job?.technology?.hardware?.includes(hw.code),
  );

  // render matched hardware from job
  const hardware = (hw: LookUp, id: number) => (
    <div key={id} className="flex content-center items-start mt-2 space-x-3">
      {/* @ts-ignore */}
      <FontAwesomeIcon icon={["fas", hw.icon]} size="1x" />
      <p>{hw.label}</p>
    </div>
  );
  const [shiftScheduleStart, shiftScheduleEnd] = position?.shiftSchedule
    ?.range ?? [undefined, undefined];

  // render matched schedule
  const day = (hours: LookUp) => (
    <div className="flex" key={hours.id}>
      <p className="w-12">{hours.code}</p>
      <div className="w-36">
        {`${getTimeFromMinute(shiftScheduleStart)} - ${getTimeFromMinute(
          shiftScheduleEnd,
        )}`}
      </div>
      <p>{getTimeZone(timezone)}</p>
    </div>
  );

  return (
    <div className="px-8 py-2 rounded-lg bg-white shadow-input-field">
      <div className=" flex items-center text-instant-teams-blue-Main space-x-2 cursor-pointer">
        <img
          className="w-20 h-20"
          src={employer?.logo}
          alt={employer?.displayName}
        />
        <div className="">
          <p>{employer?.displayName}</p>
          <p className="font-semibold">{job?.title}</p>
          <p>{position?.positionName}</p>
          <div className="flex text-sm ">
            {payTypes &&
              payTypes.map((payTypeKey: string, index: number) => {
                const payTypeValue = position?.information?.payType[payTypeKey];
                if (Array.isArray(payTypeValue)) {
                  return (
                    <p className="text-sm" key={index}>
                      {getLookupLabel(payTypeKey, payType, "Not Found")}:{" "}
                      {toUSCurrency(payTypeValue[0])} -{" "}
                      {toUSCurrency(payTypeValue[1])}
                    </p>
                  );
                }
              })}
            <span className="mx-1">|</span>
            <div>{selectedWorkTypes}</div>
            <span className="mx-1">|</span>
            <div>{workSettings}</div>
          </div>
        </div>
      </div>

      <h2 className="mt-4 mb-2 uppercase text-instant-teams-blue-Main font-semibold">
        Required Skills
      </h2>
      <div className="flex space-x-3">
        {skills && skills.length > 0
          ? skills.map((skill: any) => {
              return (
                <PreviewMobileSkillItem assessmentId={skill.assessmentId} />
              );
            })
          : null}
      </div>
      {jobHardwares.length > 0 && (
        <>
          <h2>Required Hardware</h2>
          <div>{jobHardwares.map(hardware)}</div>
        </>
      )}
      {/* render standard and non-standard shift options */}
      {position?.shiftSchedule?.shiftOption?.code !== "CUSTOM" &&
        position?.shiftSchedule?.days &&
        position?.shiftSchedule?.days.length > 0 && (
          <>
            <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
              Required Hours
            </h2>
            {position?.shiftSchedule?.days?.map(day)}
          </>
        )}
      {/* render CUSTOM shift options */}
      {position?.shiftSchedule?.shiftOption?.code === "CUSTOM" && (
        <>
          <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
            Required Hours
          </h2>
          <p
            className="dangerously"
            dangerouslySetInnerHTML={sanitizeMarkup(
              position?.shiftSchedule?.shiftOption?.description,
            )}
          ></p>
        </>
      )}

      <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
        Day In the life
      </h2>
      <div
        className="dangerously"
        dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.dayInTheLife)}
      ></div>
      <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
        Must Haves
      </h2>
      <div
        className="dangerously"
        dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.mustHaves)}
      ></div>
      <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
        Training
      </h2>
      <div
        className="dangerously"
        dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.training)}
      ></div>
      <h2 className="my-2 uppercase text-instant-teams-blue-Main font-semibold">
        Benefits
      </h2>
      <div
        className="dangerously"
        dangerouslySetInnerHTML={sanitizeMarkup(job?.description?.benefits)}
      ></div>
      <div className="mt-1 -mx-8 bg-instant-teams-teal-Main py-6 flex justify-center ">
        <Popover content={KylePopover} text="You don't have to do this!">
          <button
            disabled
            className=" py-1 px-48 sm:px-16 rounded-lg flex text-center text-white uppercase bg-instant-teams-green-Main"
          >
            Apply Now
          </button>
        </Popover>
      </div>
    </div>
  );
}
export default JobPreviewMobilePart;

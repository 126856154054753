import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../providers/auth";
import MultiFactorSignIn from "./multi-factor/MultiFactorSignIn";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import { friendlyFirebaseError } from "../../providers/firebase-errors/friendlyFirebaseError";
import { warnLogger, errorLogger } from "../../providers/clientLogger";
import { useNavigate } from "react-router-dom";
import { method } from "lodash";
import { resetPassword } from "../../callable-cloud-functions/cloudFunctions";
import { sliLogger } from "../../callable-cloud-functions/cloudFunctions";

function AuthForm(props) {
  const navigate = useNavigate();
  const auth = useAuth();
  const { handleMultiFactorSignIn } = auth;

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();

  const [shouldMfaStart, setShouldMfaStart] = useState(false);
  const [mfaResolver, setMfaResolver] = useState();

  console.log("props", props);

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ email, pass }) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    forgotpass: ({ email }) => {
      console.log("email", email);
      // resetPassword({ email, userAgent: window.navigator.userAgent })
      auth.sendPasswordResetEmail(email).then((result) => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: `An email has been sent to ${email}.\n Please check your email and follow the instructions to reset your password.`,
        });
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    }).catch((error) => {
      setPending(false);
      if (error.code === "auth/multi-factor-auth-required") {
        handleMultiFactorSignIn(
          error,
          setShouldMfaStart,
          setMfaResolver,
          props.onFormAlert,
        );
      } else {
        // Show error alert message
        props.onFormAlert({
          type: "error",
          message: friendlyFirebaseError(error),
        });
      }

      // SLI Logger
      if (
        friendlyFirebaseError(error) ===
        "An error occurred. Please try again later."
      ) {
        sliLogger({
          message: "Authentication failed",
          sevLevel: "ERROR",
          messageContext: { details: "Frontend email/pw login", error },
          resourceType: "Firebase Authentication",
          action: "Login",
          resultStatus: "success",
          cuj: "User Login",
        });
      } else {
        sliLogger({
          message: "Failed to authenticate user, but not an error on our part",
          sevLevel: "INFO",
          messageContext: { details: "Frontend email/pw login" },
          resourceType: "Firebase Authentication",
          action: "Login",
          resultStatus: "success",
          cuj: "User Login",
        });
      }
    });

    // SLI Logger
    sliLogger({
      message: "Successfully authenticated user",
      sevLevel: "INFO",
      messageContext: { details: "Frontend email/pw login" },
      resourceType: "Firebase Authentication",
      action: "Login",
      resultStatus: "success",
      cuj: "User Login",
    });
  };

  return (
    <>
      {!shouldMfaStart && (
        <form onSubmit={handleSubmit(onSubmit)} className="mb-2 w-3/4 max-w-md">
          {["signup", "signin", "forgotpass"].includes(props.type) && (
            <div className="mb-2 w-full">
              <input
                className="px-1 py-2 w-full text-sm text-gray-600 bg-white-200 shadow-xl2 rounded border border-gray-600 focus:outline-none focus:border-gray-500"
                name="email"
                type="email"
                placeholder="Email"
                ref={register({
                  required: "Please enter an email",
                })}
              />

              {errors.email && (
                <p className="mt-1 text-sm text-left text-red-600">
                  {errors.email.message}
                </p>
              )}
            </div>
          )}

          {["signup", "signin", "changepass"].includes(props.type) && (
            <div className="mb-2 w-full">
              <input
                className="px-1 py-2 w-full text-sm text-gray-600 bg-white-200 shadow-xl2 rounded border border-gray-600 focus:outline-none focus:border-gray-500"
                name="pass"
                type="password"
                placeholder="Password"
                ref={register({
                  required: "Please enter a password",
                })}
              />

              {errors.pass && (
                <p className="mt-1 text-sm text-left text-red-600">
                  {errors.pass.message}
                </p>
              )}
            </div>
          )}

          <div className="flex justify-center">
            <button
              id="loginButton"
              className={`w-48 h-10 mt-8 font-semibold text-white ${props.buttonColor !== null ? props.buttonColor : "bg-instant-teams-blue-Main"} rounded shadow-xl2 ${props.buttonHoverColor !== null ? props.buttonHoverColor : "hover:bg-instant-teams-teal-Main"}`}
              type="submit"
              disabled={pending}
            >
              {pending ? "..." : props.buttonAction}
            </button>
          </div>
        </form>
      )}
      {shouldMfaStart && (
        <DialogWrapper
          title="MFA Authentication"
          onClose={() => setShouldMfaStart(false)}
        >
          <MultiFactorSignIn
            mfaResolver={mfaResolver}
            onAuth={props.onAuth}
            onFormAlert={props.onFormAlert}
          />
        </DialogWrapper>
      )}
    </>
  );
}

export default AuthForm;

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  doc,
  deleteField,
  getDoc,
  getDocs,
  setDoc,
  collection,
  query,
  where,
  orderBy,
  addDoc,
  Timestamp,
  updateDoc,
  QueryDocumentSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { db, createQuery } from "../providers/database";
import { SyncRequest } from "../dataTypes/academy";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { getOffersData } from "./offers";
const getApplicationDocRef = (applicationId: string) => {
  return doc(db, collectionNames.applications, applicationId);
};

export function useEnrollments(assessmentId: string) {
  return useQuery(
    ["applications", assessmentId],
    createQuery(() =>
      query(
        collection(db, "Enrollments"),
        where("assessmentId", "==", assessmentId),
        orderBy("status"),
        orderBy("email"),
      ),
    ),
    { enabled: !!assessmentId },
  );
}

export function useEnrollmentsByEmail(email: string) {
  return useQuery(
    ["enrollments", email],
    createQuery(() =>
      query(collection(db, "Enrollments"), where("email", "==", email)),
    ),
    { enabled: !!email },
  );
}

export function useApplicationsByUser(userId: string) {
  return useQuery(
    ["Applications", userId],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        orderBy("timestamp", "desc"),
        where("resumeId", "==", userId),
      ),
    ),
    { enabled: !!userId },
  );
}
export function useApplicationsByUserWithoutOffered(userId: string) {
  return useQuery(
    ["ApplicationsNoOffered", userId],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        orderBy("status", "asc"),
        orderBy("timestamp", "desc"),
        where("status", "!=", "offered"),
        where("resumeId", "==", userId),
      ),
    ),
    { enabled: !!userId },
  );
}

export function useApplicationsByJob(jobId: string) {
  return useQuery(
    ["jobApplications", jobId],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        where("jobId", "==", jobId),
      ),
    ),
    { enabled: !!jobId },
  );
}

export function useApplicationsByJobAndStatus(jobId: string, status: string) {
  return useQuery(
    ["jobApplicationsByJobAndStatus", jobId, status],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        where("jobId", "==", jobId),
        where("status", "==", status),
      ),
    ),
    { enabled: !!jobId && !!status },
  );
}

export function usePreOfferApplications(keyId: string) {
  return useQuery(
    [keyId],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        where("status", "==", "preOffer"),
      ),
    ),
    { enabled: !!keyId },
  );
}

export async function filterPreOffers(
  queryClient: QueryClient,
  filters: [],
  keyId: string,
) {
  const ret = await getOffersData(filters, "preOffers");
  console.log("filterPreOffers, ret: ", ret);
  queryClient.setQueryData([keyId], ret);
}

const filterOffers = (offers: any[], filters: any) => {
  // 1. given a list of offers and a list of jobIds
  // 2. iterate list of offers and for each offer, check its jobId
  //    against the list of filter jobIds
  // 3. if it does not exist within filters, do not add to result
  //    if it does exist, add to result
  // 4. finally, return the result
  console.log("filterOffers, filters: ", filters);
  const ret: any[] = [];
  offers.forEach((offer: any) => {
    const jobId: string = offer?.jobId;
    if (filters.current.includes(jobId)) {
      ret.push(offer);
    }
  });
  return ret;
};

export function useApplicationsByEmployer(employerId: string) {
  return useQuery(
    ["employerJobApplications", employerId],
    createQuery(() =>
      query(
        collection(db, collectionNames.applications),
        where("employer", "==", employerId),
      ),
    ),
    { enabled: !!employerId },
  );
}

export function useApplication(applicationId: string) {
  return useQuery(
    ["jobApplication", applicationId],
    createQuery(() => {
      return doc(db, collectionNames.applications, applicationId);
    }),
    { enabled: !!applicationId },
  );
}

export function createApplication(application: {
  jobId: string;
  positionId: string;
  resumeId: string;
  invited: boolean;
}) {
  console.log(application);
  // add employer status "lead"
  return addDoc(collection(db, collectionNames.applications), {
    ...application,
    status: "invited",
    timestamp: Timestamp.now(),
  });
}

export function useApplicationPositions(id: string) {
  return useQuery(
    ["allApplicationPositions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.applications}/${id}/${subCollectionNames.positions}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}
export function useApplicationStatusHistory(id: string) {
  return useQuery(
    ["allApplicationStatusHistory", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.applications}/${id}/${subCollectionNames.statusHistory}`,
        ),
        orderBy("effectiveDate", "desc"),
      );
    }),
    { enabled: !!id },
  );
}

export function useApplicationPreScreen(id: string) {
  return useQuery(
    ["allApplicationPreScreen", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.applications}/${id}/${subCollectionNames.preScreen}`,
        ),
        orderBy("order", "asc"),
      );
    }),
    { enabled: !!id },
  );
}

export function useApplicationInterviewQuestions(id: string) {
  return useQuery(
    ["allApplicationInterviewQuestions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.applications}/${id}/${subCollectionNames.interviewQuestions}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function useApplicationInterviewQuestionAnswer(
  id: string,
  questionId: string,
) {
  return useQuery(
    ["applicationInterviewQuestion", { id, questionId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.applications}/${id}/${subCollectionNames.interviewQuestions}/${questionId}`,
      ),
    ),
    { enabled: !!id && !!questionId },
  );
}

export function deleteInterviewQuestion(id: string, questionId: string) {
  return deleteDoc(
    doc(
      db,
      `${collectionNames.applications}/${id}/${subCollectionNames.interviewQuestions}`,
      questionId,
    ),
  );
}

export async function updateApplicationPositionStatus(
  applicationId: string,
  positionId: string,
  data: { active: boolean; removedAt?: any },
) {
  return updateDoc(
    doc(
      db,
      collectionNames.applications,
      applicationId,
      subCollectionNames.positions,
      positionId,
    ),
    {
      ...data,
      ...(data.active && { removedAt: deleteField() }),
    },
  );
}

export async function addApplicationPosition(
  applicationId: string,
  positionId: string,
) {
  return setDoc(
    doc(
      db,
      collectionNames.applications,
      applicationId,
      subCollectionNames.positions,
      positionId,
    ),
    {
      active: true,
      createdAt: Timestamp.now(),
    },
  );
}

export function updateApplicationStatus(applicationId: string, status: string) {
  return updateDoc(doc(db, collectionNames.applications, applicationId), {
    status: status,
  });
}

export function useActiveMeetingsApplication(id: string) {
  return useQuery(
    ["activeMeetingsApplication", id],
    createQuery(() =>
      query(
        collection(db, collectionNames.meetings),
        where("refId", "==", id),
        where("refType", "==", "application-meeting"),
        where("status", "==", "scheduled"),
      ),
    ),
    { enabled: !!id },
  );
}
const getInterviewQuestionDocRef = (
  applicationId: string,
  questionId: string,
) => {
  return doc(
    db,
    collectionNames.applications,
    applicationId,
    subCollectionNames.interviewQuestions,
    questionId,
  );
};
export const setAnswer = (
  applicationId: string,
  questionId: string,
  answer: string | null,
) => {
  const docRef = getInterviewQuestionDocRef(applicationId, questionId);
  return setDoc(docRef, { answer }, { merge: true });
};

export const addApplicationSync = (applicationId: string) => {
  return addDoc(collection(db, collectionNames.applicationProgressSync), {
    applicationId,
  });
};

export const setReAttempts = (
  applicationId: string,
  questionId: string,
  reAttempts: number,
) => {
  const docRef = getInterviewQuestionDocRef(applicationId, questionId);
  return setDoc(docRef, { reAttempts }, { merge: true });
};
const getDocConverter = <T>() => ({
  toFirestore: (data: any) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    return { ...snap.data(), id: snap.id } as T;
  },
});

export const getCollectionRef = <T>(collectionPath: string) =>
  collection(db, collectionPath).withConverter(getDocConverter<T>());
const preScreenSubcollectionRef = (uid: string) => {
  return collection(
    db,
    collectionNames.applications,
    uid,
    subCollectionNames.preScreen,
  );
};
export const checkIfInvitedAndGetId = async (
  userId: string,
  jobId: string,
  positionId: string,
) => {
  const q = query(
    getCollectionRef(collectionNames.applications),
    where("resumeId", "==", userId),
    where("jobId", "==", jobId),
    where("positionId", "==", positionId),
  );

  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return null;
  return querySnapshot.docs[0].id;
};
export const updateStatus = (id: string, status: string) => {
  return updateDoc(getApplicationDocRef(id), { status });
};

export const createSeekerApplication = (application: {
  jobId: string;
  positionId: string;
  resumeId: string;
}) => {
  return addDoc(collection(db, collectionNames.applications), {
    ...application,
    status: "applied",
    timestamp: Timestamp.now(),
  });
};
export const applicationDocExists = async (id: string) => {
  const ref = await getDoc(getApplicationDocRef(id));
  return ref.exists();
};
export const preScreenSubcollectionExists = async (id: string) => {
  const { empty } = await getDocs(preScreenSubcollectionRef(id));
  return !empty;
};

const getPreScreenDocRef = (applicationId: string, questionId: string) => {
  return doc(
    db,
    collectionNames.applications,
    applicationId,
    subCollectionNames.preScreen,
    questionId,
  );
};
export const getPreScreenedValue = async (id: string) => {
  const ref = await getDoc(getApplicationDocRef(id));
  const preScreened = ref.data()?.preScreened;
  return preScreened;
};
export const updateAnswer = (
  applicationId: string,
  questionId: string,
  answer: boolean,
) => {
  const docRef = getPreScreenDocRef(applicationId, questionId);
  return updateDoc(docRef, { answer });
};

export function updateApplicationSelectedResume(
  applicationId: string,
  resumeId: string,
) {
  return updateDoc(doc(db, collectionNames.applications, applicationId), {
    resumeFileId: resumeId,
    manualResumeSelected: false,
    manualResume: "",
  });
}

export function useApplicationsByJobId(jobIds: Array<string>) {
  return useQuery(
    ["allApplicationsByJobId", { jobIds }],
    createQuery(() => {
      return query(
        collection(db, `${collectionNames.applications}`),
        where("jobId", "in", jobIds),
      );
    }),
    { enabled: !!jobIds },
  );
}

function refetchOffers(queryClient: QueryClient, listOfKeys: string[]) {
  queryClient.invalidateQueries(listOfKeys);
}

export function friendlyFirebaseError(error: any) {
  switch (error.code) {
    // authentication
    case "auth/user-not-found":
      return "The email address, password, or both are incorrect.";

    case "auth/email-already-in-use":
      return "The email address is already in use by another account.";
    case "auth/invalid-email":
      return "The email address is invalid.";
    case "auth/weak-password":
      return "The password is not strong enough.";
    case "auth/too-many-requests":
      return "Access to this account has been temporarily disabled due to many failed login attempts. \n You can immediately restore it by resetting your password or you can try again later.";

    // mfa related
    case "auth/invalid-verification-code":
    case "auth/missing-verification-code":
      return "The authorization code you entered is invalid. Please try again.";
    case "auth/quota-exceeded":
      return "Looks like we're having trouble processing your verification code. Please give it some time and try again later.";

    case "auth/code-expired":
      return "Looks like your verification code expired. Please resend.";

    case "auth/missing-phone-number":
    case "auth/invalid-phone-number":
      return "Looks like you've submitted an invalid phone number. Please check your entry and try again.";
    default:
      return "An error occurred. Please try again later.";
  }
}

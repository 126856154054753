import { useApplication } from "../../data/applications";
import { useJobDescription } from "../../data/jobDescriptions";
import { useJobPositionDetails } from "../../data/positions";
import { requireAuth } from "../../providers/auth";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useLookup } from "../../data/lookup";
import useApplicationRecordings from "../../hooks/useApplicationRecordings";
import { useUser } from "../../providers/database";
import { getBadge } from "../../data/enrollments";
import { useMap } from "../../hooks/useMap";
import { RequiredSkill } from "../../dataTypes/Assessment";

import WedgeSpinner from "../../components/spinners/wedges-spinner";
import Meta from "../../components/custom-ui/meta/Meta";
import SeekerEInterview from "../../components/seekerView/SeekerEInterview";
import ReviewAndSubmitView from "../../components/seekerView/ReviewAndSubmitView";
import ApplicationHeader from "./ApplicationHeader";
import SeekerBadges from "../../components/seekerView/SeekerBadges";
import ApplicationCardWrapper from "../../components/custom-ui/cards/applicationCardWrapper";
import ApplicationResumeSection from "../../components/seekerView/ApplicationResumeSection";
import { useEffect } from "react";

function SeekerApplicationsDetails() {
  const { applicationId = "" } = useParams();
  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { recordings } = useApplicationRecordings(
    applicationId!,
    applicationData?.jobId!,
  );
  const { data: jobData } = useJobDescription(applicationData?.jobId);

  const { data: positionData, isLoading: isPositionDataLoading } =
    useJobPositionDetails(applicationData?.jobId, applicationData?.positionId);

  const { data: levels } = useLookup("learningLevel");
  const { data: userData, isLoading: isUserDataLoading } = useUser(
    applicationData?.resumeId,
  );

  const [badges, badgesLoading] = useMap<RequiredSkill, any>({
    items: applicationData?.requiredSkills,
    map: (item) => getBadge(item, userData?.email),
    filter: (item) => !!item?.skill,
    deps: [userData?.email],
  });
  const succeeded = useMemo(
    () =>
      !badges?.some((b) => b?.result === "pending" || b?.result === "failed"),
    [badges],
  );

  const [resumeSelected, setResumeSelected] = useState(false);

  useEffect(() => {
    if (!isApplicationDataLoading) {
      const isResumeSelected =
        applicationData?.resume && applicationData?.resume !== null;
      setResumeSelected(isResumeSelected);
    }
  }, [applicationData]);

  const disabled =
    resumeSelected ||
    recordings.length === 0 ||
    !succeeded ||
    recordings?.some(({ answer }: any) => !answer);

  if (badgesLoading || isApplicationDataLoading || isPositionDataLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }

  return (
    <>
      {jobData && positionData && (
        <Meta title={`${jobData?.title}, ${positionData?.positionName}`} />
      )}
      <section className="pt-40 pb-16">
        <ApplicationHeader disabled={disabled} />
        <div className="grid grid-flow-row mx-auto grid-cols-8 gap-5 w-3/4 lg:w-full">
          <div className="col-span-5">
            {jobData?.requireResume ? (
              <ApplicationCardWrapper title={"Resume"}>
                <ApplicationResumeSection />
              </ApplicationCardWrapper>
            ) : null}
            <ApplicationCardWrapper title={"E-Interview"}>
              <SeekerEInterview
                applicationId={applicationId}
                jobId={applicationData?.jobId}
              />
            </ApplicationCardWrapper>
            <ApplicationCardWrapper title={"Assessments"}>
              <SeekerBadges succeeded={succeeded} badges={badges} />
            </ApplicationCardWrapper>
          </div>
          <div className="col-span-3">
            <ApplicationCardWrapper title={"Application Progress"}>
              <ReviewAndSubmitView badges={badges} succeeded={succeeded} />
            </ApplicationCardWrapper>
          </div>
        </div>
      </section>
    </>
  );
}
export default requireAuth(SeekerApplicationsDetails);

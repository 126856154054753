import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { 
  useCommunityAttempts,
  useCommunityAuthByToken,
  useCommunityAuth,
  useCommunityFailures,
  useCommunityDeclines
} from '../../../data/community';
import { useUser } from '../../../data/user';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";

const CommunityDashboard = () => {
  const params = useParams();
  const [id, setId] = useState(params.userId);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [milSpouse, setMilSpouse] = useState(false);
  const [authToken, setAuthToken] = useState("");

  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useUser(id ?? params.userId);
  
  const {
    data: commAuth,
    error: authErrors,
    status: authStatus,
  } = useCommunityAuth(id ?? params.userId)

  useEffect(() => {
    setEmail(userData?.email);
    setRole(userData?.claims.role);
    setMilSpouse(userData?.claims.milspouse);
    if (commAuth) {
    console.log("comm: ",commAuth);
    setAuthToken(commAuth[0]?.id);
    }
  }, [params, userData]);

  const {
    data: attempts,
    error: attemptsErrors,
    status: attemptsStatus,
  } = useCommunityAttempts(email);

  const {
    data: commErrors,
    error: errorsErrors,
    status: errorsStatus,
  } = useCommunityFailures(email);

  const {
    data: commDeclines,
    error: declinesErrors,
    status: declinesStatus,
  } = useCommunityDeclines(email);

  const {
    data: commTokens,
    error: tokensErrors,
    status: tokensStatus,
  } = useCommunityAuthByToken(authToken);

  return (
    <div>
      {role === "SKR" || milSpouse ? (
      <div>
        <GreenUnderlineHeading title="Community Member Tokens" />
          <div>
            <p className="font-bold">Currently Active Token: {commTokens ? "true" : "false" }</p>
          </div>
        <GreenUnderlineHeading title="ID.me Declines" />
        {commDeclines && commDeclines.length > 0 ? (
          <div>
            {commDeclines.map((decline: any) => 
            <div key={decline.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
              <p>{"Date: " + new Date(decline.date.seconds * 1000).toDateString()}</p>
              <p>{`Reason: ${decline.reason}`}</p>
            </div>
            )}
          </div>
        ):(<p>No Disagreements with Verification Declines</p>)}
        <GreenUnderlineHeading title="ID.me Verification" />
        {attempts && (
          <div>
            <p className="font-bold">Total Verificatons: {attempts?.length}</p>
          <div className="flex">
            {attempts.map((attempt: any) =>  (
              <div key={attempt.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                <p>{`Verified: ${attempt.verified}`}</p>
                <p>{"Date: " + new Date(attempt.time.seconds * 1000).toDateString()}</p>
                <ul>Subgroup: {attempt.subgroups[0]}</ul>
              </div>
            ))}
          </div>
          </div>
        )}
        <GreenUnderlineHeading title="Oauth 2 Errors" />
        {commErrors && (
          <div>
            <p className="font-bold">Total Errors: {commErrors?.length}</p>
            <div className="flex">
              {commErrors.map((error: any) =>  
              <div key={error.id} className="border border-instant-teams-teal-D2 rounded-lg p-2">
                <p>{`Error: ${error.action}`}</p>
                <p>{`Message: ${error.description}`}</p>
                <p>{`Date: ${new Date(error.occurence.seconds * 1000).toDateString()}`}</p>
              </div>
              )}
            </div>
          </div>
        )}
    </div>
      ): (<h1>This user is not a community member</h1>)}
      
    </div>
  );
}

export default CommunityDashboard;
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { usersByType } from "../callable-cloud-functions/cloudFunctions";
import { useEffect, useState } from "react";
import { TailwindHoverEnlarge } from "../util/tailwind/tailwind-styles";

const toArray = (object) => {
  const ret = [];
  for (const key in object) {
    const item = {};
    const val = object[key];
    item[key] = val;
    ret.push(item);
  }

  return ret;
};
function UsersByType() {
  const [values, setValues] = useState([]);
  const [affiliationTypes, setAffilliationTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await usersByType();
      if (data?.affiliationTypes) {
        const arr = toArray(data.affiliationTypes);
        setAffilliationTypes(arr);
      }
      const dataArr = toArray(data);
      setValues(dataArr);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="flex flex-wrap justify-center text-xl mb-4">
          Users By Type
        </div>
        <div className="w-full">
          {values &&
            values.map((value, index) => {
              const label = Object.keys(value)[0];
              if (label !== "affiliationTypes" && label !== "createdAt") {
                if (label === "Affiliated") {
                  const affiliatedCount = value[label];
                  return (
                    <>
                      <div
                        key={index}
                        className="w-full border-b border-gray-300"
                      >
                        {label}
                        <span
                          key={"usersByType" + index}
                          className="float-right"
                        >
                          {affiliatedCount}
                        </span>
                      </div>
                      {affiliationTypes &&
                        affiliationTypes.map((value, index) => {
                          const label = Object.keys(value)[0];
                          const count = value[label];
                          console.log(
                            "usersByType: ",
                            label,
                            ": ",
                            count,
                            "value: ",
                            value,
                          );
                          return (
                            <div
                              key={index}
                              className="w-full border-b border-gray-300 pl-4"
                            >
                              {label}
                              <span
                                key={"usersByType" + index}
                                className="float-right"
                              >
                                {count}
                              </span>
                            </div>
                          );
                        })}
                    </>
                  );
                } else {
                  const count = value[label];
                  return (
                    <div
                      key={index}
                      className="w-full border-b border-gray-300"
                    >
                      {label}
                      <span className="float-right">{count}</span>
                    </div>
                  );
                }
              }
            })}
        </div>
      </div>
    </>
  );
}

export default UsersByType;

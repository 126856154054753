import React, { useState, useEffect } from "react";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import QuestionVideoRecorder from "./QuestionVideoRecorder";
import { updateAutoSave } from "../../data/utility";
import { useEmployerInterviewQuestion } from "../../data/employer";
import DragAndDropAudioVideo from "../custom-ui/drag-and-drop/DragAndDropAudoVideo";
import { uploadBadge } from "../../providers/firebaseStorage";
import { useJobInterviewQuestion } from "../../data/jobDescriptions";

function VideoPrompt({
  type,
  collection,
  id,
  typeId,
  disabled = false,
}: {
  type: string;
  collection: string;
  id: string;
  typeId: string;
  disabled?: boolean;
}) {
  const [reload, setReload] = useState(false);
  const [trigger, setTrigger] = useState("load");
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState();
  const { isShowing: showRecorder, toggle: toggleRecorder } = useModal();
  const { data: employerInterviewQuestion } = useEmployerInterviewQuestion(
    typeId,
    id,
  );
  const { data: JobInterviewQuestion } = useJobInterviewQuestion(typeId, id);

  function removeVideo() {
    updateAutoSave(collection, id, { videoURL: null });
    setReload(true);
    setFile(null);
  }

  const confirmUpload = () => {
    if (file) {
      uploadBadge(file, "videoPrompts", `${id}_${file?.name}`).then(
        (fileUrl) => {
          const recording = {
            videoURL: fileUrl,
          };
          updateAutoSave(collection, id, recording);
        },
      );
    }
  };

  useEffect(() => {
    if (type === "employer") {
      if (employerInterviewQuestion && employerInterviewQuestion.videoURL) {
        setVideo(employerInterviewQuestion.videoURL);
        setReload(false);
      }
    }
    if (type === "job") {
      if (JobInterviewQuestion && JobInterviewQuestion.videoURL) {
        setVideo(JobInterviewQuestion.videoURL);
        setReload(false);
      }
    }
  }, [employerInterviewQuestion, JobInterviewQuestion]);

  useEffect(() => {
    console.log(video);
  }, [video]);

  return (
    <div key={video} className="w-full">
      {video !== undefined && video !== null && !reload ? (
        <div className="mx-auto mt-4 min-w-full">
          <video controls className="mx-auto">
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 mt-4">
              <button
                disabled={disabled}
                className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
                onClick={toggleRecorder}
              >
                Re-Record Video
              </button>
            </div>
            <div className="col-span-1 mt-4">
              <button
                disabled={disabled}
                className="py-2 px-4 w-full text-white bg-instant-teams-orange-D2 rounded border-0 hover:bg-instant-teams-red-Main focus:outline-none ml-auto"
                onClick={() => {
                  removeVideo();
                }}
              >
                Delete Video
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-1 mt-2">
          <div className="col-span-1">
            <button
              disabled={disabled}
              className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none ml-auto"
              onClick={toggleRecorder}
            >
              Record Video
            </button>
          </div>
          <div className="col-span-2">
            <DragAndDropAudioVideo setFile={setFile} type={"Video"} />
          </div>
          <div className="col-span-1">
            <div className="ml-2">
              {file && (
                <button
                  disabled={disabled}
                  className="w-full py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                  onClick={confirmUpload}
                >
                  Upload File
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {showRecorder && (
        <DialogWrapper
          title="Video Recorder"
          onClose={toggleRecorder}
          size={"w-1/4 h-1/4"}
        >
          <QuestionVideoRecorder collection={collection} id={id} />
        </DialogWrapper>
      )}
    </div>
  );
}

export default VideoPrompt;

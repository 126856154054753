import React, { useEffect, useState } from "react";

import { useFiles } from "../../../../data/seeker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-duotone-svg-icons";
import {
  updateApplicationSelectedResume,
  useApplication,
} from "../../../../data/applications";
import { useParams } from "react-router-dom";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import toast from "react-hot-toast";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import { addApplicationSync } from "../../../../data/applications";
const ApplicationResume = ({ id }: { id: string }) => {
  const { applicationId = "" } = useParams();
  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useApplication(applicationId);
  const { data: files } = useFiles(id);
  const [pdf, setPDF] = useState("");

  useEffect(() => {
    if (!isApplicationDataLoading) {
      setPDF(applicationData?.resumeFileId);
    }
  }, [applicationData]);

  const handleClick = async (file: any) => {
    setPDF(file.id);
    await updateApplicationSelectedResume(applicationId, file?.id);
    await addApplicationSync(applicationId);
    toast.success(`Resume ${file.file.label} selected.`);
  };

  return (
    <div className="cursor-pointer bg-white rounded-lg pt-2 pb-2">
      {files &&
        files.map((file: any, index: number) => (
          <Tooltip text={`Select ${file?.file?.label}`}>
            <button
              disabled={file.id === pdf}
              key={index}
              className={
                file.id === pdf
                  ? `pr-4 border-2 border-instant-teams-blue-Main  text-instant-teams-teal-Main rounded-lg shadow-lg mb-2 w-full`
                  : `pr-4 border-2 border-instant-teams-gray-Main  text-instant-teams-teal-Main rounded-lg shadow-lg mb-2 w-full`
              }
              onClick={() => handleClick(file)}
            >
              <div className="text-left">
                <div className="h-full w-full flex justify-between px-4 rounded-full py-2">
                  {file?.file?.label}
                  <FontAwesomeIcon
                    icon={faFile}
                    className="h-5 w-5 "
                    swapOpacity
                  />
                </div>
              </div>
            </button>
          </Tooltip>
        ))}
      <ToastAlert />
    </div>
  );
};

export default ApplicationResume;

import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { requireAuth } from "../../../providers/auth";
import { getJobOffer } from "../../../data/offers";
import SeekerInfo from "../../seeker/details/parts/seekerInfo";
import Meta from "../../custom-ui/meta/Meta";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import useModal from "../../../hooks/useModal";
import DragAndDropVariantA from "../../custom-ui/drag-and-drop/DragAndDropFile";
import RescindOfferModal from "../parts/RescindOfferModal";
import { updateOfferLetter } from "../../../data/offers";
import PDFViewer from "../../pdf/pdfViewer";
import { collectionNames } from "../../../data/dictionary/collectionNames";

const JobOfferDetailsPage = () => {
  const params = useParams();
  const offerId = params?.offerId || "";
  const [offer, setOffer] = useState();
  const [file, setFile] = useState();
  const { offers } = collectionNames;
  const { isShowing: showDragAndDropModal, toggle: toggleDragAndDropModal } =
    useModal();
  const { isShowing: rescindOffer, toggle: toggleRescindOffer } = useModal();

  useEffect(() => {
    setFile(offer?.offerLetterUrl);
  }, [offer?.offerLetterUrl]);

  useEffect(() => {
    async function fetchData() {
      let res = await getJobOffer(offerId);
      setOffer(res);
    }
    if (offerId) {
      fetchData();
    }
  }, [offerId]);

  const handleSubmit = () => {
    updateOfferLetter(offerId, file);
    toggleDragAndDropModal();
  };

  return (
    <div>
      <Meta title="Job Offer Details" />
      {offer && (
        <div className="flex">
          <SeekerInfo imageVisible={true} userId={offer?.resumeId} />
          <div className="w-1/4 mr-32">
            <button
              className="bg-instant-teams-teal-Main hover:bg-instant-teams-blue-Main text-white font-bold px-4 border border-instant-teams-teal-Main rounded mt-2 w-full"
              onClick={(e) => {
                e.stopPropagation();
                window.open(offer?.embeddedSigningUrl, "_blank", "noreferrer");
              }}
            >
              Preview URL
            </button>
            <button
              className=" bg-instant-teams-orange-Main hover:bg-instant-teams-orange-D2 text-white font-bold px-4 rounded mt-2 w-full"
              onClick={toggleRescindOffer}
            >
              Rescind Offer
            </button>
            <button
              className=" hover:bg-red-900 bg-instant-teams-red-Main text-white font-bold px-4 rounded mt-2 w-full"
              onClick={toggleDragAndDropModal}
            >
              Replace Letter
            </button>
          </div>
          {rescindOffer && (
            <DialogWrapper
              title="Rescind Offer"
              size="h-1/2 w-1/3"
              onClose={toggleRescindOffer}
            >
              <RescindOfferModal
                collection={offers}
                toggleRescindOffer={toggleRescindOffer}
              />
            </DialogWrapper>
          )}
          {showDragAndDropModal && (
            <DialogWrapper
              title="Resubmit Offer Letter"
              onClose={toggleDragAndDropModal}
            >
              <>
                <p className="mb-2">
                  This will replace your existing offer letter with a new one
                </p>
                <DragAndDropVariantA setFile={setFile} />
                <div className="flex w-full justify-center mt-2">
                  <button
                    className="bg-instant-teams-teal-Main hover:bg-instant-teams-blue-Main text-white font-bold px-4 border border-instant-teams-teal-Main rounded mt-2"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </>
            </DialogWrapper>
          )}
        </div>
      )}
      <PDFViewer file={file} label="Offer Letter" />
    </div>
  );
};

export default requireAuth(JobOfferDetailsPage);

import {useState, useEffect} from "react";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import AutoSaveText from "../../../custom-ui/input-fields/auto-save-field";
import AutoSaveToggle from '../../../custom-ui/input-fields/auto-save-toggle';
import AutoSaveSelectByLookup from "../../../custom-ui/input-fields/auto-save-select-by-lookup";
function ClaimsSection({ user }) {
  const [roleGroup, setRoleGroup] = useState(null);
  useEffect(() => {
    if(user?.claims?.type){
      switch (user?.claims?.type) {
          case "A":
            setRoleGroup("adminRole")
            break;
          case "S":
            setRoleGroup("seekerRole")
            break;
          case "E":
            setRoleGroup("employerRole")
            break;
          default:
            break;
        }
    }
  }, [user?.claims]);
  return (
    <>
      <GreenUnderlineHeading title="Claims" />
      <div className="grid grid-cols-2 gap-5">
        <AutoSaveSelectByLookup
          value={user?.claims?.type}
          collection="Users"
          group={"accountType"}
          recId={user?.id}
          required={false}
          name="claims.type"
          display="Type"
          placeholder=""
          directions=""
          readOnly={false}
        />
        <AutoSaveSelectByLookup
          value={user?.claims?.role}
          collection="Users"
          group={roleGroup}
          recId={user?.id}
          required={false}
          name="claims.role"
          display="Role"
          placeholder=""
          directions=""
          readOnly={false}
        />
        <AutoSaveText
          value={user?.claims?.employerId}
          collection="Users"
          recId={user?.id}
          required={false}
          name="claims.employerId"
          display="Employer ID"
          placeholder=""
          directions=""
          readOnly={false}
        />
        <AutoSaveText
          value={user?.claims?.invitationId}
          collection="Users"
          recId={user?.id}
          required={false}
          name="claims.invitationId"
          display="Invitation ID"
          placeholder=""
          directions=""
          readOnly={false}
        />
        <div className="col-span-2 grid grid-cols-4 gap-2 w-full mb-10">
          <div>
            <GreenUnderlineHeading title="Active" />
            <AutoSaveToggle
              value={user?.claims?.active}
              collection="Users"
              id={user?.id}
              name="claims.active"
              on={"True"}
              off={"False"}
            />
          </div>
          <div>
            <GreenUnderlineHeading title="Consent" />
            <AutoSaveToggle
              value={user?.claims?.consent}
              collection="Users"
              id={user?.id}
              name="claims.consent"
              on={"True"}
              off={"False"}
            />
          </div>
          <div>
            <GreenUnderlineHeading title="12M+" />
            <AutoSaveToggle
              value={user?.claims?.milspouse}
              collection="Users"
              id={user?.id}
              name="claims.milspouse"
              on={"True"}
              off={"False"}
            />
          </div>
          <div>
            <GreenUnderlineHeading title="MilVerified" />
            <AutoSaveToggle
              value={user?.claims?.milVerified}
              collection="Users"
              id={user?.id}
              name="claims.milVerified"
              on={"True"}
              off={"False"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimsSection;

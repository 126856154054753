import { useLookup } from "../../../data/lookup";
import { LookUp } from "../../../dataTypes/LookUp";
import WedgeSpinner from "../../spinners/wedges-spinner";
function LookupSelector({
  group,
  placeholder,
  display,
  directions,
  value,
  setValue,
}: {
  group: string;
  placeholder: string;
  display: string;
  directions: string;
  value: string;
  setValue: Function;
}) {
  const { data: lookupData, isLoading } = useLookup(group);
  if (isLoading)
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  return (
    <div className="relative" id={group}>
      <label className="text-instant-teams-teal-Main text-sm  absolute  duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
        {display} {directions ? " - " + directions : null}
      </label>
      <select
        name={"affiliation"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="form-input shadow-md rounded-md w-full pt-3"
      >
        {" "}
        <option disabled={true} value="" selected={true}>
          {placeholder}
        </option>
        {Object.keys(lookupData).length ? (
          lookupData.map((key: LookUp) => (
            <option key={key.id} value={key.code}>
              {key.label}
            </option>
          ))
        ) : (
          <option disabled={true}>N/A</option>
        )}
      </select>
    </div>
  );
}
export default LookupSelector;

//  this might be best used as an npm package to be shared between cloud functions and client apps.
type CloudFunctionNames = {
  addressValidation: string;
  offersDataAggregationCallable: string;
  deptDashboard: string;
  usersByType: string;
  newUsersByDay: string;
  newOpeningsByDay: string;
  outreachList: string;
  collectionCounts: string;
  sliLogger: string;
  resetPassword: string;
  updateResetPassword: string;
  fetchEarnedBadges: string;
  positionMetrics: string;
  getOpenJobMetrics: string;
  getPositionMetrics: string;
  getTop10CoursesByCompletion: string;
  getApplicationTrendsByDay: string;
  getApplicationsPerPosition: string;
  getApplicationTrendsByDayByJobId: string;
  getNewRegistrationMetrics: string;
  getAssessmentMetricsByStatus: string;
  cleanupUserRole: string;
  cleanupOrphanedAuth: string;
  commCreateUser: string;
  commCheckUser: string;
  csvUsers: string;
  deleteApplicationRecursively: string;
};

const cloudFunctionNames: CloudFunctionNames = {
  addressValidation: "addressValidation-addressValidation",
  offersDataAggregationCallable: "offersDataAggregationCallable",
  deptDashboard: "deptDashboard",
  usersByType: "usersByType",
  newOpeningsByDay: "newOpeningsByDay",
  newUsersByDay: "newUsersByDay",
  outreachList: "outreachList",
  collectionCounts: "collectionCounts",
  sliLogger: "sliLogger",
  resetPassword: "resetPassword",
  updateResetPassword: "updateResetPassword",
  fetchEarnedBadges: "fetchEarnedBadges",
  positionMetrics: "positionMetrics",
  getOpenJobMetrics: "getOpenJobMetrics",
  getPositionMetrics: "getPositionMetrics",
  getApplicationTrendsByDay: "getApplicationTrendsByDay",
  getApplicationsPerPosition: "getApplicationsPerPosition",
  getApplicationTrendsByDayByJobId: "getApplicationTrendsByDayByJobId",
  getTop10CoursesByCompletion: "getTop10CoursesByCompletion",
  getNewRegistrationMetrics: "getNewRegistrationMetrics",
  getAssessmentMetricsByStatus: "getAssessmentMetricsByStatus",
  cleanupUserRole: "cleanupUserRole",
  cleanupOrphanedAuth: "cleanupOrphanedAuth",
  commCreateUser: "commCreateUser",
  commCheckUser: "commCheckUser",
  csvUsers: "csvUsers",
  deleteApplicationRecursively: "deleteApplicationRecursively",
};

export default cloudFunctionNames;

import { useEffect, useState } from "react";
import { Chat } from "../../dataTypes/Messages";
import {
  newestMessage,
  useAllMessagesByChatId,
  markChatRead,
} from "../../data/messages";
import { useUser } from "../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { DisplayTime, DisplayTimestamp } from '../../util/timestamps/timestamp';
import useModal from '../../hooks/useModal';
import DialogWrapper from '../custom-ui/dialog/Dialog';
import { useAuth } from '../../providers/auth';
import { createNewConversation } from '../../data/messages';
import { useUsersByRole } from '../../data/user';

const SeekerMessageTabContentsEmpty = ({ type }: { type: string }) => {
  const userId: string =
    localStorage.getItem("__user_id")?.replace(/"/g, "") || "";
  const [unreadIds, setUnreadIds] = useState([]);
  const { user } = useAuth();
  const [defaultText, setDefaultText] = useState("");
  const[avatar, setAvatar] = useState("");
  const[title, setTitle] = useState("");
  const [messageData, setMessageData] = useState("");
  const { isShowing, toggle } = useModal();
  const { data: usersByRole } = useUsersByRole(type);

  const getText = () => {
    switch (type) {
      case 'tac':
        setTitle(`Talent Acquisition Consultant`);
        setDefaultText(`We're your dedicated Talent Acquisition Consultant team-providing personalized assistance and expert guidance for job opportunities and employers. With our tailored support, you can rely on us to help you navigate your career journey. Reach out to this channel for expert guidance.`);
        setAvatar("/images/message/tac.png");
        return;
      case 'csss':
        setTitle(`Career Seeker Support Specialist`);
        setDefaultText(`Our passionate Career Seeker Support Specialist team is dedicated to helping you thrive with Instant Teams. Get comprehensive support to make informed career decisions and enhance your skills. Connect with us for personalized assistance.`);
        setAvatar("/images/message/csss.png");
        return;
      case 'sadmin':
        setTitle(`Technical Support`);
        setDefaultText(`Did you encounter technical challenges on the Instant Teams job app? Our dedicated Technical Support team is ready to help! From troubleshooting navigation to fixing access issues, we'll provide the assistance you need.`);
        setAvatar("/images/message/helpdesk.png");
        return;
      default:
        return '';
    }
  };

  useEffect(() => {
    getText();
  }, []);

  const handleCreateConversation = () => {
    const newData = {
      ...messageData,
      body: `New web message from ${user?.name}`,
      messageType: "group",
      group: type,
      unreadUsers: unreadIds,
      createdByName: user?.name,
    };
    createNewConversation(newData);
  };

  useEffect(() => {
    setMessageData({
      recipientEmail: user?.email,
      recipientId: user?.uid,
      recipientName: user?.name,
      status: "inbox",
      userId: user?.uid,
    });
  }, [user]);

  useEffect(() => {
    const userIds = usersByRole
      ?.map((item: any) => item.id)
      .filter((id: string) => id !== user?.uid);
    if (
      userIds?.length > 0
    ) {
      setUnreadIds([...userIds]);
    }
  }, [usersByRole, user?.uid]);

  return (
    <div className="pb-2 pt-6 flex justify-center items-center cursor-pointer" onClick={toggle}>
      <div className="w-1/5 flex justify-center ">
        {avatar && (
          <img
            src={avatar}
            alt=""
            className={`rounded-full h-12 w-12`}
          />
        )}        
      </div>
      <div className="w-4/5 pr-4 h-1/2">
        <p>{title}</p>
        <div className="text-xs flex items-center">
          {defaultText}
        </div>
      </div>
      {isShowing && (
        <DialogWrapper title={`Start chat with ${title}`} onClose={toggle}>
          <div>
            <p>{defaultText}</p>
            <button
              className="w-full px-4 py-2 h-auto text-white bg-instant-teams-blue-Main border-2 border-instant-teams-blue-Main rounded hover:bg-white hover:text-instant-teams-blue-Main focus:outline-none text-sm mt-4"
              onClick={() => handleCreateConversation()}
            >
            Start New Conversation
          </button>
          </div>
        </DialogWrapper>
      )}
    </div>
  );
};

export default SeekerMessageTabContentsEmpty;

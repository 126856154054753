import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  connectFirestoreEmulator,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { createQuery, format } from "../providers/database";
import { firebaseApp } from "../providers/firebase";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";
import { errorLogger } from "../providers/clientLogger";
import { User } from "../dataTypes/User";
import { z } from "zod";

export const db = getFirestore(firebaseApp);

export function useUser(uid: string) {
  return useQuery(
    ["user", { uid }],
    createQuery(() => doc(db, collectionNames.users, uid)),
    { enabled: !!uid },
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid: string) {
  return getDoc(doc(db, collectionNames.users, uid)).then(format);
}

export function useUserStatus(id: string) {
  return useQuery(
    ["userStatus", { id }],
    createQuery(() => doc(db, "UserStatus", id)),
    { enabled: !!id },
  );
}

// Create a new user
export function createUser(uid: string, data: User) {
  return setDoc(doc(db, collectionNames.users, uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid: string, data: User) {
  return updateDoc(doc(db, collectionNames.users, uid), data);
}

// Update existing user claims on User collection
export function updateUserOnboardedStatus(uid: string, isOnboarded: boolean) {
  return updateDoc(doc(db, collectionNames.users, uid), {
    "claims.onboarded": isOnboarded,
  });
}

export function disableUser(uid: string) {
  return updateDoc(doc(db, "users", uid), { active: false });
}

export function useNotesUser(uid: string) {
  return useQuery(
    ["notesUser", { uid }],
    createQuery(() => doc(db, collectionNames.users, uid)),
    { enabled: !!uid },
  );
}
export function useUserByEmail(email: string, trigger = true) {
  const emailSchema = z.object({
    email: z.string().email(),
  });
  const result = emailSchema.safeParse(email);

  return useQuery(
    ["userByEmail", { email }],
    createQuery(() => {
      console.log("ran query...");
      return query(
        collection(db, collectionNames.users),
        where("email", "==", email),
        limit(1),
      );
    }),
    { enabled: !!email && !!result && trigger },
  );
}

export function useUserByEmail2(email: string) {
  const emailSchema = z.object({
    email: z.string().email(),
  });
  const result = emailSchema.safeParse(email);

  return useQuery(
    ["userByEmail", { email }],

    createQuery(() => {
      console.log("ran query...");
      return query(
        collection(db, collectionNames.users),
        where("email", "==", email),
        limit(1),
      );
    }),
    { enabled: !!email && !!result },
  );
}

export function useUsersByRole(role: string) {
  return useQuery(
    ["allUsersByRole", { role }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("claims.role", "==", role),
        where("claims.active", "==", true),
      );
    }),
    { enabled: !!role },
  );
}
export function useUsersByStatus(status: string) {
  return useQuery(
    ["allUsersByStatus", { status }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("status", "==", status),
      );
    }),
    { enabled: !!status },
  );
}
export function useUsersByStatusWithExcludedRole(status: string, role: string) {
  return useQuery(
    ["allUsersByStatus", { status }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.users),
        where("status", "==", status),
        where("claims.role", "!=", role),
      );
    }),
    { enabled: !!status },
  );
}

export function useAllUsers() {
  return useQuery(
    ["allUsers"],
    createQuery(() => {
      return query(collection(db, collectionNames.users));
    }),
    { enabled: true },
  );
}

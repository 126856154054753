import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { LoaderIcon, toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth, handleVerifyEmail } from "../../providers/auth";
import ToastAlert from "../../components/custom-ui/toast-alert/ToastAlert";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";

const AuthActionTemplate = () => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [actionMessage, setActionMessage] = useState(
    "Confirming your account..."
  );
  const [mode, setMode] = useState(searchParams.get("mode") || null);
  const [passwordReset, setPasswordReset] = useState(false);

  useEffect(() => {
    console.log(searchParams.get("mode"));
    if (searchParams.get("mode") === "revertSecondFactorAddition") {
      setActionMessage(
        "Thank you for enrolling in multifactor authentication."
      );
    }
    if (searchParams.get("mode") === "verifyEmail") {
      handleVerifyEmail(searchParams.get("oobCode"))
        .then(() => {
          setActionMessage("Thank you for verifying your email address.");
        })
        .catch((error) => {
          setActionMessage(
            "There was a problem attempting to verify your email."
          );
        });
    }
    if (searchParams.get("mode") === "recoverEmail") {
      setActionMessage("Thank you for updating your email address.");
    }
    if (searchParams.get("mode") === "resetPassword") {
      setActionMessage("Your password has been successfully reset.");
    }
  }, [searchParams]);

  const [viewPassword, setViewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password,
      secondPassword: confirmPassword,
    });

  const [reqMet, setReqMet] = useState(false);

  useEffect(() => {
    if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
      setReqMet(true);
    } else setReqMet(false);
  }, [validLength, hasNumber, upperCase, lowerCase, match, specialChar]);

  const navigateSignin = () => {
    navigate("/auth/signin");
  };

  const handlePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!loading);
    auth
      .confirmPasswordReset(password, searchParams.get("oobCode"))
      .then(() => {
        console.log("Password reset successfully");
        setPassword("");
        setConfirmPassword("");
        toast.success("Password reset successfully");
      })
      .catch((error) => {
        console.log("Error:", error);
        toast.error("Error resetting password");
      });
    setTimeout(setPasswordReset(true), 6000);
  };

  useEffect(() => {
    if (
      password === confirmPassword &&
      password.length !== "" &&
      confirmPassword !== ""
    ) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [password, confirmPassword]);

  return (
    <div className=" bg-white xs:pt-0 sm:pt-0">
      <div
        className="flex flex-row sm:min-h-content min-h-screen w-screen bg-white"
      >
      <div className="w-2/5 overflow-hidden sm:hidden xs:hidden">
        <img src="./images/mask_group.png" alt="" className="h-full"/>
      </div>
      {mode !== "resetPassword" || passwordReset ? (
        <div className="w-3/5 xs:w-full sm:w-full flex flex-col items-center justify-start text-xl font-bold text-center">
          <h1 className=" p-4 text-instant-teams-teal-Main">{actionMessage}</h1>
          <i className="fa-rounded fa fa-check-circle text-green-500 text-5xl p-8"></i>
          <div className=" text-base">
            <p className=" text-center">
              If you did not make this request, please contact:
            </p>
            <a
              href="mailto:helpdesk@instantteams.com"
              className="text-center mx-auto"
            >
              helpdesk@instantteams.com
            </a>
          </div>
          <div className="flex flex-row w-3/4 xs:flex-col sm:flex-col  items-center  mx-auto justify-around max-h-36">
            <img
              src="/images/instant-teams-logo/InstantTeamsLogoMain-01.png"
              className="w-fit h-3/4 mt-4 xs:hidden sm:hidden block"
              alt="12 Million Plus Logo"
            />
            <img
              src="/images/12-mil-plus/12-mil-logo.png"
              className=" w-fit h-full  mt-4"
              alt="12 Million Plus Logo"
            />
          </div>
        </div>
      ) : (
        <div className="w-3/5 xs:w-full sm:w-full flex flex-col items-center sm:justify-start justify-center  text-xl font-bold text-center">
          <div className="mt-4 flex flex-col justify-center items-center text-center">
            <p className="text-xl font-bold pb-10 text-instant-teams-teal-D2">
              Reset Your Password.
            </p>
            <p className="text-base font-normal pb-10 text-instant-teams-teal-D2">
              Please enter your new password.
            </p>
            <form
              className="grid grid-cols-1 gap-3 mb-2 relative w-full"
              onSubmit={handleSubmit}
            >
              {viewPassword ? (
                <i
                  className="fa-solid fa-eye absolute p-2.5 w-10 right-4"
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                />
              ) : (
                <i
                  className="fa-solid fa-eye-slash  absolute p-2.5 w-10 right-4"
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                />
              )}
              <div className="flex flex-col gap-5">
                <input
                  type={viewPassword ? "text" : "password"}
                  placeholder="Password"
                  onChange={handlePassword}
                  value={password}
                  className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight ${
                    reqMet
                      ? "focus:border-8 focus:border-green-500 focus:ring-4 focus:ring-green-400"
                      : "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"
                  }`}
                />
                <ul className=" list-disc text-left text-sm flex flex-col gap-3 pl-4">
                  <li>At least 8 characters</li>
                  <li>At least one capital letter</li>
                  <li>At least one lower case letter</li>
                  <li>At least one number or symbol</li>
                </ul>
              </div>
              <input
                type={viewPassword ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={handleConfirmPassword}
                className={`shadow-sm appearance-none rounded-lg w-96 xs:w-full text-xl py-2 px-5 leading-tight ${
                  passwordsMatch
                    ? "focus:border-8  focus:border-green-500 focus:ring-4 focus:ring-green-400"
                    : "focus:border-8 focus:border-red-500 focus:ring-4 focus:ring-red-500"
                }`}
              />
              <div className="flex flex-col justify-center items-center gap-4 mt-8">
                {passwordsMatch && reqMet ? (
                  <button
                    className="w-48 h-10 mt-8 font-semibold text-white bg-instant-teams-blue-Main rounded shadow-xl2 hover:bg-instant-teams-blue-L1"
                    type="submit"
                  >
                    {/* &#10140; */}
                    Submit
                  </button>
                ) : (
                  <button
                    className="w-48 h-10 mt-8 font-semibold text-white bg-gray-600 rounded shadow-xl2"
                    disabled
                  >
                    {/* &#10140; */}
                    Submit
                  </button>
                )}
                <div
                  onClick={navigateSignin}
                  className="font-normal text-base underline"
                >
                  Back to Login
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastAlert />
      </div>
    </div>
  );
};

export default AuthActionTemplate;

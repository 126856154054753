import React, { useEffect, useState } from "react";
import { useAuth } from "../providers/auth";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { internalRoute } from "./internal/internalRoutes";
import { employerRoute } from "./external/employerRoutes";
import { seekerRoute } from "./seeker/seekerRoutes";
import { publicRoute } from "./publicRoutes";
import { useAllActiveAlerts } from "../data/alerts";
// confused, checkout ReadMe.md
// still confused, reach out to Amil
function ConditionalRouter() {
  // we don't have access to useAuth in _app.js
  const auth = useAuth();
  const [routes, setRoutes] = useState(internalRoute);
  const { data: alerts } = useAllActiveAlerts();

  useEffect(() => {
    sessionStorage.removeItem("hideAlerts");
  }, [alerts?.length]);

  useEffect(() => {
    // set to public routes that have no auth HOC
    if (!auth.user) {
      setRoutes(publicRoute);
    }
    // set to employer routes
    if (auth.userClaims?.claims?.type === "E") {
      setRoutes(employerRoute);
      console.log("employer routes", routes);
    } else if (auth.userClaims?.claims?.type === "S") {
      // set to seeker routes
      setRoutes(seekerRoute);
    } else {
      // set to internal routes
      setRoutes(internalRoute);
    }
  }, [auth]);
  const artemisRoutes = createBrowserRouter(routes);

  return <RouterProvider router={artemisRoutes} />;
}

export default ConditionalRouter;

import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  runTransaction,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db, client, createQuery, format } from "../providers/database";
import { JobDescription } from "../dataTypes/JobDescription";
import { File } from "../dataTypes/Utilities";
import {
  collectionNames,
  subCollectionNames,
} from "./dictionary/collectionNames";

const jobDescriptionCollectionRef = collection(
  db,
  collectionNames.jobDescriptions,
);

const getJobDescriptionDocRef = (jobDescriptionId: string) =>
  doc(db, collectionNames.jobDescriptions, jobDescriptionId);

// Create a new job description
export async function createJobDescription(jobDescription: JobDescription) {
  const {
    model = [],
    term = [],
    classification = [],
    payType = [],
    hoursPerWeek = [40, 45],
    ...jobData
  } = jobDescription ?? {};
  // updated payType structure and default values
  const payTypeObject = payType.reduce(
    (accumulator: any, currentValue: any) => {
      if (currentValue === "SAL") {
        return { ...accumulator, ...{ [currentValue]: [10000, 80000] } };
      } else if (currentValue === "HRLY") {
        return { ...accumulator, ...{ [currentValue]: [10, 85] } };
      } else if (currentValue === "COMM") {
        return { ...accumulator, ...{ [currentValue]: "" } };
      }
      return accumulator;
    },
    {},
  );

  const newJob = await addDoc(jobDescriptionCollectionRef, {
    ...jobData,
    status: "tac_review",
    information: {
      model,
      term,
      classification,
      payType: payTypeObject,
      hoursPerWeek,
    },
  });
  return newJob.id;
}

// Creates the text description on JobDescriptions
export function createDescription(
  jobDescriptionId: string,
  description: string,
) {
  const jobDescriptionDocRef = getJobDescriptionDocRef(jobDescriptionId);

  return updateDoc(jobDescriptionDocRef, {
    clientDescription: description,
  });
}

export function writeFileLocation(jobDescriptionId: string, file: File) {
  const jobDescriptionDocRef = getJobDescriptionDocRef(jobDescriptionId);
  const jobFileSubCollectionRef = collection(
    jobDescriptionDocRef,
    subCollectionNames.files,
  );
  return addDoc(jobFileSubCollectionRef, { file: file });
}

export function useJobDescription(jobId: string) {
  return useQuery(
    ["jobDescription", { jobId }],
    createQuery(() => doc(db, collectionNames.jobDescriptions, jobId)),
    { enabled: !!jobId },
  );
}

export function useAllJobDescriptionsByStatus(status: string) {
  return useQuery(
    ["jobDescriptionsByStatus", { status }],
    createQuery(() =>
      query(
        collection(db, collectionNames.jobDescriptions),
        where("status", "==", status),
      ),
    ),
  );
}

export function useJobDescriptionFiles(id: string) {
  return useQuery(
    ["allJobDescriptionFiles", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.files}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function useJobDescriptionSkills(id: string) {
  return useQuery(
    ["allJobDescriptionSkills", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.skills}`,
        ),
      );
    }),
    { enabled: !!id },
  );
}

export function addSkill(
  jobDescriptionId: string,
  skill: string,
  tier: string,
  who: string,
) {
  const jobDescriptionDocRef = getJobDescriptionDocRef(jobDescriptionId);
  const jobFileSubCollectionRef = collection(
    jobDescriptionDocRef,
    subCollectionNames.skills,
  );

  const update = {
    assessmentId: skill,
    tier: tier,
    who: who,
  };

  return addDoc(jobFileSubCollectionRef, update);
}

export function deleteSkill(id: string, jobId: string) {
  return deleteDoc(
    doc(
      db,
      `${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.skills}`,
      id,
    ),
  );
}

export function useJobInterviewQuestions(id: string) {
  return useQuery(
    ["allJobInterviewQuestions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.interviewQuestions}`,
        ),
        orderBy("sortOrder"),
      );
    }),
    { enabled: !!id },
  );
}

export function useJobInterviewQuestion(id: string, questionId: string) {
  return useQuery(
    ["jobQuestion", { id, questionId }],
    createQuery(() =>
      doc(
        db,
        `${collectionNames.jobDescriptions}/${id}/${subCollectionNames.interviewQuestions}/${questionId}`,
      ),
    ),
    { enabled: !!id && !!questionId },
  );
}

export function addJobInterviewQuestion(jobId: string, question: any) {
  delete question.id;
  return addDoc(
    collection(
      db,
      `${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.interviewQuestions}`,
    ),
    { ...question },
  );
}

export function removeJobInterviewQuestion(jobId: string, questionId: string) {
  const questionRef = doc(
    db,
    `${collectionNames.jobDescriptions}/${jobId}/${subCollectionNames.interviewQuestions}/${questionId}`,
  );
  return deleteDoc(questionRef);
}


export function useJobsByDepartment(id: string) {
  return useQuery(
    ["allJobInterviewQuestions", { id }],
    createQuery(() => {
      return query(
        collection(
          db,
          `${collectionNames.jobDescriptions}`,
        ),
        where("department", "==", id),
      );
    }),
    { enabled: !!id },
  );
}
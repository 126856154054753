import { useEffect } from "react";
import Meta from "../../components/custom-ui/meta/Meta";
import { useGlobalState } from "../../providers/globalState/GlobalStateProvider";
import { useEmployer, useEmployerJobsByStatus } from "../../data/employer";
import { Link, useParams } from "react-router-dom";
import ExternalLinkIcon from "../../components/custom-ui/icons/ExternalLinkIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/dangerouslySet.css";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import { useState } from "react";
import { requireAuth } from "../../providers/auth";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
function SeekerViewCompanyDetails() {
  const INSTANTTEAMS_ID = process.env.REACT_APP_INSTANT_TEAMS_ID;
  const { companyId = "" } = useParams();
  const { globalState, setGlobalState } = useGlobalState();
  const { data: employerData } = useEmployer(companyId);
  const [videoURL, setVideoUrl] = useState("");
  const { data: employerJobsData, isLoading } = useEmployerJobsByStatus(
    companyId,
    "live",
  );
  const removedBPOJobsData = employerJobsData?.filter(
    (job: any) => job?.solutionType !== "BPO",
  );

  const [employerId, setEmployerId] = useState("");

  useEffect(() => {
    if (employerData?.id === INSTANTTEAMS_ID) {
      setEmployerId(INSTANTTEAMS_ID);
    } else {
      setEmployerId(employerData?.id);
    }
    return () => setEmployerId("");
  }, [employerData, INSTANTTEAMS_ID]);

  useEffect(() => {
    const employerName = employerData?.displayName ?? "";
    if (employerName) {
      setGlobalState({
        ...globalState,
        pageTitle: employerName,
      });
    }
  }, [employerData]);

  useEffect(() => {
    const videoLink = employerData?.profile?.video;
    const cleanLink = videoLink?.split("watch?v=")[1];
    setVideoUrl(cleanLink);
  }, [employerData]);

  const isInstantTeamsEmployer = employerId === INSTANTTEAMS_ID;
  const hasRemovedBPOJobs = removedBPOJobsData?.length > 0;
  const isDisabled = !isInstantTeamsEmployer && !hasRemovedBPOJobs;

  if (isLoading) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  }
  return (
    <>
      <Meta title="Companies" />
      <section className="pt-40 pb-16">
        <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 w-3/4">
          <img
            className=" col-span-1 w-24 h-24 object-contain  "
            src={employerData?.logo}
            alt={employerData?.displayName}
          />

          <div className="col-span-4 my-auto text-instant-teams-blue-Main justify-start text-2xl font-semibold  md:text-sm md:pl-2 lg:pl-2">
            {employerData?.displayName}
          </div>
          <div className="col-span-3 mx-auto my-auto w-56 ">
            <Link to={`/jobs?company=${employerId}`}>
              <button
                // disabled={removedBPOJobsData?.length > 0 ? false : true}
                disabled={isDisabled}
                className="py-3 w-full  uppercase bg-instant-teams-blue-Main  hover:bg-instant-teams-blue-D1 text-white rounded-lg md:py-2  md:text-sm lg:py-2 "
              >
                View Jobs
              </button>
            </Link>
          </div>

          {/* Inner Grid to display details */}
          <div className="col-span-3 mt-8 grid grid-flow-row grid-cols-3 text-instant-teams-blue-Main md:text-xs">
            <div className="col-span-1">Founded</div>
            <div className="col-span-2">{employerData?.intel?.foundedYear}</div>
            <div className="col-span-1">Address</div>
            <div className="col-span-2">
              {employerData?.address?.standardizedAddress}
            </div>
            <div className="col-span-1">Size</div>
            <div className="col-span-2">
              {employerData?.intel?.metrics?.employeesRange}
            </div>
            <div className="col-span-1">Industry</div>
            <div className="col-span-2">
              {employerData?.intel?.category?.industry}
            </div>
            <div className="col-span-1">Type</div>
            <div className="col-span-2">
              {employerData?.intel?.type?.replace(/^\w/, (char: string) =>
                char?.toUpperCase(),
              )}
            </div>
            <div className="col-span-1">Website</div>

            <div className="flex">
              <ExternalLinkIcon
                href={employerData?.intel?.url}
                linkText={employerData?.intel?.url}
              />
            </div>
            <div className="col-span-3 mt-5 w-full space-x-8 sm:space-x-1 md:space-x-1 lg:space-x-3">
              {employerData?.profile?.facebook && (
                <a
                  href={employerData?.profile?.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="2x" icon={faFacebook} />
                </a>
              )}
              {employerData?.profile?.instagram && (
                <a
                  href={employerData?.profile?.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="2x" icon={faInstagram} />
                </a>
              )}
              {employerData?.profile?.twitter && (
                <a
                  href={employerData?.profile?.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="2x" icon={faTwitter} />
                </a>
              )}
              {employerData?.profile?.linkedin && (
                <a
                  href={employerData?.profile?.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon size="2x" icon={faLinkedin} />
                </a>
              )}
            </div>
          </div>
          {/* Empty div element  */}
          <div className=" col-span-1"></div>
          <div className="col-span-4 mt-2 ">
            {videoURL ? (
              <div className="mx-auto m-5 w-full">
                <iframe
                  title={videoURL}
                  width="760"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoURL}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full mx-auto"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-8 flex flex-col">
            <div
              className="dangerously"
              dangerouslySetInnerHTML={sanitizeMarkup(
                employerData?.profile?.intro,
              )}
            ></div>
            {employerData?.profile?.overview && (
              <>
                <GreenUnderlineHeading title="Overview" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.overview,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.mission && (
              <>
                <GreenUnderlineHeading title="Mission" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.mission,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.military && (
              <>
                <GreenUnderlineHeading title="Military Impact" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.military,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.values && (
              <>
                <GreenUnderlineHeading title="Core Values" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.values,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.dei && (
              <>
                <GreenUnderlineHeading title="DIVERSITY, EQUITY & INCLUSION" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.dei,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.culture && (
              <>
                <GreenUnderlineHeading title="Culture" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.culture,
                  )}
                ></div>
              </>
            )}
            {employerData?.profile?.social && (
              <>
                <GreenUnderlineHeading title="Social" />
                <div
                  className="dangerously"
                  dangerouslySetInnerHTML={sanitizeMarkup(
                    employerData?.profile?.social,
                  )}
                ></div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default requireAuth(SeekerViewCompanyDetails);

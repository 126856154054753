import { useEffect } from "react";
import { useAuth } from "../providers/auth";
import { Outlet, useNavigate } from "react-router-dom";
import ScrollToTop from "../providers/router";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import RotatorDisplay from "./seekerRotator/parts/rotatorDisplay";

function IndexPage(props) {
  const { user, userClaims } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("---> Current path: ", window.location.pathname);
    if (window.location.pathname === "/") {
      user
        ? navigate("/dashboard/home", { replace: true })
        : navigate("/auth/signin", { replace: true });
    }
  }, [user]);
  return (
    <>
      {userClaims?.claims?.type === "S" ? (
        <>
          <ScrollToTop />
          <Header />
          <main className="clear-both bg-gray-50 min-h-screen">
            <div className="grid grid-cols-12 gap-4 w-full">
              <div className="xs:hidden sm:hidden md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4">
                <RotatorDisplay />
              </div>
              <div className="xs:col-span-12 sm:col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-8 2xl:col-span-8">
                <Outlet />
              </div>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <>
          <ScrollToTop />
          <Header />
          <main className="clear-both bg-gray-50 min-h-screen">
            <Outlet />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}

export default IndexPage;

import DatePicker from "react-datepicker";
import { forwardRef, useState, useEffect, useRef } from "react";
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DragAndDropVariantA from "../../custom-ui/drag-and-drop/DragAndDropFile";
import { useEmployerUsers } from "../../../data/employer";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import {
  TooltipRail,
  Handle,
  Track,
} from "../../custom-ui/input-fields/range-slider/RangeSlider";
import { useAuth } from "../../../providers/auth";
import { Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  updateApplicationStatus,
  useApplication,
} from "../../../data/applications";
import { useUser } from "../../../providers/database";
import { useJobDescription } from "../../../data/jobDescriptions";
import { createOffer, useEmployerReciepient } from "../../../data/offers";
import { createNotification } from "../../../data/notifications";
import {
  toUSCurrencyNoChange,
  toUSCurrency,
} from "../../../util/helpers/toUSCurrency";
import { updateStatusHistory } from "../../../data/utility";
import { collectionNames } from "../../../data/dictionary/collectionNames";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

function ExternalOfferForm({
  position,
  toggleOfferModal,
  passedInApplicationId = "",
}) {
  const NOT_REQUIRED = "Not Required";
  const { userClaims } = useAuth();

  const params = useParams();
  const { applicationId = "" } = params ?? {};

  const { data: applicationData } = useApplication(
    applicationId || passedInApplicationId,
  );
  const { employer, resumeId, jobId, positionId } = applicationData;
  const [signature, setSignature] = useState(NOT_REQUIRED);

  const { data: employerUsers } = useEmployerUsers(position.employer);
  const { data: recipientData } = useUser(resumeId);
  const { data: employerRecipientData } = useEmployerReciepient(signature);
  const { data: jobData } = useJobDescription(jobId);

  const [startDate, setStartDate] = useState(new Date());
  const [file, setFile] = useState();
  const [isSignatureRequired, setIsSignatureRequired] = useState(false);
  const [passed, setPassed] = useState(false);
  const [isCommission, setIsCommission] = useState(false);
  const [pay, setPay] = useState(0);
  const [commisionDetails, setCommisionDetails] = useState("");
  const [offerLetterIsSigned, setOfferLetterIsSigned] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    position.information.payType.COMM
      ? prepareDraft(position.information.payType.COMM)
      : EditorState.createEmpty(),
  );

  const selectedUserRef = useRef(null);

  const payTypeCode = position?.information?.payType
    ? Object.keys(position?.information?.payType)
    : "not assigned";

  const sliderStyle = {
    position: "relative",
    width: "95%",
  };

  // pay can be salary or hourly
  // Just in case the slider doesn't get updated
  useEffect(() => {
    if (position?.information?.payType["SAL"]) {
      setPay(
        (position?.information?.payType["SAL"][0] +
          position?.information?.payType["SAL"][1]) /
          2,
      );
    } else {
      setPay(
        (position?.information?.payType["HRLY"][0] +
          position?.information?.payType["HRLY"][1]) /
          2,
      );
    }
  }, [position]);

  // signature is either required or not.
  // pay when saved from slides gets passed in as an array
  useEffect(() => {
    if (
      startDate &&
      pay[0] !== 0 &&
      file &&
      ((isSignatureRequired && signature !== NOT_REQUIRED) ||
        !isSignatureRequired)
    ) {
      setPassed(true);
    } else {
      setPassed(false);
    }
  }, [startDate, pay, file, isSignatureRequired, signature]);

  useEffect(() => {
    setCommisionDetails(position.information.payType.COMM);
  }, [position]);

  useEffect(() => {
    payTypeCode.includes("COMM")
      ? setIsCommission(true)
      : setIsCommission(false);
  }, [position]);

  const handleCheckboxChange = () => {
    setIsSignatureRequired(!isSignatureRequired);
    selectedUserRef.current.value = "";
    setSignature(NOT_REQUIRED);
  };

  const handleCheckbox = (e) => {
    const { value: checked } = e.target;
    if (checked) {
      setOfferLetterIsSigned(true);
    }
  };

  const handleSubmit = async () => {
    const data = {
      applicationId,
      employerId: employer,
      resumeId,
      jobId,
      positionId,
      payRate: pay,
      payType: Object.keys(position.information.payType)[0],
      file,
      startDate,
      createdBy: userClaims?.claims?.user_id,
      isCommission: isCommission,
      commissionDetails: isCommission ? commisionDetails : "",
      createdAt: Timestamp.now(),
      recipientEmail: recipientData?.email ? recipientData?.email : "",
      solutionType: jobData.solutionType ?? "",
      offerLetterIsSigned: offerLetterIsSigned,
      // save employerUserId to offer if employer signature is required
      employerUserId: signature === NOT_REQUIRED ? "" : signature,
      employerUserEmail:
        signature === NOT_REQUIRED ? "" : employerRecipientData?.email,
      awaitingEmployerSignature: signature === NOT_REQUIRED ? false : true,
    };
    const notificationToTacData = {
      title: "A New Offer Has Been Submitted",
      body: `A new offer has been sent to ${recipientData?.name}`,
      createdBy: userClaims?.claims?.user_id,
      isPushNotification: true,
      url: `applications/${applicationId}/details`,
      group: "tac",
    };
    try {
      await createOffer(data);
      const history = {
        alteredBy: userClaims?.claims?.user_id,
        effectiveDate: Timestamp.now(),
        from: applicationData?.status,
        status: "offered",
      };
      await updateApplicationStatus(applicationId, "offered");
      await updateStatusHistory(
        collectionNames.applications,
        applicationId,
        history,
      );
      await createNotification(notificationToTacData);
      await new Promise((resolve) => {
        toggleOfferModal();
        resolve();
      });
    } catch (error) {
      console.log("error occured when submitting offer", error);
    }
  };
  // const handleSubmit = {};
  function prepareDraft(value) {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    return editorState;
  }

  const onEditorStateChange = (newEditorState) => {
    const content = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent()),
    );
    setEditorState(newEditorState);
    setCommisionDetails(content);
  };

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <div key={value} className="custom-date-input w-full">
      <input
        ref={ref}
        className="px-8 w-full"
        type="text"
        value={value}
        onClick={onClick}
        onChange={(e) => {
          e.preventDefault();
        }}
        placeholder="Select date"
      />
      <span className="absolute left-2 top-1.5" onClick={onClick}>
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="text-instant-teams-gray-D1"
        />
      </span>
    </div>
  ));

  return (
    <>
      <h1 className="text-2xl text-instant-teams-blue-Main font-semibold uppercase flex justify-center">
        {position.positionName}
      </h1>
      <div className="grid grid-cols-2 gap-4 w-full pb-2">
        {/* Left Row */}
        <div>
          <GreenUnderlineHeading title="Start Date" />
          <div>
            <DatePicker
              showIcon
              minDate={new Date()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              isClearable={true}
              customInput={<CustomDateInput />}
              showTimeInput
              dateFormat="yyyy/MM/dd HH:mm"
            />
          </div>
          <div className="flex  justify-between">
            <GreenUnderlineHeading title="Employer Signature" />
            <div className=" justify-items-end">
              <button
                type="button"
                className={` mt-7 cursor-pointer ${
                  isSignatureRequired
                    ? "bg-instant-teams-green-Main"
                    : "bg-gray-300"
                } relative inline-flex items-center h-6 rounded-full w-11`}
                onClick={handleCheckboxChange}
              >
                {" "}
                <span
                  className={`${
                    isSignatureRequired ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full`}
                />
              </button>
            </div>
          </div>
          <div className="flex align-middle justify-center">
            {employerUsers && employerUsers.length > 0 ? (
              <select
                className="w-full"
                name="users"
                disabled={!isSignatureRequired}
                ref={selectedUserRef}
                onChange={() => {
                  setSignature(selectedUserRef.current.value);
                }}
              >
                <option value="" defaultValue>
                  Select User
                </option>
                {employerUsers.map((employer) => (
                  <option key={employer.id} value={employer.id}>
                    {employer.preferredName}
                  </option>
                ))}
              </select>
            ) : (
              <p>No employers found.</p>
            )}
          </div>
        </div>

        {/* Right Row */}
        <div>
          <GreenUnderlineHeading title="Pay Rate" />
          <div className="mb-4 -mt-5">
            {payTypeCode.includes("SAL") ? (
              <>
                <label className=" ">Salary</label>
                <div className="mt-2 pt-8 pb-6  ">
                  <Slider
                    mode={3}
                    step={100}
                    domain={position?.information?.payType["SAL"]}
                    rootStyle={sliderStyle}
                    // puts the slider in the middle
                    values={[
                      (position?.information?.payType["SAL"][0] +
                        position?.information?.payType["SAL"][1]) /
                        2,
                    ]}
                    onSlideEnd={(data) => {
                      setPay(data[0]);
                    }}
                  >
                    <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>

                    <Handles>
                      {({ handles, activeHandleID, getHandleProps }) => {
                        return (
                          <div className="slider-handles">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={position?.information?.payType["SAL"]}
                                isActive={handle.id === activeHandleID}
                                getHandleProps={getHandleProps}
                                formatTooltip={toUSCurrencyNoChange}
                              />
                            ))}
                          </div>
                        );
                      }}
                    </Handles>
                    <Tracks left={false} right={false}>
                      {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                          {tracks.map(({ id, source, target }) => (
                            <Track
                              key={id}
                              source={source}
                              target={target}
                              getTrackProps={getTrackProps}
                            />
                          ))}
                        </div>
                      )}
                    </Tracks>
                    <Ticks values={position?.information?.payType["SAL"]}>
                      {({ ticks }) => (
                        <div className="slider-ticks flex space-x-80">
                          {ticks.map((tick, index) => {
                            return (
                              <span className="py-4" key={tick.id}>
                                {toUSCurrencyNoChange(tick.value)}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </Ticks>
                  </Slider>
                </div>
              </>
            ) : null}
            {payTypeCode.includes("HRLY") ? (
              <>
                <label className="text-instant-teams-teal-Main ">Hourly</label>
                <div className="mt-2 pt-8 pb-6  ">
                  <Slider
                    mode={3}
                    step={0.5}
                    domain={position?.information?.payType["HRLY"]}
                    rootStyle={sliderStyle}
                    // puts the slider in the middle
                    values={[
                      (position?.information?.payType["HRLY"][0] +
                        position?.information?.payType["HRLY"][1]) /
                        2,
                    ]}
                    onSlideEnd={(data) => {
                      setPay(data[0]);
                    }}
                  >
                    <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
                    <Handles>
                      {({ handles, activeHandleID, getHandleProps }) => {
                        return (
                          <div className="slider-handles">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={position?.information?.payType["HRLY"]}
                                isActive={handle.id === activeHandleID}
                                getHandleProps={getHandleProps}
                                formatTooltip={toUSCurrency}
                              />
                            ))}
                          </div>
                        );
                      }}
                    </Handles>
                    <Tracks left={false} right={false}>
                      {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                          {tracks.map(({ id, source, target }) => (
                            <Track
                              key={id}
                              source={source}
                              target={target}
                              getTrackProps={getTrackProps}
                            />
                          ))}
                        </div>
                      )}
                    </Tracks>
                    <Ticks values={position?.information?.payType["HRLY"]}>
                      {({ ticks }) => (
                        <div className="slider-ticks flex space-x-80">
                          {ticks.map((tick, index) => {
                            return (
                              <span className="py-4" key={tick.id}>
                                {toUSCurrency(tick.value)}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </Ticks>
                  </Slider>
                </div>
              </>
            ) : null}
            {isCommission ? (
              <div id="information.payType.COMM" className="w-full">
                <div className="text-instant-teams-teal-Main font-bold pl-1 mt-2">
                  Commission Details
                </div>
                <Editor
                  toolbar={{
                    options: ["inline", "blockType", "list", "textAlign"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    textAlign: {
                      options: ["left", "center", "right", "justify"],
                    },
                  }}
                  editorStyle={{ height: "16vh", marginBottom: "5%" }}
                  toolbarClassName="border-solid border-b-2 border-sky-500 p-2 text-xs"
                  wrapperClassName="shadow-md rounded-md bg-white pt-3"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <hr className="-mx-6" />
      <div className="flex flex-col items-center">
        <div className="w-1/2 my-10">
          <DragAndDropVariantA setFile={setFile} />
          <div className="flex w-full justify-center pt-8">
            <input
              className="h-5 w-5 shadow text-instant-teams-teal-Main leading-8 bg-white rounded border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
              name={"payTypeGroup"}
              type="checkbox"
              onChange={handleCheckbox}
            />
            <p className="pl-4">
              Check here if offer letter is already signed by seeker
            </p>
          </div>
        </div>
        {passed ? (
          <button
            className="bg-instant-teams-blue-Main rounded-md text-white uppercase py-2 px-24"
            onClick={handleSubmit}
          >
            Send Offer
          </button>
        ) : (
          <button
            className="bg-instant-teams-gray-D1 rounded-md text-white uppercase py-2 px-24"
            disabled={true}
          >
            Please fill out form
          </button>
        )}
      </div>
    </>
  );
}

export default ExternalOfferForm;

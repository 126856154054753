import {
  faArrowDown,
  faArrowDownFromLine,
  faArrowUp,
  faArrowUpFromLine,
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addEmployerInterviewQuestion,
  removeEmployerInterviewQuestion,
} from "../../../data/employer";
import { updateInterviewQuestionSort } from "../../../data/interviewQuestion";
import { useState } from "react";
import { useEffect } from "react";

function SortableList({
  list = [],
  updateField,
  displayField,
  collection,
  parentId,
  subcollection,
  callback,
  returnItem,
}) {
  const [max, setMax] = useState(list.length * 10);
  const [active, setActive] = useState("");
  function addItem() {
    const count = list.length * 10 + 10;
    const newItem = {
      displayName: "New Item",
      employer: parentId,
      sortOrder: count,
    };
    addEmployerInterviewQuestion(parentId, newItem);
  }

  function removeItem(id) {
    removeEmployerInterviewQuestion(parentId, id);
  }

  function moveUpItem(id) {
    let current = list.find((o, i) => {
      if (o.id === id) {
        return o;
      }
    });
    const currentOrder = current.sortOrder;
    let update = list.find((o, i) => {
      if (o.sortOrder === currentOrder - 10) {
        return o;
      }
    });
    const newOrder = update.sortOrder;
    updateInterviewQuestionSort(
      currentOrder,
      parentId,
      collection,
      subcollection,
      update.id,
    );
    updateInterviewQuestionSort(
      newOrder,
      parentId,
      collection,
      subcollection,
      current.id,
    );
  }

  function selectItem(item) {
    setActive(item.id);
    returnItem(item);
  }

  function moveDownItem(id) {
    let current = list.find((o, i) => {
      if (o.id === id) {
        return o;
      }
    });

    const currentOrder = current.sortOrder;
    let update = list.find((o, i) => {
      if (o.sortOrder === currentOrder + 10) {
        return o;
      }
    });

    const newOrder = update.sortOrder;
    updateInterviewQuestionSort(
      currentOrder,
      parentId,
      collection,
      subcollection,
      update.id,
    );
    updateInterviewQuestionSort(
      newOrder,
      parentId,
      collection,
      subcollection,
      current.id,
    );
  }

  const styling = {
    active:
      "w-full items-start grid grid-cols-8 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 bg-instant-teams-teal-Main text-white",
    inactive:
      "w-full items-start text-instant-teams-teal-Main grid grid-cols-8 gap-1 p-2 mb-2 rounded-lg shadow-lg col-span-9 hover:bg-instant-teams-teal-Main hover:text-white",
  };

  return (
    <div className="w-full">
      <div className="w-full text-right mb-4">
        <button className="" onClick={() => addItem()}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="mr-1 text-instant-teams-green-Main h-6 w-6"
          />
        </button>
      </div>
      {list.map((item) => (
        <div key={item.id} className="grid grid-cols-10 gap-1 mb-4">
          <div className="col-span-1 content-center pt-2 text-bold">
            {item.sortOrder / 10}.
          </div>
          <div
            key={item.id}
            className={active === item.id ? styling.active : styling.inactive}
          >
            <div className="grid grid-cols-2 gap-1">
              <div className="col-span-1 text-right">
                {item.sortOrder !== 10 && (
                  <button
                    className="text-xs"
                    onClick={() => moveUpItem(item.id)}
                  >
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="mr-1 h-4 w-4"
                    />
                  </button>
                )}
              </div>
              <div className="col-span-1 text-left">
                {item.sortOrder < max && (
                  <button className="" onClick={() => moveDownItem(item.id)}>
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className="mr-1 h-4 w-4"
                    />
                  </button>
                )}
              </div>
            </div>
            <div
              key={item.id}
              className="col-span-6 text-center font-bold"
              onClick={() => {
                callback(item.id);
                selectItem(item);
              }}
            >
              {item[displayField]}
            </div>
            <div className="col-span-1 text-right">
              <button className="" onClick={() => removeItem(item.id)}>
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="mr-1 text-instant-teams-orange-D2 h-4 w-4"
                />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SortableList;

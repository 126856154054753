import { useState, useEffect } from "react";
import { useAuth } from "../../../../providers/auth";
import ProgressDots from "../../../custom-ui/progress-dots/ProgressDots";
import SeekerInfo from "./SeekerInfo";
import SeekerPasswordConfirmation from "./SeekerPasswordConfirmation";
import { useNavigate } from "react-router-dom";
import RotatorDisplay from "../../../../pages/seekerRotator/parts/rotatorDisplay";

function SeekerRegistrationIndex() {
  const auth = useAuth();
  useEffect(() => {
    if (auth.user) {
      navigate("/email-verification");
    }
  }, [auth.user]);

  const navigate = useNavigate();
  const totalSteps = 2;
  const [activeStep, setActiveStep] = useState(1);
  const [seekerInfo, setSeekerInfo] = useState();

  const nextStep = () =>
    setActiveStep((prevState: number) => {
      const step = ++prevState;

      return step;
    });

  // needs to set seekerinfo in parent component because
  // data is used to create the user in next step
  const handleSeekerInfoDone = (data: any) => {
    setSeekerInfo(data);
    nextStep();
  };

  const renderActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <div className="flex justify-center">
              <h1 className="mt-12 text-2xl font-bold pb-8 text-instant-teams-teal-Main">
                Create an Account
              </h1>
            </div>
            <SeekerInfo onDone={handleSeekerInfoDone}>
              <div className="my-4">
                <ProgressDots totalSteps={totalSteps} activeStep={activeStep} />
              </div>
            </SeekerInfo>
          </>
        );
      case 2:
        return (
          <>
            <div className="flex justify-center">
              <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
                Set Password
              </h1>
            </div>
            <SeekerPasswordConfirmation
              onDone={() => navigate("/email-verification")}
              seekerInfo={seekerInfo}
            >
              <div className="my-4">
                <ProgressDots totalSteps={totalSteps} activeStep={activeStep} />
              </div>
            </SeekerPasswordConfirmation>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className="flex  min-h-screen bg-white overflow-hidden">
        <div className="xs:w-0 sm:w-0 w-1/3">
          <RotatorDisplay />
        </div>
        <div className="m-4 xs:w-full sm:w-full md:w-3/4 lg:w-3/4 xl:w-3/4 2xl:w-3/4 flex justify-center items-center">
          <div className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3">
            {renderActiveStep(activeStep)}
          </div>
        </div>
      </div>
    </>
  );
}

export default SeekerRegistrationIndex;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faCheck } from "@fortawesome/free-solid-svg-icons";
import FadeInFromTop from "../animations/transitions/FadeInFromTop";

// wrapper that contains title and message for equired field
import Tooltip from "../tooltip/tooltip";
function AutoSaveWrapper({ children, issue }) {
  return (
    <>
      {/* <Tooltip text="testing"> */}
      <div className="flex items-center mb-1 text-instant-teams-red-Main">
        {issue ? (
          <>
            <FontAwesomeIcon icon={faAsterisk} className="px-1 " />
            <div className="mx-2 block text-left text-sm">{issue.message}</div>
          </>
        ) : null}
      </div>
      {/* </Tooltip> */}
      <div className="mb-2">{children}</div>
    </>
  );
}

export default AutoSaveWrapper;

import { useUser } from "../../../providers/database";

import Affiliations from "../../user/details/parts/Affiliations";

import { useApplication } from "../../../data/applications";

import { useNavigate } from "react-router-dom";
import { useJobPositionDetails } from "../../../data/positions";
import { DisplayDateOther } from "../../../util/timestamps/timestamp";
import { useFindOfferStatus } from "../../../data/status";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";

function AdminOfferItem({ offer }: { offer: any }) {
  const navigate = useNavigate();
  const { data: applicant = {}, status: applicantQueryStatus } = useUser(
    offer.resumeId,
  );

  const { data: jobPositionData } = useJobPositionDetails(
    offer?.jobId,
    offer?.positionId,
  );

  const { data: statusData = {} } = useFindOfferStatus(offer.status);
  const { data: applicationData } = useApplication(offer.applicationId);

  const colorJobListingVariants: any = {
    pending: "bg-instant-teams-tags-BLUE",
    error: "bg-instant-teams-tags-ROSE",
    hired: "bg-instant-teams-tags-GREEN",
    preparation: "bg-instant-teams-tags-TEAL",
    rejected: "bg-instant-teams-tags-ORANGE",
    cancelled: "bg-instant-teams-gray-D1",
  };

  return (
    <div
      key={offer?.objectID || applicationData?.id}
      className={
        TailwindHoverEnlarge(103) +
        "shadow-lg rounded-xl bg-white h-full mt-4 cursor-pointer"
      }
      onClick={() => navigate(`/offers/${offer?.objectID}/details`)}
    >
      {applicantQueryStatus === "success" && (
        <div className="w-full grid grid-cols-5 gap-2 h-full">
          <div className="w-full flex mb-2 p-2 col-span-2 bg-instant-teams-teal-Main text-white rounded-l-lg h-full">
            <img
              src={
                applicant?.photoURL
                  ? applicant?.photoURL
                  : "/images/default_user.png"
              }
              className={`rounded-full h-12 w-12 border-solid border-4 my-auto ${
                applicant?.status !== "active"
                  ? " border-instant-teams-red-Main"
                  : "border-instant-teams-green-D1"
              }`}
              alt={`${applicant?.name?.toUpperCase()}`}
            />
            <div>
              <p className="pl-4 text-sm pt-2">{`${applicant?.name?.toUpperCase()}`}</p>
              <p className="pl-4 text-xs pt-1">{applicant?.email}</p>
              <p className="pl-4 text-xs pt-1">
                {applicant?.address?.standardizedAddress}
              </p>
              <div className="pl-4 text-xs pt-1">
                <PhoneNumber number={applicant?.phone || ""} />
              </div>
              <div className="pl-4 text-xs pt-1">
                <Affiliations
                  userId={offer?.resumeId}
                  background={"darkBackground"}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-full col-span-1 pt-2 pl-4 pr-4 mt-2 text-sm pb-2">
            <div className="text-sm">
              Position: {jobPositionData?.positionName}
            </div>
            <div className="text-sm">
              {offer.payType === "HRLY" && (
                <span>
                  Hourly
                  {" - $" +
                    offer.payRate
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </span>
              )}
              {offer.payType === "SAL" && (
                <span>
                  Salary
                  {" - $" +
                    offer.payRate
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </span>
              )}
            </div>
            {offer && offer?.isCommission && (
              <div className="text-sm">
                Commission: {offer.commissionDetails}
              </div>
            )}
            <div className="text-sm">
              Start Date: {DisplayDateOther(offer?.startDate)}
            </div>
          </div>
          <div className="w-full h-full col-span-1 pt-2 pl-4 pr-4 mt-2 text-sm pb-2 text-center">
            <Tooltip text={statusData?.description || "Current Status"}>
              <div
                className={`items-center w-full mt-2 rounded-md text-white mx-auto p-1 mb-3 ${
                  colorJobListingVariants[offer?.status] ||
                  "bg-instant-teams-purple-Main"
                }`}
              >
                <p className="text-center">{statusData?.label}</p>
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminOfferItem;

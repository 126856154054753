import { useEffect, useState } from "react";
import { isString } from "lodash";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Handle, Track, TooltipRail } from "./range-slider/RangeSlider";
import AutoSaveWysiwyg from "./auto-save-wysiwyg";
import { updateAutoSave } from "../../../data/utility";
import { deleteField } from "firebase/firestore";

function AutoSavePayTypeWysiwyg({
  options,
  payTypeInitialValue,
  collection,
  recId,
  name,
}) {
  // Create an array of payType objects from any initial values found on record
  // corresponds to checkbox options
  const [paySelections, setPaySelections] = useState(
    Object.keys(payTypeInitialValue) ?? [],
  );

  // exclude Commission option from rate slider (commission "value" is a string, not for use with slider)
  // sort non destructively to ensure sliders render in the same order each render
  const rateSliders = [
    ...paySelections.filter((code) => code !== "COMM"),
  ].sort();

  // commission description
  const [commissionDescription, setCommissionDescription] = useState(
    isString(payTypeInitialValue["COMM"]) ? payTypeInitialValue["COMM"] : "",
  );

  // set paySelections on changes to checkbox
  const handleChange = (e) => {
    const { value: code, checked } = e.target;

    if (checked) {
      setPaySelections((prevSelections) => [...prevSelections, code]);

      updateAutoSave(collection, recId, {
        [`${name}.${code}`]: [],
      });
    } else {
      const omitUnchecked = paySelections.filter((selectedCode) => {
        return selectedCode !== code;
      });
      setPaySelections(omitUnchecked);

      updateAutoSave(collection, recId, {
        [`${name}.${code}`]: deleteField(),
      });
    }
  };

  const handleSlideEnd = (code, range) => {
    updateAutoSave(collection, recId, { [`${name}.${code}`]: range });
  };

  return (
    <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
      {/* Pay Options */}
      {options.map((item) => (
        <div key={item.id} className="flex items-start ">
          <>
            <input
              className="h-5 w-5 shadow text-instant-teams-teal-Main leading-8 bg-white rounded border-instant-teams-blue-Main outline-none focus:border-instant-teams-blue-Main focus:ring-1"
              id={item.id}
              name={"payTypeGroup"}
              type="checkbox"
              value={item.code}
              onChange={handleChange}
              checked={!!paySelections.find((pay) => pay === item.code)}
            />
            <label
              className="mb-1 px-2 text-sm  font-semibold text-instant-teams-blue-Main"
              htmlFor={item.id}
            >
              {item.label}
            </label>
          </>
        </div>
      ))}

      <div id={name} className="mb-4 col-span-3">
        {rateSliders.map((selection) => {
          const label = options.find((opt) => opt.code === selection);

          const payType = payTypeInitialValue[selection];

          return (
            <div key={selection} className="h-16 my-4">
              <div className="mb-10 uppercase block text-left font-semibold text-instant-teams-blue-Main">
                {label?.label}
              </div>
              <div className="col-span-2 pr-8 pl-4">
                <RateSlider
                  initialValues={payType}
                  minMaxRange={selection === "SAL" ? [10000, 80000] : [10, 85]}
                  code={selection}
                  handleSlideEnd={handleSlideEnd}
                  step={selection === "SAL" ? 500 : 0.5}
                />
              </div>
            </div>
          );
        })}
      </div>

      {paySelections.find((item) => item === "COMM") && (
        <div id="information.payType.COMM.value" className="col-span-3">
          <AutoSaveWysiwyg
            value={commissionDescription}
            rows={5}
            collection={collection}
            recId={recId}
            required={true}
            name={"information.payType.COMM"}
            display="Commission Details"
            placeholder="Enter commission details."
            directions=""
            readOnly={false}
            editorStyle={{ height: "240px" }}
          />
        </div>
      )}
    </div>
  );
}

function RateSlider({
  initialValues,
  minMaxRange,
  code,
  handleSlideEnd,
  step,
}) {
  function validateInitialValues(initialValues) {
    // check if initialValues from db contain valid numbers, if so then
    // initialize the "values" array with those numbers
    // we dont want to override db if data is valid
    if (
      Array.isArray(initialValues) &&
      initialValues.length > 0 &&
      !initialValues.some(isNaN)
    ) {
      return [...initialValues];
    } else {
      return minMaxRange;
    }
  }

  function formatTooltip(rate) {
    return Number(rate).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const sliderStyle = {
    position: "relative",
    width: "100%",
  };

  return (
    <>
      <div>
        <Slider
          mode={3}
          step={step}
          domain={minMaxRange}
          rootStyle={sliderStyle}
          onSlideEnd={(range) => handleSlideEnd(code, range)}
          values={validateInitialValues(initialValues)}
        >
          <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => {
              return (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={minMaxRange}
                      isActive={handle.id === activeHandleID}
                      getHandleProps={getHandleProps}
                      formatTooltip={formatTooltip}
                    />
                  ))}
                </div>
              );
            }}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    </>
  );
}

export default AutoSavePayTypeWysiwyg;

import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";

import HeaderLogItems from "./HeaderLogItems";
import { useUser } from "../../../providers/database";
import PhoneNumber from "../../custom-ui/input-fields/phoneNumber";
import SendEmail from "../../custom-ui/emails/send-email";
import { DisplayDate } from "../../../util/timestamps/timestamp";
import { useRole } from "../../../hooks/useRole";
import { Type } from "../../../data/dictionary/type";
import { getMemberSince } from "../../../data/login";

import AutoSaveSelectGeneric from "../../custom-ui/input-fields/auto-save-select-generic";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import { useLookup } from "../../../data/lookup";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { useAuth } from "../../../providers/auth";
import Avatar from "../../user/settings/Avatar";
import useModal from "../../../hooks/useModal";
function UserDetailsSidebarHeader() {
  const { users } = collectionNames;
  const { userClaims } = useAuth();
  const params = useParams();
  const [id, setId] = useState(params.userId ?? "");
  const [type, setType] = useState("");
  const [member, setMember] = useState("");
  const { data: userData, status: userStatus, error: userError } = useUser(id);

  const { data: employerRolesLookup } = useLookup("employerRole");
  const { data: seekerRolesLookup } = useLookup("seekerRole");
  useEffect(() => {
    // this is setting the role and type of the user we are viewing.
    setType(userData?.claims?.type || "");
  }, [userData]);
  const { isShowing: showAvatarEditModal, toggle: toggleAvatarEditModal } =
    useModal();

  const getRoleLabel = useRole();
  const userRoleLabel = getRoleLabel(userData?.claims?.role, true);

  useEffect(() => {
    const getMember = async (id: string) => {
      const memberSince = await getMemberSince(id);
      setMember(memberSince);
    };
    getMember(id);
  }, [id, member]);
  useEffect(() => {
    if (userError) {
      console.log(userError);
    }
  }, [userError]);

  useEffect(() => {
    setId(params.userId ?? "");
  }, [params.userId]);

  return (
    <div className="">
      {userClaims?.claims?.type === "A" ? (
        <HeaderLogItems
          collection="Users"
          recId={id}
          viewableItems={["audit", "access", "notes"]}
        />
      ) : null}
      <div className="items-center pt-4 pr-3 flex flex-col">
        <img
          src={userData?.photoURL || "/images/default_user.png"}
          alt={userData?.preferredName}
          className="mx-auto w-24 h-24 rounded-xl "
          onClick={toggleAvatarEditModal}
        />
      </div>
      {showAvatarEditModal && userClaims?.claims?.type === "A" ? (
        <Avatar toggleModal={toggleAvatarEditModal} />
      ) : null}
      <div className="items-center py-4 pr-3">
        <div className="text-center text-xl font-bold">
          {userData?.preferredName}
        </div>
        <div className="text-center text-base font-bold pb-1">
          {userData?.phone && <PhoneNumber number={userData?.phone} />}
          <SendEmail email={userData?.email} />
        </div>
        <div className="text-center mr-3 text-base items-center">
          Last login:{" "}
          {userData?.lastLogIn
            ? DisplayDate(userData.lastLogIn)
            : "No Log In History"}
        </div>
        <div className="text-center mr-3 text-base">
          User Type: {Type[type]}
        </div>
        <div className="text-center mr-3 text-base">
          User Role: {userRoleLabel}
        </div>
        <div className="flex flex-col items-center">
          <div className="text-center grid grid-cols-2 gap-5 items-center text-base mb-4">
            {userData?.claims?.milspouse && userData?.claims?.type !== "A" ? (
              <Tooltip text="Member of 12 Million Plus">
                <img
                  src="/images/12-mil-plus/12-mil-logo.png"
                  alt="milspouse logo"
                  className="w-8 h-8 mt-1 items-center text-center bg-white rounded-full"
                />
              </Tooltip>
            ) : (
              // empty div to keep the icons in place
              <></>
            )}
            {userData?.claims?.milVerified && userData?.claims?.type !== "A" ? (
              <Tooltip text="Military Association Verified">
                <img
                  src="/images/mil-verified/military-logo.png"
                  alt="mil verified logo"
                  className="w-8 h-8 mt-1 items-center bg-white rounded-full"
                />
              </Tooltip>
            ) : (
              // empty div to keep the icons in place
              <></>
            )}
          </div>
        </div>
        {userData && (
          <div className="pl-3">
            <AutoSaveStatusSelector
              status={userData?.status}
              collection={users}
              recId={userData?.id}
              required={false}
              name="status"
              display="Status"
              placeholder=""
              directions=""
            />
          </div>
        )}
        {type && type !== "A" ? (
          <div className="pl-3">
            <AutoSaveSelectGeneric
              value={userData?.claims?.role}
              options={type === "E" ? employerRolesLookup : seekerRolesLookup}
              collection={users}
              recId={userData?.id}
              required={false}
              name="claims.role"
              display="Role"
              placeholder="Select a Role"
              directions=""
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default UserDetailsSidebarHeader;

import { useEffect, useState } from "react";
import { updateAutoSave, logActivity } from "../../../data/utility";
import { useAuth } from "../../../providers/auth";

function AutoSaveToggle({
  value,
  collection,
  id,
  name,
  on,
  off,
  disabled = false,
}) {
  const auth = useAuth();
  const [isOn, setIsOn] = useState(value);

  const handleClick = () => {
    setIsOn(!isOn);
  };

  useEffect(() => {
    if (collection && id && name) {
      updateAutoSave(collection, id, { [name]: isOn });
      logActivity(collection, id, name, value, isOn, auth?.user?.uid, "Edited");
    }
  }, [isOn]);

  return (
    <div id={name} className="flex items-center">
      <button
        type="button"
        disabled={disabled}
        className={`cursor-pointer ${
          isOn ? "bg-instant-teams-green-Main" : "bg-gray-300"
        } relative inline-flex items-center h-6 rounded-full w-11`}
        onClick={handleClick}
      >
        <span
          className={`${
            isOn ? "translate-x-6" : "translate-x-1"
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </button>
      <span className="ml-3">{isOn ? on : off}</span>
    </div>
  );
}

export default AutoSaveToggle;

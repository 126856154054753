import { useState, useEffect } from 'react';
import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import EditCustom from './EditCustom';
import { useEmployerInterviewQuestions } from '../../../data/employer';
import SortableList from './sortable-list';
import { collectionNames, subCollectionNames } from '../../../data/dictionary/collectionNames';

function EInterviewEmployerCustom({employerId}:{employerId: string}) {
  const [trigger, setTrigger] = useState(true);
  const [questionId, setQuestionId] = useState(null);
  const [question, setQuestion] = useState(null);
  const { data: questions } = useEmployerInterviewQuestions(employerId);

  return (
    <div className='w-full'>
      <div className='w-full'>
        <GreenUnderlineHeading title="eInterview Custom Questions" />
      </div>
      <div className='grid grid-cols-3 gap-2'>
        <div className="col-span-1">
        <GreenUnderlineHeading title={"Questions"}/>
          <SortableList 
            list={questions}
            updateField={"sortOrder"}
            displayField={"displayName"}
            collection={collectionNames.employers}
            parentId={employerId}
            subcollection={subCollectionNames.interviewQuestions}
            callback={setQuestionId}
            returnItem={setQuestion}
          />
        </div>
        <div className="col-span-2 ml-10">
          { questionId !== null &&
            <EditCustom key={questionId} type={"employer"} typeId={employerId} id={questionId} readOnly={false} /> 
          }
        </div>
      </div>     
    </div>
  );
}

export default EInterviewEmployerCustom;

import ButtonWithLoading from "../../../components/custom-ui/button/ButtonWithLoading";
import GreenUnderlineHeading from "../../../components/custom-ui/headings/heading";
import { createMarketingListing } from "../../../data/marketing";
import { useAllMarketings } from "../../../data/marketing";
import { Timestamp } from "firebase/firestore";
import useModal from "../../../hooks/useModal";
import { useState } from "react";
import DialogWrapper from "../../../components/custom-ui/dialog/Dialog";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { DisplayDate } from "../../../util/timestamps/timestamp";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
import CampaignCardDetails from "../parts/campaign-card-details";
import Tooltip from "../../../components/custom-ui/tooltip/tooltip";
function MarketingDashboard() {
  const { isShowing, toggle } = useModal();
  const [pending, setPending] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isEmpty(name)) {
      setPending(true);
      const defaultData = {
        campaignName: name,
        body: "<p></p>",
        active: false,
        button: {
          bgColor: "#19405E",
          textColor: "#FFF",
          link: "",
          text: "Open",
          showButton: true,
        },
        schedule: {
          dateRange: {
            endDate: null,
            startDate: null,
          },
          showOnLogIn: false,
          showCount: "1",
        },
        title: "Hey There Career Seeker",
        createdAt: Timestamp.now(),
      };
      await createMarketingListing(defaultData).then((res) => {
        console.log(res);
        setPending(false);
        toggle();
        navigate(`/dashboard/marketing/${res.id}`);
      });
    }
  };
  const { data: marketingData } = useAllMarketings();

  return (
    <>
      <GreenUnderlineHeading title="Marketing Interface" />

      <div className="w-64 my-5">
        <ButtonWithLoading value="Add" onClick={toggle} />
      </div>
      <div className="grid grid-cols-2 gap-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 ">
        {marketingData && marketingData?.length > 0 ? (
          <>
            {marketingData?.map((data: any) => {
              return (
                <Link to={`${data.id}`}>
                  <div
                    key={data.id}
                    className={
                      TailwindHoverEnlarge(105) +
                      `bg-white shadow-input-field px-4 pt-4 pb-10 rounded-lg`
                    }
                  >
                    <Tooltip
                      text={
                        data?.active ? "Active Campaign" : "Inactive Campaign"
                      }
                    >
                      <div
                        className={
                          data?.active
                            ? "flex justify-center text-instant-teams-blue-Main font-semibold text-xl"
                            : "flex justify-center text-instant-teams-gray-Main font-semibold text-xl"
                        }
                      >
                        {data?.campaignName}
                      </div>
                    </Tooltip>
                    <div className="flex justify-center">
                      {DisplayDate(data?.schedule?.dateRange?.startDate)} -{" "}
                      {DisplayDate(data?.schedule?.dateRange?.endDate)}
                    </div>
                    <div className="mt-5 shadow-input-field py-5 rounded-xl">
                      <CampaignCardDetails data={data} />
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        ) : (
          <>
            <div>No marketing ads made yet.</div>
          </>
        )}
      </div>
      {isShowing && (
        <DialogWrapper title="Create New Campaign" onClose={toggle}>
          <form onSubmit={onSubmit}>
            <input
              className="w-full my-6"
              name="campaignName"
              type="text"
              placeholder="The Marvelous Job Odyssey"
              disabled={pending}
              onChange={(event) => setName(event.target.value)}
            />
            <div className="w-full my-2 flex justify-center">
              <button
                className="py-2 px-16 rounded-lg bg-instant-teams-blue-Main text-white hover:bg-instant-teams-blue-D1"
                type="submit"
              >
                {pending ? "..." : "Create New Campaign"}
              </button>
            </div>
          </form>
        </DialogWrapper>
      )}
    </>
  );
}

export default MarketingDashboard;

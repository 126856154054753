import React, { memo, forwardRef } from "react";
import useLocalTime from "../../../hooks/useLocalTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faShield,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useLookupCode } from "../../../data/lookup";
import { useEmployer } from "../../../data/employer";
import { Link } from "react-router-dom";
import { useUserStatus } from "../../../data/user";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { TailwindHoverEnlarge } from "../../../util/tailwind/tailwind-styles";
const UserManagementLineComponent = forwardRef((row: any, ref: any) => {
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  const formattedDate = useLocalTime(row.row?.hit?.lastLogIn, options);
  const { data: userData = {} } = useLookupCode(row.row?.hit?.claims?.role);
  const { data: employer = {} } = useEmployer(row.row?.hit?.claims?.employerId);
  const { data: userStatusData = {} } = useUserStatus(row.row?.hit?.status);

  return (
    <Link to={`/users/${row?.row.hit?.objectID}/user-info`}>
      <div
        ref={ref}
        className={
          TailwindHoverEnlarge(105) +
          "items-center w-full rounded-lg bg-white shadow-lg"
        }
      >
        <div className="items-center bg-instant-teams-teal-Main p-5 rounded-t-lg w-full h-44">
          <div className="w-full flex mb-2 items-center">
            <img
              src={
                row.row?.hit?.photoURL
                  ? row.row?.hit?.photoURL
                  : "/images/default_user.png"
              }
              className={`rounded-full h-12 w-12 border-4 mx-auto ${
                row.row?.hit?.disabled
                  ? " border-instant-teams-red-Main"
                  : "border-instant-teams-green-D1"
              }`}
              alt={`${row.row?.hit?.name?.toUpperCase()}`}
            />
          </div>
          <div className="w-full mb-2 text-white pl-2 text-center">
            <p>{`${row.row?.hit?.name?.toUpperCase()}`}</p>
            <p className=" text-sm">{row.row?.hit?.email}</p>
            <p className=" text-sm">{userStatusData?.label || "unknown"}</p>
          </div>
        </div>
        <div className="w-full text-white overflow-hidden whitespace-no-wrap items-center mt-4 pt-2 pr-4 pl-4">
          <section
            className={`float-left h-8 text-white rounded-md w-full pt-2 text-center text-sm  ${
              row.row?.hit?.claims?.role === "COMM"
                ? "bg-instant-teams-orange-L2"
                : row.row?.hit?.claims?.type === "S"
                ? "bg-instant-teams-blue-L2"
                : row.row?.hit?.claims?.type === "A"
                ? " bg-instant-teams-purple-Main"
                : row.row?.hit?.claims?.type === "E"
                ? " bg-instant-teams-green-L1"
                : " bg-instant-teams-gray-Main"
            }`}
          >
            <p className=" px-2 truncate text-ellipsis">
              {userData[0]?.label.toUpperCase()}
            </p>
          </section>
        </div>
        <div className="w-full text-white whitespace-no-wrap items-center pt-2">
          <section className="h-10 text-black rounded-md w-full mt-2 pt-2 text-center items-center text-sm flex justify-around">
            {row?.row?.hit?.claims?.type === "E" ? (
              <p className="pr-5">{employer?.displayName?.toUpperCase()}</p>
            ) : (
              <></>
            )}
            {row.row?.hit?.claims?.milspouse &&
              row?.row?.hit?.claims?.type !== "E" && (
                <Tooltip text="Member of 12 million plus">
                  <div className=" flex items-center justify-end">
                    <img
                      src="/images/12-mil-plus/12-mil-logo.png"
                      alt=""
                      className="w-8 h-8 mx-auto bg-white rounded-full "
                    />
                  </div>
                </Tooltip>
              )}
            {row?.row?.hit?.claims?.milVerified &&
              row?.row?.hit?.claims?.type !== "E" && (
                <Tooltip text="Military Association Verified">
                  <div className="flex items-center justify-start  ">
                    <img
                      src="/images/mil-verified/military-logo.png"
                      alt=""
                      className="w-8 h-8 mx-auto bg-white rounded-full "
                    />
                  </div>
                </Tooltip>
              )}
          </section>
        </div>
        <div className="flex items-center p-5 mt-4 rounded-b-lg w-full">
          <div className="w-full min-w-full">
            <div className="flex items-center justify-around">
              <Tooltip text="Verified Email?">
                <div className="space-x-1">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="h-5 w-5"
                    swapOpacity
                  />
                  {row?.row?.hit?.emailVerified ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-5 text-instant-teams-green-D1"
                      swapOpacity
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="h-5 w-5 text-instant-teams-red-Main"
                      swapOpacity
                    />
                  )}
                </div>
              </Tooltip>

              <Tooltip text="Phone Number Verified?">
                <div className="space-x-1 ">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="h-5 w-5 mx-auto"
                    swapOpacity
                  />

                  {row.row?.hit?.phone !== null ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-5 text-instant-teams-green-D1"
                      swapOpacity
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="h-5 w-5 text-instant-teams-red-Main"
                      swapOpacity
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip text="MFA Active?">
                <div className="space-x-1">
                  <FontAwesomeIcon
                    icon={faShield}
                    className="h-5 w-5 mx-auto"
                    swapOpacity
                  />
                  {row.row?.hit?.mfa ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="h-5 w-5 text-instant-teams-green-D1"
                      swapOpacity
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="h-5 w-5 text-instant-teams-red-Main"
                      swapOpacity
                    />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-full min-w-full">
          <p className=" pb-2 w-full text-instant-teams-blue-Main font-bold mb-4 text-center">
            LAST LOGIN: {formattedDate ? formattedDate : "Hasn't Logged In"}
          </p>
        </div>
      </div>
    </Link>
  );
});

export default memo(UserManagementLineComponent);

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/auth";
import { isEmpty } from "lodash";
import { createJobDescription } from "../../../data/jobDescriptions";
import { useAllActiveEmployers } from "../../../data/employer";

function CreateJobBasicForm({
  employerId,
  onDone = () => {},
  toggle,
  handleFormAlert,
}: {
  employerId?: string;
  onDone?: Function;
  toggle: Function;
  handleFormAlert: Function;
}) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [empId, setEmpId] = useState("all");
  const { data: employers } = useAllActiveEmployers(empId);
  const [pending, setPending] = useState(false);
  const [employerValue, setEmployerValue] = useState(employerId);
  const [title, setTitle] = useState("");

  const onSubmit = async () => {
    if (!isEmpty(employerValue) && !isEmpty(title)) {
      setPending(true);
      const jobDescription = {
        employer: employerValue,
        title: title,
        createdBy: user.id,
        status: "tac_review",
      };
      console.log(jobDescription);
      await createJobDescription(jobDescription)
        .then((data) => {
          console.log("DATA JD Create: ", data, handleFormAlert);

          handleFormAlert("success");

          setTimeout(() => {
            onDone();
            toggle();
            navigate(
              !employerId
                ? `/jobs/${data}/${employerValue}/description`
                : `/jobs/new/${data}`,
            );
          }, 4000);
        })
        .catch(() => {
          handleFormAlert("error");
          setPending(false);
        });

      return;
    }
  };

  return (
    <>
      <div>
        {!employerId ? (
          <div className="mb-2">
            <select
              name="employer"
              className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
              onBlur={(event) => setEmployerValue(event.target.value)}
              onChange={(event) => setEmployerValue(event.target.value)}
            >
              <option value=""></option>
              {employers?.map((employer: any) => (
                <option key={employer.id} value={employer.id}>
                  {employer.displayName}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="mb-2">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="title"
            type="text"
            placeholder="Job Title"
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <button
          className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
          type="submit"
          onClick={onSubmit}
          disabled={pending}
        >
          {pending ? "..." : "Create New Job"}
        </button>
      </div>
    </>
  );
}

export default CreateJobBasicForm;

import NewUserChart from "../../../charts/newUsers";
import UsersByType from "../../../charts/UsersByType";
import GlobalCounts from "../dataSets/counts";
import EnrollmentSyncFailures from "../dataSets/enrollmentSyncFailures";
import { useAllActiveAlerts } from "../../../data/alerts";
import useModal from "../../../hooks/useModal";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import AlertDisplay from "../../alerts/AlertDisplay";

function SadminDashboard(props) {
  const { data: alerts } = useAllActiveAlerts();
  const { isShowing: isShowingAlerts, toggle: toggleAlerts } = useModal();
  let hideAlerts = sessionStorage.getItem("hideAlerts");

  const handleAlertClose = () => {
    sessionStorage.setItem("hideAlerts", "true");
    toggleAlerts();
  };

  return (
    <>
      <div className="grid grid-rows-2 grid-cols-4 gap-10">
        <div className="w-full col-span-2">
          <NewUserChart />
        </div>
        <div className="w-full">
          <UsersByType />
        </div>
        <div className="w-full">
          <GlobalCounts />
        </div>
        <div className="w-full col-span-2">
          <EnrollmentSyncFailures />
        </div>
        {!isShowingAlerts && !hideAlerts && alerts?.length > 0 && (
          <DialogWrapper
            title="System Alerts"
            onClose={handleAlertClose}
            size="w-1/3"
          >
            {/* @ts-ignore */}
            <AlertDisplay alerts={alerts} />
          </DialogWrapper>
        )}
      </div>
    </>
  );
}

export default SadminDashboard;

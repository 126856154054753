import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useState, useEffect, useRef } from "react";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import useModal from "../../hooks/useModal";
import { useAuth } from "../../providers/auth";
import VideoRecorder from "react-video-recorder";
import FormAlert from "../custom-ui/form-alert/formAlert";
import { uploadBytesWithRetry } from "../../providers/firebaseStorage";
import { collectionNames } from "../../data/dictionary/collectionNames";
import {
  addApplicationSync,
  setAnswer,
  setReAttempts,
} from "../../data/applications";
import QuestionAudioRecorder from "../interviews/QuestionAudioRecorder";
import { useParams } from "react-router-dom";
import ProgressCircle from "../custom-ui/animations/progress-circle/ProgressCircle";

function SeekerEInterviewItem({
  recording,
  index,
}: {
  recording: any;
  index: any;
}) {
  const { applicationId = "" } = useParams();
  const { user } = useAuth();
  const [timeLeft, setTimeLeft] = useState(recording.prepTime);
  const [answering, setAnswering] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [prepTimeClass, setPrepTimeClass] = useState("mt-5");
  const [answerText, setAnswerText] = useState("");

  const [uploadProgress, setuploadProgress] = useState(0);
  const [uploadIsInProgress, setUploadIsInProgress] = useState(false);

  const [formAlert, setFormAlert] = useState(null);
  const clearFormAlert = () => setFormAlert(null);

  const { isShowing: isRecordingShowing, toggle: toggleRecording } = useModal();
  const { isShowing: showAudioRecorder, toggle: toggleAudioRecorder } =
    useModal();

  const [videoUrl, setVideoUrl] = useState("");

  const handleClickedInterview = useCallback(
    (id: string) => {
      toggleRecording();
    },
    [toggleRecording],
  );

  const answer = async (url: any) => {
    setVideoUrl(url);
    setPrepTimeClass("mt-5");
    setTimeLeft(recording.prepTime);
    setRecordingStarted(false);
    setAnswering(true);
    await setAnswer(applicationId, recording.id, url);
    await addApplicationSync(applicationId);
    setAnswering(false);
    toggleRecording();
  };

  const sumbitTextAnswer = async () => {
    setAnswering(true);
    await setAnswer(applicationId, recording.id, answerText);
    await addApplicationSync(applicationId);
    setAnswering(false);
    toggleRecording();
  };

  const redo = async () => {
    setTimeLeft(recording.prepTime);
    setPrepTimeClass("mt-5");
    setVideoUrl("");
    await setAnswer(applicationId, recording.id, null);
    await setReAttempts(applicationId, recording.id, recording.reAttempts - 1);
  };

  const uploadAudio = async (audioURL: any) => {
    setAnswering(true);
    setRecordingStarted(false);
    setPrepTimeClass("mt-5");
    setTimeLeft(recording.prepTime);
    await setAnswer(applicationId, recording.id, audioURL);
    await addApplicationSync(applicationId);
    setAnswering(false);
    toggleAudioRecorder();
    toggleRecording();
  };
  // controls prep timer
  useEffect(() => {
    let timeoutId: any;

    if (
      isRecordingShowing &&
      !recordingStarted &&
      !recording.answer &&
      recording.type !== "ESS"
    ) {
      if (timeLeft > 0) {
        timeoutId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      }
    }

    if (recordingStarted) {
      clearTimeout(timeoutId);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeLeft, isRecordingShowing, recordingStarted, recording]);

  // get the seekers attention they have to start the recording
  // at zero sec left, submit answer.
  useEffect(() => {
    if (timeLeft > 10 && timeLeft <= 20) {
      setPrepTimeClass("mt-5 border-4 border-red-500");
    }
    if (timeLeft > 0 && timeLeft <= 10) {
      setPrepTimeClass("mt-5 border-4 border-red-800 animate-pulse");
    }
    if (timeLeft === 0) {
      const emptyAnswer = "timedOut";
      answer(emptyAnswer);
    }
  }, [timeLeft]);

  const startAudioRecorder = () => {
    setRecordingStarted(true);
    setPrepTimeClass("mt-5");
    toggleAudioRecorder();
    setTimeLeft(recording.prepTime);
  };

  return (
    <div
      key={recording.id}
      className={`${TailwindHoverEnlarge(
        103,
      )} + shadow-input-field rounded-lg h-28 lg:h-40 w-full mb-3 pl-5 text-instant-teams-blue-Main`}
    >
      <div className="pt-5 font-semibold">
        {recording?.displayName || index + 1}
      </div>
      <div className="col-span-8 grid-cols-8 grid grid-flow-col lg:grid-flow-row my-auto py-1 ">
        <div className="col-span-2  ">
          <span className="font-semibold pr-2">Re-attempts:</span>
          {recording?.reAttempts}
        </div>
        <div className="col-span-2   ">
          <span className="font-semibold pr-2">Prep Time:</span>
          {recording?.prepTime} sec
        </div>
        <div className="col-span-2  ">
          <span className="font-semibold pr-2">Max Length:</span>
          {Math.floor(recording?.maxTime / 60)} mins
        </div>
        <div className=" col-span-2   mx-auto ">
          {!!recording?.answer ? (
            <button className="  " onClick={toggleRecording}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className=" text-instant-teams-green-Main"
                size="2x"
              />
            </button>
          ) : uploadIsInProgress ? (
            <ProgressCircle
              containerClassName=""
              r={15}
              strokeWidth={6}
              prog={uploadProgress}
            />
          ) : (
            <button
              className=""
              onClick={() => {
                handleClickedInterview(recording?.id);
              }}
            >
              <FontAwesomeIcon icon={faPlayCircle} size="2x" />
            </button>
          )}
        </div>
      </div>
      {isRecordingShowing && recording && (
        <DialogWrapper
          title={recording.displayName}
          size="w-1/3 mt-36"
          onClose={() => {
            toggleRecording();
            clearFormAlert();
          }}
        >
          <>
            <div
              dangerouslySetInnerHTML={sanitizeMarkup(recording?.question)}
              className=" px-4 h-full overflow-auto "
            ></div>
            {recording?.promptType === "VID" ? (
              <div className="w-3/4 mx-auto my-2">
                <video controls className="mx-auto h-80">
                  <source src={recording?.videoURL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : null}
            {recording?.promptType === "VOICE" ? (
              <div className="mx-auto w-1/2 my-2">
                <audio className="" src={recording?.audioURL} controls />
              </div>
            ) : null}
            {!recording.answer && recording.type !== "ESS" ? (
              <div className={`px-4 my-2  text-sm ${prepTimeClass}`}>
                Please begin your response recording in {timeLeft} seconds.
              </div>
            ) : null}
            {recording.reAttempts && recording.reAttempts > 0 ? (
              <p className="text-instant-teams-blue-Main px-4 text-sm my-2">
                Redo ({recording.reAttempts} attempt)
              </p>
            ) : null}
            {recording?.answer !== null &&
              recording?.answer !== undefined &&
              recording?.type === "VID" && (
                <video controls className="w-3/4 mx-auto">
                  <source src={recording?.answer} type="video/mp4" />
                </video>
              )}
            {recording?.type === "VOICE" && !recording.answer && (
              <div className=" px-4 w-48 mx-auto mt-2">
                <div className="w-full">
                  <button
                    className={`${prepTimeClass} py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none ml-auto`}
                    onClick={() => startAudioRecorder()}
                  >
                    Record Audio
                  </button>
                </div>
              </div>
            )}{" "}
            {recording?.type === "VOICE" && recording?.answer ? (
              <>
                <p className="my-2 text-instant-teams-blue-Main px-4">
                  Your Response
                </p>
                <div className="mx-auto w-1/2 my-2">
                  <audio className="" src={recording?.answer} controls />
                </div>
              </>
            ) : null}
            {recording?.type === "VID" && (
              <>
                {formAlert && (
                  <div className="my-4">
                    <FormAlert alert={formAlert} />
                  </div>
                )}
                {videoUrl === "" && !recording.answer ? (
                  <div className={`${prepTimeClass} w-3/4 mx-auto`}>
                    <VideoRecorder
                      width={640}
                      height={480}
                      timeLimit={recording.maxTime * 1000}
                      onStartRecording={() => {
                        setRecordingStarted(true);
                        setPrepTimeClass("mt-5");
                      }}
                      onRecordingComplete={async (videoBlob: any) => {
                        const filename = `${user.uid}_${applicationId}_${recording.id}.mp4`;
                        try {
                          setUploadIsInProgress(true);
                          const result = await uploadBytesWithRetry(
                            videoBlob,
                            "videoAnswers",
                            filename,
                            setuploadProgress,
                          );
                          await answer(result?.downloadURL);

                          setRecordingStarted(false);
                        } catch (error) {
                          setFormAlert({
                            type: "error",
                            message: `Looks like there was a problem saving your video. Please try again.`,
                          });
                          setRecordingStarted(false);
                        } finally {
                          setUploadIsInProgress(false);
                          setuploadProgress(0);
                        }
                      }}
                      isFlipped
                      isOnInitially
                      replayVideoAutoplayAndLoopOff
                      constraints={{
                        audio: true,
                        video: true,
                      }}
                    />
                  </div>
                ) : null}
              </>
            )}
            {(recording?.type === "ESS" && recording.answer === "") ||
            (recording?.type === "ESS" && recording.answer == null) ? (
              <div className="flex px-4 flex-col">
                <label className="my-2 text-instant-teams-blue-Main">
                  Answer
                </label>
                <textarea
                  className=" resize"
                  rows={5}
                  cols={32}
                  placeholder="Answer"
                  onChange={(e) => setAnswerText(e.target.value)}
                  value={answerText}
                />
                <div className="w-1/2 mx-auto my-5">
                  <button
                    onClick={sumbitTextAnswer}
                    disabled={answerText?.length < 3 || answering}
                    className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none ml-auto"
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
            {recording?.answer !== null && recording?.type === "ESS" && (
              <div className="w-full my-5 px-4">
                <p className="my-2 text-instant-teams-blue-Main">Answer</p>
                <p>{recording?.answer}</p>
              </div>
            )}
            {recording?.reAttempts &&
            recording.reAttempts > 0 &&
            recording.answer ? (
              <div className="mx-auto w-40 my-2">
                <button
                  className="py-2 w-full bg-instant-teams-blue-Main text-white rounded-sm hover:bg-instant-teams-blue-D1"
                  onClick={redo}
                >
                  Redo
                </button>
              </div>
            ) : null}
          </>
        </DialogWrapper>
      )}
      {showAudioRecorder && (
        <DialogWrapper
          title="Audio Recorder"
          onClose={toggleAudioRecorder}
          size={"w-1/4 h-1/4"}
        >
          <QuestionAudioRecorder
            closeMe={uploadAudio}
            collection={collectionNames.applications}
            returnFile={true}
            id={recording.id}
            applicationId={applicationId}
            folderName={"audioAnswers"}
          />
        </DialogWrapper>
      )}
    </div>
  );
}

export default SeekerEInterviewItem;

function SeekerJobItem({ listing }: { listing: any }) {
  return (
    <>
      <img
        className="w-20 h-20 object-contain my-auto mx-auto col-span-1"
        src={listing?.companyLogo}
        alt={listing?.companyName}
      />
      <div className="my-auto col-span-2 ">
        <div>{listing?.companyName}</div>
        <div className="font-semibold uppercase">{listing?.title}</div>
        <div>{listing?.subTitle}</div>
      </div>
      <div className="my-auto col-span-2">
        <div className="uppercase font-semibold text-sm">
          {listing.positionPayType}
        </div>
        <div className="">
          <div className="uppercase text-sm font-semibold">
            Work Type:
            <span className="pl-2 capitalize font-normal">
              {listing?.classification}
            </span>
          </div>
        </div>
        <div className="">
          <div className="uppercase text-sm font-semibold">
            Work Setting:
            <span className="pl-2 capitalize font-normal">
              {listing?.model}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeekerJobItem;

import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useModal from "../../../hooks/useModal";
import { useEmployer } from "../../../data/employer";
import { useJobDescription } from "../../../data/jobDescriptions";
import GeneralNote from "../../custom-ui/input-fields/modals/GeneralNote";
import AutoSaveStatusSelector from "../../custom-ui/input-fields/statusSelector";
import { collectionNames } from "../../../data/dictionary/collectionNames";
import HeaderLogItems from "./HeaderLogItems";
import { useAuth } from "../../../providers/auth";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import { updateAutoSave } from "../../../data/utility";
import { createNotification } from "../../../data/notifications";
import { toast } from "react-hot-toast";
import ToastAlert from "../../custom-ui/toast-alert/ToastAlert";
import Tooltip from "../../custom-ui/tooltip/tooltip";
import { DisplayDate } from "../../../util/timestamps/timestamp";
function JobsSidebarHeader({}) {
  const { userClaims } = useAuth();
  const params = useParams();
  const [employerId, setEmployerId] = useState(params.employerId);
  const [jobId, setJobId] = useState(params.id);
  const [collection, setCollection] = useState(collectionNames.jobDescriptions);
  const { data: jobData, status: jobStatus } = useJobDescription(
    params.id ?? "",
  );
  const { data: employer, status: employerStatus } = useEmployer(employerId);

  const [logo, setLogo] = useState(employer?.logo);
  const [displayName, setDisplayName] = useState(employer?.displayName);
  const [status, setStatus] = useState(jobData?.status);
  const [title, setTitle] = useState(jobData?.title);
  const [createdAt, setCreatedAt] = useState(jobData?.createdAt);

  useEffect(() => {
    if (jobStatus === "success") {
      setEmployerId(jobData?.employer);
    }
  }, [jobData, jobStatus]);

  const { isShowing: isJobApprovedDialog, toggle: toggleJobApprovedDialog } =
    useModal();

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();

  const handleDone = () => {
    toggleNote();
  };

  useEffect(() => {
    if (jobStatus === "success") {
      setTitle(jobData?.title);
      setStatus(jobData?.status);
      setCreatedAt(jobData?.createdAt);
    }
  }, [jobData, jobStatus]);

  useEffect(() => {
    setDisplayName(employer?.displayName);
    setEmployerId(employer?.id);
    return () => {
      setDisplayName("");
      setEmployerId("");
    };
  }, [employer]);

  useEffect(() => {
    if (logo === undefined || logo === null) {
      setLogo("/images/default-employer.jpg");
    } else {
      setLogo(employer?.logo);
    }
  }, [employer]);

  useEffect(() => {
    setJobId(params.id);
  }, [params.id]);

  const handleApproveJob = async () => {
    await updateAutoSave("JobDescriptions", jobId, {
      status: "approvedByCustomer",
    });
    createNotification({
      group: "tac",
      title: "New Job Approved",
      body: `${displayName} submitted a new job approval.`,
      createdBy: `${userClaims?.claims?.user_id}`,
      isPushNotification: false,
      url: `jobs/${jobId}/${userClaims?.claims?.employerId}/description`,
    })
      .then((res) => {
        console.log("notification created");
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        toast.success("Successfully Published");
        toggleJobApprovedDialog();
      });
  };
  return (
    <div className="">
      {userClaims?.claims?.type === "A" ? (
        <HeaderLogItems
          collection={collection}
          recId={jobId}
          viewableItems={["notes", "audit"]}
        />
      ) : null}
      <div className="items-center pt-4  pb-3">
        <img
          src={logo}
          alt={displayName}
          className="mx-auto  max-w-75 h-auto"
        />
        <div className="text-center text-xl font-bold pb-1 pt-3">
          {displayName}
        </div>
        <div className="text-center text-xl font-bold">{title}</div>
        <p className="text-center text-sm pb-5">{DisplayDate(createdAt)}</p>

        {status &&
          userClaims?.claims?.type === "A" &&
          employer?.status === "active" && (
            <div className="px-2">
              <AutoSaveStatusSelector
                status={status}
                collection="JobDescriptions"
                recId={jobData.id}
                required={false}
                name="status"
                display="Status"
                placeholder=""
                directions=""
              />
            </div>
          )}
        {status === "awaiting" && userClaims?.claims?.type === "E" ? (
          <div className="flex items-center justify-center px-2 ">
            <button
              onClick={toggleJobApprovedDialog}
              className=" py-2 w-full bg-white text-instant-teams-blue-Main uppercase font-semibold rounded-md hover:bg-instant-teams-teal-Main hover:text-white"
            >
              Approve Job
            </button>
          </div>
        ) : null}
      </div>
      {isShowingNote && (
        <GeneralNote onDone={handleDone} collection={collection} id={jobId} />
      )}
      {isJobApprovedDialog && (
        <DialogWrapper
          title="Job Approval"
          onClose={toggleJobApprovedDialog}
          size="w-1/3 h-1/3"
        >
          <div>
            <h1 className="mb-6 uppercase font-semibold text-instant-teams-blue-Main ">{`Approve ${title} job description?`}</h1>
            <p className="mb-6">{`Are you certain you want to approve the ${title} job description? By approving this job description you are confirming that all information is accurate and consistent with your needs. Upon approval your job will be posted and we will begin curating applications for you to view.`}</p>
            <div className="flex justify-center space-x-5">
              <button
                onClick={handleApproveJob}
                className="py-2 px-10 text-white bg-instant-teams-blue-Main rounded border-0 hover:border  hover:bg-instant-teams-teal-Main  focus:outline-none"
              >
                Confirm
              </button>
              <button
                onClick={toggleJobApprovedDialog}
                className="py-2 px-10  rounded hover:text-white  hover:border  hover:bg-instant-teams-teal-Main border border-instant-teams-blue-Main bg-white text-instant-teams-blue-Main focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
      <ToastAlert />
    </div>
  );
}

export default JobsSidebarHeader;

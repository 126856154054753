import React from "react";
import { useEffect, useState } from "react";
import { collectionCounts } from "../../../callable-cloud-functions/cloudFunctions";

function GlobalCounts() {
  const [values, setValues] = useState();
  const [collectionNames, setCollectionNames] = useState([
    "Employer",
    "Job Descriptions",
    "Invitations",
    "Email",
  ]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await collectionCounts({
        collections: collectionNames,
      });
      setValues(data);
    }
    fetchData();
  }, [collectionNames]);

  return (
    <>
      <div className="w-full">
        <div className="flex flex-wrap justify-center text-xl mb-4">
          Collection Counts
        </div>
        <div className="w-full">
          {values &&
            values.map((value, index) => {
              return (
                <div key={index} className="w-full border-b border-gray-300">
                  {values[index].collection}
                  <span className="float-right">{values[index].count}</span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default GlobalCounts;

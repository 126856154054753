import React, { useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { useForm } from "react-hook-form";
import { createNote } from "../../../../data/notes";
import { Transition, Dialog } from "@headlessui/react";
import { Timestamp } from "firebase/firestore";
import { useEffect } from "react";

function GeneralNote({
  onDone,
  collection,
  id,
  minLength = 20,
}: {
  onDone: Function;
  collection: string;
  id: string;
  minLength?: number;
}) {
  const { user, userClaims } = useAuth();
  const [buttonText, setButtonText] = useState("Please complete the note");
  const [formDisabled, setFormDisabled] = useState(true);

  const [formAlert, setFormAlert] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      collection: collection,
      id: id,
      type: "General",
      timestamp: Timestamp.now(),
      who: user.id,
      isPublic: false,
      note: "",
    },
  });

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (userClaims?.claims?.type === "E") {
      setIsChecked(true);
    }
    return () => {
      setIsChecked(false);
    };
  }, [userClaims]);
  const handleTextareaChange = (event: any) => {
    // subscribe to the ref of note to give an accurate result
    const watchedNoteInput = watch("note");

    setCharacterCount(event.target.value.length);

    if (watchedNoteInput.length >= minLength) {
      setFormDisabled(false);
      setButtonText("Save Note");
    } else if (watchedNoteInput.length < minLength) {
      setFormDisabled(true);
      setButtonText("Please complete the note");
    }
  };
  const onSubmit = async (data: any) => {
    console.log("Status Update Data: ", data);
    // exclude uncessary prefix form data
    data.isPublic = isChecked;
    data.timestamp = Timestamp.now();
    data.type = "General";
    setButtonText("Saving...");
    await createNote(id, collection, data)
      .then(() => {
        setButtonText("Save Complete");
        onDone();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Transition appear={true} show={true}>
        <Dialog
          className="overflow-y-auto fixed inset-0"
          onClose={() => onDone()}
        >
          <div className="px-4 min-h-screen text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Note
                </Dialog.Title>
                <div className="mt-4">
                  {formAlert && (
                    <div className="mb-4 text-red-600">{formAlert.message}</div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <input
                      className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
                      name="who"
                      type="hidden"
                      ref={register()}
                    />

                    {userClaims?.claims?.type === "A" ? (
                      <div
                        className="mb-4 float-right"
                        id="share-update-checkbox"
                      >
                        Share update?
                        <input
                          className="ml-4 float-right "
                          type="checkbox"
                          name="isPublic"
                          ref={register()}
                          checked={isChecked}
                          onChange={handleChange}
                          id="general-note-input-field"
                        />
                      </div>
                    ) : null}

                    <div className="mb-2">
                      <textarea
                        className={
                          characterCount < minLength
                            ? "border-red-500 py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded"
                            : "py-1 px-3 font-bold w-full leading-8 bg-gray-200 rounded border border-gray-300 outline-none"
                        }
                        name="note"
                        onChange={handleTextareaChange}
                        ref={register()}
                      />
                    </div>
                    <p className="text-gray-600 text-xs float-right">
                      Character Count: {characterCount}
                      {characterCount < minLength && (
                        <span className="text-red-500">
                          {" "}
                          (minimum: {minLength})
                        </span>
                      )}
                    </p>

                    <button
                      id="general-note-submit-button"
                      className="py-2 px-4 w-full text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                      type="submit"
                      disabled={formDisabled}
                    >
                      {buttonText}
                    </button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default GeneralNote;

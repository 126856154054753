import GreenUnderlineHeading from "../../../components/custom-ui/headings/heading";

import { useLookup } from "../../../data/lookup";
import { LookUp } from "../../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTimeZone,
  getTimeFromMinute,
} from "../../../util/timezone/timezone";

import { sanitizeMarkup } from "../../../util/sanitize/sanitize-html";
import "../../../styles/dangerouslySet.css";

import { useState, useEffect } from "react";
import PreviewWebSkillPart from "./PreviewWebSkillPart";
import { getLookupLabel } from "../../../util/search/getLookupLabel";
import { toUSCurrency } from "../../../util/helpers/toUSCurrency";
import Popover from "../../custom-ui/popover/Popover";

function JobPreviewWebPart({
  employer,
  job,
  position,
  skills,
  timezone,
  KylePopover,
}: {
  employer: any;
  job: any;
  position: any;
  skills: any;
  timezone: any;
  KylePopover: any;
}) {
  const { data: hardwares = [] } = useLookup("hardware");
  const { data: payType = [] } = useLookup("payType");

  const [applying, setApplying] = useState(false);
  const [payTypes, setPayTypes] = useState([]);

  const { data: classification = [] } = useLookup("classification");
  const { data: model = [] } = useLookup("model");

  useEffect(() => {
    if (job) {
      setPayTypes(Object.keys(position?.information?.payType));
    }
    return () => setPayTypes([]);
  }, [job]);

  const jobHardwares = hardwares.filter((hw: any) =>
    job?.technology?.hardware?.includes(hw.code),
  );

  const selectedWorkTypes = getLookupLabel(
    position?.information?.classification,
    classification,
    "classification",
  );
  const workSettings = getLookupLabel(
    position?.information?.model,
    model,
    "model",
  );

  // render matched hardware from job
  const hardware = (hw: LookUp, id: number) => (
    <div key={id} className="flex content-center items-start mt-2 space-x-3">
      {/* @ts-ignore */}
      <FontAwesomeIcon icon={["fas", hw.icon]} size="2x" />
      <p>{hw.label}</p>
    </div>
  );

  const [shiftScheduleStart, shiftScheduleEnd] = position?.shiftSchedule
    ?.range ?? [undefined, undefined];

  // render matched schedule
  const day = (hours: LookUp) => (
    <div className="flex" key={hours?.id}>
      <p className="w-12">{hours?.code}</p>
      <div className="w-36">
        {`${getTimeFromMinute(shiftScheduleStart)} - ${getTimeFromMinute(
          shiftScheduleEnd,
        )}`}
      </div>
      <p>{getTimeZone(timezone)}</p>
    </div>
  );

  return (
    <>
      <section className="pb-16 pt-4 shadow-input-field bg-white rounded-lg">
        <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 px-8">
          <img
            className="my-auto col-span-1 w-24 h-24 object-contain  "
            src={employer?.logo}
            alt={employer?.displayName}
          />

          <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <div className="hover:underline">{employer?.displayName}</div>

            <div className="font-semibold uppercase">{job?.title}</div>
            <div>{position?.positionName}</div>
            {payTypes &&
              payTypes.map((payTypeKey: string, index: number) => {
                const payTypeValue = position?.information?.payType[payTypeKey];
                if (Array.isArray(payTypeValue)) {
                  return (
                    <p className="text-sm font-semibold" key={index}>
                      {getLookupLabel(payTypeKey, payType, "Not Found")}:{" "}
                      {toUSCurrency(payTypeValue[0])} -{" "}
                      {toUSCurrency(payTypeValue[1])} | {selectedWorkTypes} |
                      {workSettings}
                    </p>
                  );
                }
              })}
          </div>
          <div className="col-span-3 space-y-5">
            <Popover content={KylePopover} text="You don't have to do this!">
              <button
                disabled
                className="py-3 w-full uppercase bg-instant-teams-blue-Main  hover:bg-instant-teams-blue-D1 text-white rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20"
              >
                {applying ? "..." : "Apply Now"}
              </button>
            </Popover>
          </div>
          {skills && skills.length > 0 && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="required assessments" />
              <div className="flex space-x-5">
                {skills?.map((skill: any) => {
                  return (
                    <PreviewWebSkillPart assessmentId={skill.assessmentId} />
                  );
                })}
              </div>
            </div>
          )}
          {job && jobHardwares.length > 0 && (
            <div className="col-span-4 md:col-span-8 mr-2">
              <GreenUnderlineHeading title="Required Equimpent" />
              <div className="text-instant-teams-blue-Main">
                {jobHardwares.map(hardware)}
              </div>
            </div>
          )}
          {/* render standard and non-standard shift options */}
          {position?.shiftSchedule?.shiftOption?.code !== "CUSTOM" &&
            position?.shiftSchedule?.days &&
            position?.shiftSchedule?.days.length > 0 && (
              <div className="col-span-4 md:col-span-8 ml-2">
                <GreenUnderlineHeading title="Required Hours" />
                {position?.shiftSchedule?.days?.map(day)}
              </div>
            )}
          {/* render CUSTOM shift options */}

          {position?.shiftSchedule?.shiftOption?.code === "CUSTOM" && (
            <div className="col-span-4 md:col-span-8 ml-2">
              <GreenUnderlineHeading title="Required Hours" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  position?.shiftSchedule?.shiftOption?.description,
                )}
              ></div>
            </div>
          )}
          {job?.description?.dayInTheLife && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Day in the life" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  job?.description?.dayInTheLife,
                )}
              ></div>
            </div>
          )}
          {job?.description?.mustHaves && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="must have" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  job?.description?.mustHaves,
                )}
              ></div>
            </div>
          )}
          {job?.description?.training && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Training" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  job?.description?.training,
                )}
              ></div>
            </div>
          )}
          {job?.description?.benefits && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Benefits" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  job?.description?.benefits,
                )}
              ></div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default JobPreviewWebPart;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { isSeekerDomain } from "../../providers/auth";
import AuthForm from "./AuthForm";
import AuthSocial from "./AuthSocial";
import { multiFactor } from "firebase/auth";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "./ResetPassword";

function Auth(props) {
  const shouldBypassMfa = process.env.REACT_APP_ADMIN_BYPASS_MFA === "true";
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const isMultiFactorUser = (user) => {
    return multiFactor(user).enrolledFactors[0];
  };

  const handleAuth = async (user) => {
    const token = await user?.auth?.currentUser.getIdTokenResult();
    const { claims } = token;
    setUserClaims(claims);

    if (claims.type === "A" && shouldBypassMfa) {
      navigate(props.afterAuthPath);
      return;
    }

    if (
      isSeekerDomain() === true &&
      claims.type === "S" &&
      claims.onboarded === false
    ) {
      navigate("/onboarding");
      return;
    }

    // Check mfa status. If not enrolled navigate user to enrollment page
    if (!isMultiFactorUser(user.auth.currentUser)) {
      navigate("/registration/multifactor-enrollment");
      return;
    }

    navigate(props.afterAuthPath);
  };

  const handleFormAlert = (alert) => {
    // alert {type:string, message:string}
    setFormAlert(alert);
    if (alert.type === "error") {
      toast.error(alert.message);
    }
  };

  return (
    <>
      {formAlert && (
        <div
          className={
            "mb-4 mx-auto w-5/6 text-center whitespace-pre-line" +
            (formAlert.type === "error" ? " text-red-600" : "") +
            (formAlert.type === "success" ? " text-green-600" : "")
          }
        >
          {formAlert.message}
        </div>
      )}
      {/* AuthForm is only for customers - implements username and password credentials */}
      {props.type !== "internalsignin" && (
        <AuthForm
          type={props.type}
          buttonAction={props.buttonAction}
          onAuth={handleAuth}
          onFormAlert={handleFormAlert}
          buttonColor={
            props.hasOwnProperty("buttonColor") ? props.buttonColor : null
          }
          buttonHoverColor={
            props.hasOwnProperty("buttonHoverColor")
              ? props.buttonHoverColor
              : null
          }
        />
      )}
      {["signup", "internalsignin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <AuthSocial
              buttonAction={props.buttonAction}
              providers={props.providers}
              showLastUsed={true}
              onAuth={handleAuth}
              onFormAlert={handleFormAlert}
            />
          )}
        </>
      )}
      <ToastAlert />
    </>
  );
}

export default Auth;

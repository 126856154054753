import Meta from "../../components/custom-ui/meta/Meta";
import {
  SearchBox,
  RefinementList,
  CurrentRefinements,
  ClearRefinements,
  Configure,
  Hits,
  HitsPerPage,
  Pagination,
} from "react-instantsearch-hooks-web";
import SeekerCardComponent from "../../components/seeker/seekerCardComponent";
import { requireAuth } from "../../providers/auth";

function SeekersPage() {
  return (
    <div>
      <Meta title="Seekers" />
      <div className="flex pt-36 pb-36">
        <div className="basis-1/6">
          <div className=" px-6">
            <SearchBox
              placeholder="Keyword Search"
              classNames={{
                input: "w-full",
                submitIcon: "hidden",
                resetIcon: "hidden",
                submit: "hidden",
              }}
            />
            <CurrentRefinements />
            Filter by Status
            <RefinementList
              attribute="status"
              operator="and"
              classNames={{
                root: "capitalize",
                showMore: " text-instant-teams-blue-Main text-xs",
              }}
              limit={5}
              showMore={true}
            />
            <ClearRefinements
              translations={{ resetButtonText: "Clear Filters" }}
              classNames={{
                button:
                  " bg-instant-teams-teal-Main text-white p-2 rounded mt-5 text-center w-full",
              }}
            />
          </div>
        </div>
        <div className="basis-5/6">
          <div className="flex justify-end w-[90%]">
            <div className="flex items-center">
              <div className=" pr-4">Results per page:</div>
              <HitsPerPage
                items={[
                  { label: "10", value: 10 },
                  { label: "25", value: 25 },
                  { label: "50", value: 50, default: true },
                  { label: "100", value: 100 },
                ]}
                classNames={{ select: "text-xs h-6 py-0" }}
              />
            </div>
          </div>
          <div className="mt-4 w-[90%]">
            <Configure filters="claims.role:SKR" />
            <Hits
              hitComponent={(hit: any) => <SeekerCardComponent hit={hit} />}
              classNames={{
                root: "ais-InfiniteHits",
                list: "ais-InfiniteHits-list grid grid-cols-5 gap-4 w-full",
              }}
            />
          </div>
          <div className="flex justify-center">
            <div className="mt-12">
              <Pagination
                showNext={true}
                padding={1}
                classNames={{
                  list: "flex",
                  item: "h-8 w-8 text-center rounded-m rounded mx-1 bg-white",
                  selectedItem: "scale-125 border-black",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default requireAuth(SeekersPage);

import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../providers/auth";
import { useForm } from "react-hook-form";
import { orderBy } from "lodash";
import { addressValidation } from "../../callable-cloud-functions/cloudFunctions";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";
import { Address } from "../../dataTypes/Utilities";
import ButtonWithLoading from "../custom-ui/button/ButtonWithLoading";
import ForwardArrowButton from "../custom-ui/button/ForwardArrowButton";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import GoogleMapEmbed from "../custom-ui/google-map/GoogleMapEmbed";
import useModal from "../../hooks/useModal";
import { LookUp } from "../../dataTypes/LookUp";
import { useLookup } from "../../data/lookup";
import { errorLogger, infoLogger } from "../../providers/clientLogger";
import { connectStorageEmulator } from "firebase/storage";
import { ReactNode } from "react";

// TODO: Refactor
// Pass in a document ref and the id from collection
// Component decides which record to update and what record to query
// no need to pass in db write function or address data
// component gets and saves on its own

function AddressForm({
  id,
  address,
  addressLabel,
  onSubmit,
  onDone,
  buttonVariant,
  returnValue = false,
  children,
}: {
  id: string;
  address?: Address;
  addressLabel: string;
  onSubmit: Function;
  onDone: Function;
  buttonVariant?: string;
  returnValue?: boolean;
  children?: React.ReactNode;
}) {
  const { user } = useAuth();
  const { isShowing, toggle } = useModal();

  const [pending, setPending] = useState(false);
  const [addressValidateError, setAddressValidateError] = useState();

  // countries needs to be added to lookup groups
  const {
    data: countries = [],
    status: countriesQueryStatus = "",
    error: countriesQueryError,
  } = useLookup("countries");

  const regionCode = useRef(null);
  const { ref: placesRef, autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["address"],
      // fields definitions:
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
      fields: ["address_components", "adr_address", "formatted_address"],
    },
    onPlaceSelected: (place) => {
      console.log(place);
    },
  });

  // default addressLine input with current address if passed in from props.address
  const currentAddress = address?.standardizedAddress ?? "";
  const countryCode = address?.country ?? "";

  useEffect(() => {
    placesRef.current.value = currentAddress;
    regionCode.current.value = countryCode;
  }, [currentAddress, countryCode]);

  const [googleVerification, setGoogleVerification] = useState();

  // combines addressLine and cityStatePostalCode for display on address confirmation dialog.
  const [userEnteredAddress, setUserEnteredAddress] = useState();

  // sets whether US Postal Service CASS should be enabled.
  const [enableUspsCass, setEnableUspsCass] = useState(true);

  // How Google Address Validation Expects the data
  const formatForValidation = (regionCode: string, addressLine: string) => ({
    regionCode,
    addressLines: [addressLine],
  });

  const { handleSubmit, register, errors, getValues, setValue } = useForm({
    defaultValues: {
      address: address?.standardizedAddress ?? "",
      verifyAddress: "googleSuggested",
    },
  });

  const handleValidate = (e: any) => {
    setAddressValidateError(undefined);
    e.preventDefault();
    if (regionCode.current.value && placesRef.current.value) {
      validateAddress(
        formatForValidation(regionCode.current.value, placesRef.current.value),
      );
    } else {
      // if etiher region code or placesRef values are empty then display this error message
      setAddressValidateError("Please select a country and enter an address.");
    }
  };

  function validateAddress(address: any) {
    setPending(true);
    addressValidation({ address, enableUspsCass })
      .then((res: any) => {
        setPending(false);
        const { result } = res?.data ?? {};
        setGoogleVerification(result);
        setUserEnteredAddress(placesRef.current.value);
        toggle();
      })
      .catch((error) => {
        setPending(false);
        errorLogger(error.message, {
          component: "MailingAddressForm addressValdiation()",
          flow: "Employer Registration / User Settings",
          userId: user.id,
        });
      })
      .finally(() => setPending(false));
  }

  const confirmAndSaveAddress = (data: any) => {
    setPending(true);
    // set the values to the confirm form using user selection and google address verification data

    // radio button fieldset "verifyAddress" is either "googleSuggested" or "userEntered"
    // determines what data to send to the database after validation

    if (data.verifyAddress === "googleSuggested") {
      const { addressComponents, geocode, metadata, uspsData, address } =
        googleVerification ?? {};
      data.latitude = geocode?.location?.latitude;
      data.longitude = geocode?.location?.longitude;
      data.business = metadata?.business ?? false;
      data.poBox = metadata?.poBox ?? false;
      data.residential = metadata?.residential ?? false;
      data.standardizedAddress = address.formattedAddress;
      data.country = address.postalAddress.regionCode;
      data.state = address?.postalAddress?.administrativeArea || null;
      data.type = geocode?.placeTypes;
      data.label = addressLabel;
    } else {
      data.unverifiedAddress = userEnteredAddress;
      data.label = addressLabel;
    }

    // exclude verifyAddress before sending address data to db
    const { verifyAddress, ...address } = data;

    if (returnValue) {
      setPending(false);
      onSubmit(address);
      toggle();
    } else {
      onSubmit(id, { address })
        .then(() => {
          setPending(false);
          toggle();
          onDone();
        })
        .catch((error: Error) => {
          errorLogger(error.message, {
            component: "MailingAddressForm confirmAndSave()",
            flow: "Employer Registration / Mailing Address Form",
            userId: user.id,
          });
        })
        .finally(() => setPending(false));
    }
  };

  return (
    <>
      {/* 
      Todo: When rendered causes autocomplete to stop rendering
      {address && (
        <div className="mb-4 h-72">
          <div className="mb-2 h-full">
            <GoogleMapEmbed
              center={{
                lat: address.latitude,
                lng: address.longitude,
              }}
              zoom={12}
            />
          </div>
        </div>
      )} */}
      {/* This form sends the address to the addressValidation cloud function for verification */}
      {addressValidateError && (
        <div className="text-left text-red-600 mb-2">
          {addressValidateError}
        </div>
      )}
      <form onSubmit={handleValidate} autoComplete="off">
        <div className="mb-2">
          <select
            onChange={(e) => {
              if (e.target.value === "US") {
                setEnableUspsCass(true);
              } else {
                setEnableUspsCass(false);
              }
            }}
            className="w-full py-1 leading-8 bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-1"
            ref={regionCode}
          >
            {countries.map((country: LookUp) => (
              <option key={country.id} value={country.code}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <input
            ref={placesRef}
            className="py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="addressLine"
            type="text"
          />
        </div>
        {children && <div>{children}</div>}
        {buttonVariant === "arrowButton" ? (
          <div className="flex justify-center">
            <ForwardArrowButton pending={pending} type="submit" />
          </div>
        ) : (
          <ButtonWithLoading pending={pending} value="Verify Address" />
        )}
      </form>
      {/* Confirm Address Dialog */}
      {/* Missing Information Dialog */}
      {/* Couldn't Verify Dialog */}
      {isShowing && (
        <DialogWrapper
          onClose={() => {
            toggle();
          }}
          title="Confirm your mailing address"
        >
          <>
            {/* This form writes the selected address to the database */}
            <form onSubmit={handleSubmit(confirmAndSaveAddress)}>
              <fieldset>
                <legend className="mb-4">Review recommended address.</legend>
                {/* user entered */}
                <div className="mb-2">
                  {/*  Only provide the verified option */}

                  {/* <div className="h-30 p-4 flex flex-col text-instant-teams-gray-D1 justify-center shadow-md">
                    <div className="text-instant-teams-gray-D1">
                      <input
                        type="radio"
                        name="verifyAddress"
                        id="userEntered"
                        value="userEntered"
                        ref={register()}
                      ></input>
                      <label htmlFor="userEntered" className="py-1 px-3">
                        What you entered:
                      </label>
                    </div>
                    <p className="px-7 w-full">{userEnteredAddress}</p>
                  </div> */}
                </div>
                {/* google recommended */}
                <div className="mb-2">
                  <div className="h-72 p-4 flex flex-col text-instant-teams-gray-D1 justify-center shadow-md">
                    <div>
                      <input
                        type="radio"
                        name="verifyAddress"
                        id="recommended"
                        ref={register()}
                        value="googleSuggested"
                      ></input>
                      <label htmlFor="recommended" className="py-1 px-3">
                        Recommended:
                      </label>
                    </div>

                    <p className="px-7 w-full">
                      {googleVerification?.address?.formattedAddress}
                    </p>
                    <div className="mb-2 h-full">
                      <GoogleMapEmbed
                        center={{
                          lat: googleVerification?.geocode?.location?.latitude,
                          lng: googleVerification?.geocode?.location?.longitude,
                        }}
                        zoom={12}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className="flex space-x-4">
                <button
                  className="py-2 px-4 mt-9 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-gray-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none "
                  type="button"
                  onClick={toggle}
                  disabled={pending}
                >
                  Go Back
                </button>
                <button
                  className="py-2 px-4 mt-9 w-full text-xl uppercase  justify-center mx-auto text-white bg-instant-teams-blue-Main rounded-lg border-0 hover:bg-instant-teams-blue-D1 focus:outline-none "
                  type="submit"
                  disabled={pending}
                >
                  Confirm
                </button>
              </div>
            </form>
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default AddressForm;

import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import DailyIframe from "@daily-co/daily-js";
import { useMeeting } from "../../../data/meetings";

function JoinLiveMeetingPage() {
  const { meetingId = "" } = useParams();
  const { data: meetingData, status: meetingQueryStatus } =
    useMeeting(meetingId);
  console.log(meetingData);
  const CALL_OPTIONS = {
    url: "",
    iframeStyle: {
      width: "100%",
      height: "100%",
      border: "1px solid #e6eaef",
      borderRadius: "6px 6px 0 0",
    },
    showLeaveButton: true,
    showFullscreenButton: true,
  };
  const DEFAULT_HEIGHT = 400;
  const videoRef = useRef(null);

  const [callframe, setCallframe] = useState(null);
  const [callInstance, setCallInstance] = useState();
  const [hasRecordingPermission, setHasRecordingPermission] = useState(false);
  const dailyRoomUrl = meetingData?.dailyRoom?.url;

  console.log(callInstance);

  useEffect(() => {
    // if there is a callframe when we start then destroy it
    // to avoid duplicates
    if (callframe) {
      console.log(callframe);
      callframe.destroy();
    }
  }, []);

  useEffect(() => {
    console.log(callframe);
    console.log(videoRef);
    console.log(dailyRoomUrl);
    // if DOM element isn't available or if callframe already exist exit
    if (!videoRef || !videoRef.current || callframe) return;

    console.log("conditions met. Create and set callframe...");
    if (dailyRoomUrl) {
      try {
        CALL_OPTIONS.url = dailyRoomUrl;
        const newCallframe = DailyIframe.createFrame(
          videoRef.current,
          CALL_OPTIONS,
        );
        /**
         * meeting-session-updated
         * https://docs.daily.co/reference/rest-api/meetings
         */
        newCallframe.on("meeting-session-updated", (e) => {
          console.log(e);
          // check participants
          // participantCounts
          // https://docs.daily.co/reference/daily-js/instance-methods/participant-counts
          // https://docs.daily.co/reference/daily-js/instance-methods/participants

          const numOfParticipants = newCallframe.participantCounts();
          console.log(numOfParticipants);
        });

        /**
         * joined-meeting event
         * Emitted once the local participant has joined the call. At this point, the call is considered connected.
         * https://docs.daily.co/reference/daily-js/events/meeting-events
         */
        newCallframe.on("joined-meeting", (e) => {
          console.log(e);
          setCallInstance(DailyIframe.getCallInstance());

          /**
           * Start Recording
           * https://docs.daily.co/reference/daily-js/instance-methods/start-recording#customize-your-video-layout
           */
          newCallframe.startRecording({
            width: 1280,
            height: 720,
            fps: 25,
            backgroundColor: "#FF1F2D3D",
            layout: {
              preset: "default",
              max_cam_streams: 5,
            },
          });
        });

        newCallframe.on("recording-started", (e) => {
          console.log(e);
        });

        console.log(newCallframe);
        console.log("preparing to join meeting....");
        newCallframe.join().then(() => {
          console.log("joining meeting...");

          setCallframe(newCallframe);
        });
      } catch (error) {
        console.log("Problem joining meeting...", error);
      }
    }
    // cleanup function

    return () => {
      if (callframe) {
        // remove iframe

        console.log("leave and destroy callframe.");
        callframe.leave();
        callframe.destroy();
      }
    };
  }, [videoRef.current, dailyRoomUrl]);

  console.log(meetingData);
  console.log(meetingId);

  return (
    <>
      <div className="mt-28 h-screen">
        {/*  */}

        <div className="h-full">
          {/* @ts-ignore */}
          <div className="h-full" ref={videoRef}></div>
        </div>
      </div>
      {!hasRecordingPermission && (
        <DialogWrapper
          onClose={() => {}}
          title="Recording Notice"
          size=" w-1/3 sm:w-full md:w-full h-52 mt-36"
        >
          <>
            <div className="w-full text-center text-xl text-instant-teams-teal-D1 font-bold">
              <p>This meeting will be recorded.</p>
              <p>Please select 'Continue' to proceed.</p>

              <button
                className="mt-4 md:w-full w-80 py-2 px-4 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-L1 focus:outline-none"
                onClick={() => setHasRecordingPermission(true)}
              >
                Continue
              </button>
            </div>
          </>
        </DialogWrapper>
      )}
    </>
  );
}

export default JoinLiveMeetingPage;

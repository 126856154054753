import { useAuth } from "../../providers/auth";
import { useState, useEffect, Fragment } from "react";
import { useLocation, Link, NavLink } from "react-router-dom";
import Auth from "../auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-duotone-svg-icons";
import {
  faGear,
  faRightFromBracket,
  faBell,
  faCircleInfo,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from "@headlessui/react";
import { EnvAlert } from "./EnvAlert";
import { optionsByType } from "../auth/signInOptions";
import NotificationList from "../notifications/notificationsList";
import { useGlobalState } from "../../providers/globalState/GlobalStateProvider";
import MessageList from "../messages/MessageList";
import { navbarRoutes } from "../../react-router_routes/navbarRoutes";
import { useUser } from "../../providers/database";
import VideoHelp from "../video/VideoHelp";
import MeetingsHeaderList from "../live/meetingsHeaderList";
import MeetingsHeaderListSeeker from "../live/meetingsHeaderListSeeker";
import { useResume } from '../../data/seeker';

function Navbar() {
  const auth = useAuth();
  const { userClaims } = useAuth();
  const { data: userData } = useUser(auth.user?.uid);
  const {data: resumeData} = useResume(auth.user?.uid);
  const { globalState, setGlobalState } = useGlobalState();
  const [seekerNavLocked, setSeekerNavLocked] = useState(true)
  // Internal Sign in
  const internalSignIn = optionsByType["internalsignin"];

  //Onboarded flag is not functioning as expected
  useEffect(() => {
    if(resumeData && resumeData?.classifications !== undefined && resumeData?.classifications !== null && resumeData?.models !== undefined && resumeData?.models !== null && resumeData?.terms !== undefined && resumeData?.terms !== null) {
      setSeekerNavLocked(false)
    }
  },[resumeData]);

  return (
    <div className="">
      {(userClaims?.claims?.type !== "S" || !seekerNavLocked) && auth.user && (
        <div className="flex">
          <div className="flex-auto">
            <div className="flex h-20 items-center">
              <h1 className="md:text-lg 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl mr-auto ml-48 lg:ml-56 xl:ml-44 2xl:ml-56 header-title">
                {globalState.pageTitle}
              </h1>
              <Link
                to="/faq"
                className="flex text-white relative h-full w-12 mt-2 pb-2 hover:pb-1 hover:border-b-4 hover:border-green-400 overflow-hidden justify-center items-center"
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="h-6 w-6 text-white"
                />
              </Link>
              {auth?.user?.claims?.type === "S" ? (
                <MeetingsHeaderListSeeker userId={auth.user?.uid} />
              ) : (
                <MeetingsHeaderList userId={auth.user?.uid} />
              )}
              <MessageList userId={auth.user?.id} />
              <NotificationList userId={auth.user?.id} />
              <Menu as="div" className="relative inline-block text-left pr-6">
                <div>
                  <Menu.Button className="flex items-center w-full justify-center rounded-2xl rounded-l-2xl hover:bg-instant-teams-teal-D1">
                    <img
                      id="avatarButton"
                      type="button"
                      data-dropdown-toggle="userDropdown"
                      data-dropdown-placement="bottom-start"
                      className="object-cover h-8 w-8 mr-2 rounded-full cursor-pointer"
                      src={userData?.photoURL ?? "/images/default_user.png"}
                      alt="User dropdown"
                    />
                    <div className="flex  ">
                      <div className="flex flex-col justify-start justify-items-start ">
                        <p className=" flex sm:hidden md:hidden pr-1 leading-3 py-0.5   ">
                          {auth.user?.preferredName ?? auth.user?.name ?? "--"}
                        </p>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="pr-2 pl-1 md:pl-0 text-white flex"
                      swapOpacity
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-4 mt-2 w-40 md:w-36 origin-top-right divide-y divide-gray-100 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="p-1">
                      <Link
                        to="/settings/general"
                        onClick={() =>
                          setGlobalState({
                            ...globalState,
                            pageTitle: "My Profile",
                          })
                        }
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? " bg-instant-teams-teal-L4 bg-opacity-20 text-black "
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md p-2 text-sm `}
                            >
                              <FontAwesomeIcon
                                icon={faGear}
                                className="pr-1 pl-1 text-instant-teams-teal-Main flex md:hidden sm:hidden"
                                swapOpacity
                              />
                              <div className="ml-3">My Profile</div>
                            </button>
                          )}
                        </Menu.Item>
                      </Link>
                    </div>
                    <div className="p-1">
                      <Link
                        to={`/notifications/${auth?.user?.id}`}
                        onClick={() =>
                          setGlobalState({
                            ...globalState,
                            pageTitle: "My Profile",
                          })
                        }
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? " bg-instant-teams-teal-L4 bg-opacity-20 text-black"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md p-2 text-sm `}
                            >
                              <FontAwesomeIcon
                                icon={faBell}
                                className="pr-1 pl-1 text-instant-teams-teal-Main flex md:hidden sm:hidden"
                                swapOpacity
                              />
                              <div className="ml-3">My Notifications</div>
                            </button>
                          )}
                        </Menu.Item>
                      </Link>
                    </div>
                    <div className="p-1">
                      <Link
                        to="/auth/signout"
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? " bg-instant-teams-teal-L4 bg-opacity-20 text-black"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md p-2 text-sm`}
                            >
                              <FontAwesomeIcon
                                icon={faRightFromBracket}
                                className="pr-1 pl-1 text-instant-teams-teal-Main flex md:hidden sm:hidden"
                                swapOpacity
                              />
                              <div className="ml-3">Logout</div>
                            </button>
                          )}
                        </Menu.Item>
                      </Link>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <nav className="h-[2.7rem] flex flex-wrap justify-center items-center  bg-instant-teams-blue-Main border-b-2 border-instant-teams-blue-D1">
              <div className="flex space-x-2 ">
                {navbarRoutes
                  .filter((route) =>
                    route.viewer.includes(userClaims?.claims?.role),
                  )
                  .map((link, idx) => (
                    <NavLink
                      key={idx}
                      to={link.link}
                      onClick={() =>
                        setGlobalState({
                          ...globalState,
                          pageTitle: link.name,
                        })
                      }
                      className={({ isActive }) =>
                        "flex  hover:border-b-2 hover:border-instant-teams-green-Main text-white py-2 w-36 md:w-full lg:w-20 xl:w-32 justify-center " +
                        (isActive
                          ? "bg-instant-teams-teal-Main text--white  shadow-lg border-b-2  border-instant-teams-green-Main"
                          : "")
                      }
                    >
                      {link.name}
                    </NavLink>
                  ))}
              </div>
            </nav>
            <EnvAlert />
          </div>
        </div>
      )}

      {!auth.user &&
        (window.location.pathname !== "/authaction" ||
          window.screen.width >= 640) && (
          <div className=" ">
            <div className="flex">
              <div className="flex-auto">
                <div className="flex pt-2 h-20">
                  <h1 className="text-5xl md:text-2xl  mr-auto ml-48 header-title ">
                    Welcome to Instant Teams!
                  </h1>
                  <div className="h-full flex content-center mx-4 pt-4">
                    <Auth
                      type={"internalsignin"}
                      buttonAction={internalSignIn.buttonAction}
                      providers={["instantTeamsWorkspace"]}
                      afterAuthPath={"/dashboard/home"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <nav className="h-[2.718rem] flex flex-wrap md:justify-center items-center sm:justify-end bg-instant-teams-blue-Main"></nav>
            <EnvAlert />
          </div>
        )}
      {auth.user && userClaims?.claims?.type === "S" && seekerNavLocked && (
        <div className=" ">
          <div className="flex">
            <div className="flex-auto">
              <div className="flex pt-2 h-20">
                <h1 className="text-5xl md:text-2xl  mr-auto ml-48 header-title ">
                  Welcome to Instant Teams!
                </h1>
                <Menu as="div" className="relative inline-block text-left pr-6">
                <div className='pt-4'>
                  <Menu.Button className="flex items-center w-full justify-center rounded-2xl rounded-l-2xl hover:bg-instant-teams-teal-D1">
                    <img
                      id="avatarButton"
                      type="button"
                      data-dropdown-toggle="userDropdown"
                      data-dropdown-placement="bottom-start"
                      className="object-cover h-8 w-8 mr-2 rounded-full cursor-pointer"
                      src={userData?.photoURL ?? "/images/default_user.png"}
                      alt="User dropdown"
                    />
                    <div className="flex  ">
                      <div className="flex flex-col justify-start justify-items-start ">
                        <p className=" flex sm:hidden md:hidden pr-1 leading-3 py-0.5   ">
                          {auth.user?.preferredName ?? auth.user?.name ?? "--"}
                        </p>
                      </div>
                    </div>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="pr-2 pl-1 md:pl-0 text-white flex"
                      swapOpacity
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-4 mt-2 w-40 md:w-36 origin-top-right divide-y divide-gray-100 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="p-1">
                      <Link
                        to="/auth/signout"
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? " bg-instant-teams-teal-L4 bg-opacity-20 text-black"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md p-2 text-sm`}
                            >
                              <FontAwesomeIcon
                                icon={faRightFromBracket}
                                className="pr-1 pl-1 text-instant-teams-teal-Main flex md:hidden sm:hidden"
                                swapOpacity
                              />
                              <div className="ml-3">Logout</div>
                            </button>
                          )}
                        </Menu.Item>
                      </Link>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              </div>
            </div>
          </div>          
          <nav className="h-[2.718rem] flex flex-wrap md:justify-center items-center sm:justify-end bg-instant-teams-blue-Main"></nav>
          <EnvAlert />
        </div>
      )}
    </div>
  );
}

export default Navbar;

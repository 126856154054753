import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../providers/database";
import { AccessLog } from "../../../../dataTypes/AccessLogs";
import AccessLogItem from "../../../accessLog/accessLogItem";
import { useAllAccessLogs } from "../../../../data/accessLogs";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import { useRef } from "react";

function AccessLogListing() {
  const params = useParams();
  const [startDate, setStartDate] = useState(Date.parse("1970-01-01"));
  const [endDate, setEndDate] = useState(Date.now());
  

  const {
    data: userData,
    status: userStatus,
    error: userError,
  } = useUser(params.userId);
  const {
    data: accessLogs,
    status: accessLogsStatus,
    error: accessLogsError,
  } = useAllAccessLogs(params.userId || "");
  const [filteredAccessLogs, setFilteredAccessLogs] = useState(accessLogs);

  useEffect(() => {
    setFilteredAccessLogs(accessLogs?.filter((log: AccessLog) => log.timestamp.seconds *1000 >= startDate && log.timestamp.seconds *1000<= endDate))
    console.log(accessLogs, startDate, endDate)
  }, [startDate, endDate, accessLogs]);

  
  return (
    <>
      <div>
        <div className="flex flex-row justify-evenly align-baseline">
        <label>Start Date:</label>
        <input type="date" className="rounded-md" onChange={(e) => {setStartDate(Date.parse(e.target.value))}}></input>
        <label>End Date:</label>
          <input type="date" className="rounded-md" onChange={(e) => { setEndDate(Date.parse(e.target.value)) }}></input>
          <label>Filter</label>
          <select className="rounded-md" onChange={(e) => {
            switch (e.target.value) {
              case "7":
                setStartDate(Date.now() - 7 * 24 * 60 * 60 * 1000);
                break;
              case "14":
                setStartDate(Date.now() - 14 * 24 * 60 * 60 * 1000);
                break;
              case "30":
                setStartDate(Date.now() - 30 * 24 * 60 * 60 * 1000);
                break;
              default:
                break;
            }
          }}>
            <option>None</option>
            <option value={7}>Last 7 Days</option>
            <option value={14}>Last 2 Weeks</option>
            <option value={30}>Last Month</option>
          </select>
          <button className="rounded-md bg-instant-teams-blue-Main border-black border-solid border-2 text-white px-2 py-1" onClick={()=> {
            setStartDate(0)
            setEndDate(Date.now())
          }}>Clear</button>
        </div>
     
      </div>
      <GreenUnderlineHeading title="Access Logs" />
      {filteredAccessLogs &&
        filteredAccessLogs.map((row: AccessLog, index: number) => {
          return <AccessLogItem entry={row} />;
        })}
    </>
  );
}

export default AccessLogListing;

import { useEffect, useState } from "react";
import { ZodError, ZodIssue } from "zod";
type PwdReq = {
  [key: string]: string;
};
function PwdCheck({
  schema,
  value,
  pwdReq,
  listStyles = "",
}: {
  schema: any;
  value: string;
  pwdReq: PwdReq;
  listStyles?: string;
}) {
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    if (schema.parse) {
      const zodErrors = schema.safeParse(value).error;
      if (zodErrors) {
        const issues = zodErrors.issues;
        const errorPaths = issues.reduce(
          (accumulator: string[], zodIssue: ZodIssue) => {
            return [...accumulator, ...zodIssue.path];
          },
          [],
        );
        setErrors(errorPaths);
      } else {
        setErrors([]);
      }
    }
  }, [schema, value]);

  return (
    <ul className={`list-none ${listStyles}`}>
      {Object.keys(pwdReq).map((key, index) => {
        const isError = () => !errors.includes(key);
        const showPassFail = () => {
          return isError() ? (
            value ? (
              <i className="mx-2 fa-solid fa-check text-instant-teams-green-D1"></i>
            ) : (
              ""
            )
          ) : (
            <i className="mx-2 fa-solid fa-xmark text-instant-teams-red-Main"></i>
          );
        };
        return (
          <li key={index}>
            {showPassFail()}
            {pwdReq[key]}
          </li>
        );
      })}
    </ul>
  );
}

export default PwdCheck;

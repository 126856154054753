import { useNavigate, useParams } from "react-router-dom";
import { useListing } from "../../data/listings";
import Meta from "../../components/custom-ui/meta/Meta";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";

import { useLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTimeZone, getTimeFromMinute } from "../../util/timezone/timezone";
import {
  useJobPositionDetails,
  useActiveJobPositions,
} from "../../data/positions";
import { useJobDescription } from "../../data/jobDescriptions";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/dangerouslySet.css";
import { Link } from "react-router-dom";
import { requireAuth, useAuth } from "../../providers/auth";
import { useState } from "react";
import {
  applicationDocExists,
  checkIfInvitedAndGetId,
  createSeekerApplication,
  preScreenSubcollectionExists,
  updateStatus,
  useApplicationsByUser,
} from "../../data/applications";
import { useEmployer } from "../../data/employer";
import JobSkillListing from "./JobSkillListings";
import ButtonWithLoading from "../../components/custom-ui/button/ButtonWithLoading";

function SeekerListingDetails({}) {
  const INSTANTTEAMS_ID = process.env.REACT_APP_INSTANT_TEAMS_ID || "";
  const { data: InstantTeamsData } = useEmployer(INSTANTTEAMS_ID);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { listingId = "" } = useParams();
  const { data: listingData } = useListing(listingId);
  const { data: positionData } = useJobPositionDetails(
    listingData?.jobId,
    listingData?.positionId,
  );
  const { data: applicationsData = [] } = useApplicationsByUser(user?.uid);
  const alreadyAppliedToThisJob = applicationsData?.find(
    (a: any) => a.jobId === listingData?.jobId,
  );

  const modifyBPOListing = (listing: any) => {
    return {
      ...listing,
      companyName: InstantTeamsData?.displayName,
      companyLogo: InstantTeamsData?.logo,
      employerId: INSTANTTEAMS_ID,
    };
  };
  const isBPOListing = (listingData: any) => {
    return listingData?.solutionType === "BPO";
  };

  const updateListingDataForBPO = (listingData: any) => {
    if (isBPOListing(listingData)) {
      return modifyBPOListing(listingData);
    }
    return listingData;
  };
  const updatedListingData = updateListingDataForBPO(listingData);
  const { data: jobData } = useJobDescription(updatedListingData?.jobId);
  const { data: hardwares = [] } = useLookup("hardware");
  const { data: payType = [] } = useLookup("payType");

  const { data: positionsData } = useActiveJobPositions(
    updatedListingData?.jobId,
  );
  const [showApply, setShowApply] = useState(true);
  const [applying, setApplying] = useState(false);

  const jobHardwares = hardwares.filter((hw: any) =>
    jobData?.technology?.hardware?.includes(hw.code),
  );

  // render matched hardware from job
  const hardware = (hw: LookUp, id: number) => (
    <div key={id} className="flex content-center items-start mt-2 space-x-3">
      {/* @ts-ignore */}
      <FontAwesomeIcon icon={["fas", hw.icon]} size="2x" />
      <p>{hw.label}</p>
    </div>
  );

  const [shiftScheduleStart, shiftScheduleEnd] = positionData?.shiftSchedule
    ?.range ?? [undefined, undefined];

  // render matched schedule
  const day = (hours: LookUp) => (
    <div className="flex" key={hours?.id}>
      <p className="w-12">{hours?.code}</p>
      <div className="w-36">
        {`${getTimeFromMinute(shiftScheduleStart)} - ${getTimeFromMinute(
          shiftScheduleEnd,
        )}`}
      </div>
      <p>{getTimeZone(updatedListingData?.location?.timezone?.id)}</p>
    </div>
  );
  const navigateTo = (applicationId: string) => {
    if (positionsData && positionsData?.length > 1) {
      navigate(`/other-positions/${applicationId}`);
    } else {
      navigate(`/pre-screen-questions/${applicationId}`);
    }
  };
  const checkIfPreScreenQuestionsAreGenerated = (applicationId: string) => {
    const intervalId = setInterval(async () => {
      const applicationExists = await applicationDocExists(applicationId);
      const preScreenExists = await preScreenSubcollectionExists(applicationId);
      if (preScreenExists && applicationExists) {
        clearInterval(intervalId);
        setApplying(false);
        navigateTo(applicationId);
      } else if (!applicationExists) {
        clearInterval(intervalId);
        setApplying(false);
      }
    }, 1500);
  };

  const apply = async () => {
    setApplying(true);
    try {
      const applicationId = await checkIfInvitedAndGetId(
        user.uid,
        updatedListingData.jobId,
        updatedListingData.positionId,
      );
      if (applicationId) {
        await updateStatus(applicationId, "applied");
        navigateTo(applicationId);
      } else {
        const { id } = await createSeekerApplication({
          jobId: updatedListingData.jobId,
          positionId: updatedListingData.positionId,
          resumeId: user.uid,
        });
        checkIfPreScreenQuestionsAreGenerated(id);
      }
    } catch (err) {
      setApplying(false);
    }
  };

  return (
    <>
      <Meta title="Jobs" />
      <section className="pt-40 pb-16">
        <div className="grid grid-flow-row mx-auto grid-cols-8 gap-y-3 w-3/4">
          <Link to={`/companies/${updatedListingData?.employerId}`}>
            <img
              className="my-auto col-span-1 w-24 h-24 object-contain  "
              src={updatedListingData?.companyLogo}
              alt={updatedListingData?.companyName}
            />
          </Link>
          <div className="pl-5 col-span-4 my-auto  text-instant-teams-blue-Main justify-start md:text-sm">
            <Link to={`/companies/${updatedListingData?.employerId}`}>
              <div className="hover:underline">
                {updatedListingData?.companyName}
              </div>
            </Link>
            <div className="font-semibold uppercase">
              {updatedListingData?.title}
            </div>
            <div>{updatedListingData?.subTitle}</div>
            <div className="font-semibold">
              {updatedListingData?.positionPayType} |{" "}
              {updatedListingData?.classification} | {updatedListingData?.model}
            </div>
          </div>
          <div className="col-span-3 space-y-5">
            {!alreadyAppliedToThisJob ||
            alreadyAppliedToThisJob?.status === "invited" ||
            applying ? (
              <ButtonWithLoading
                disabled={applying}
                onClick={apply}
                pending={applying}
                value={"Apply Now"}
              />
            ) : null}
            {/* Dismiss is hidden, no functionality atm */}
            <button className=" hidden py-3 w-full uppercase bg-white border border-instant-teams-blue-Main text-instant-teams-blue-Main hover:text-white hover:bg-instant-teams-blue-D1 rounded-lg md:py-2 md:px-8 md:text-sm lg:py-2 lg:px-16 xl:px-20">
              Dismiss
            </button>
          </div>
          {updatedListingData?.skills &&
            updatedListingData?.skills.length > 0 && (
              <div className="col-span-8">
                <GreenUnderlineHeading title="required assessments" />
                <div className="flex space-x-5">
                  {updatedListingData?.skills?.map((skill: any) => {
                    console.log("skill", skill);
                    return (
                      <JobSkillListing
                        skill={skill}
                        key={skill?.assessmentId}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          {jobData && jobHardwares.length > 0 && (
            <div className="col-span-4 md:col-span-8 mr-2">
              <GreenUnderlineHeading title="Required Equimpent" />
              <div className="text-instant-teams-blue-Main">
                {jobHardwares.map(hardware)}
              </div>
            </div>
          )}
          {/* render standard and non-standard shift options */}
          {positionData?.shiftSchedule?.shiftOption?.code !== "CUSTOM" &&
            positionData?.shiftSchedule?.days &&
            positionData?.shiftSchedule?.days.length > 0 && (
              <div className="col-span-4 md:col-span-8 ml-2">
                <GreenUnderlineHeading title="Required Hours" />
                {positionData?.shiftSchedule?.days?.map(day)}
              </div>
            )}
          {/* render CUSTOM shift options */}

          {positionData?.shiftSchedule?.shiftOption?.code === "CUSTOM" && (
            <div className="col-span-4 md:col-span-8 ml-2">
              <GreenUnderlineHeading title="Required Hours" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  positionData?.shiftSchedule?.shiftOption?.description,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.dayInTheLife && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Day in the life" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.dayInTheLife,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.mustHaves && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="must have" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.mustHaves,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.training && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Training" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.training,
                )}
              ></div>
            </div>
          )}
          {jobData?.description?.benefits && (
            <div className="col-span-8">
              <GreenUnderlineHeading title="Benefits" />
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  jobData?.description?.benefits,
                )}
              ></div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default requireAuth(SeekerListingDetails);

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  addEmployerLocation,
  useEmployerUsers,
} from "../../../../data/employer";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../../../providers/auth";
import AddressForm from "../../../address/AddressForm";
import { Locations } from "../../../../dataTypes/Company";
import { Address } from "../../../../dataTypes/Utilities";
import { useCurrentUserEmployerId } from "../../../../hooks/useCurrentUserEmployerId";

const CreateEmployerLocation = (props: any) => {
  const userEmployerId = useCurrentUserEmployerId();
  const auth = useAuth();
  const params = useParams();
  const timezoneKey = process.env.GOOGLE_TIMEZONE_API_KEY;
  const [employerId, setEmployerId] = useState(
    params.employerId || userEmployerId,
  );
  const [name, setName] = useState("");
  const [manager, setManager] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isRemote, setIsRemote] = useState(false);
  const [address, setAddress] = useState({});
  const [save, setSave] = useState(false);

  const [mapSource, setMapSource] = useState(
    "https://maps.google.com/maps?q=35.856737, 10.606619&z=15&output=embed",
  );
  const [collectionName, setCollectionName] = useState("");

  const {
    data: employees = [],
    status: employeesStatus,
    error: employeesError,
  } = useEmployerUsers(employerId ?? userEmployerId);

  const placeholder = "Please select an employee";
  useEffect(() => {
    setCollectionName("Employers/" + employerId + "/Locations");
  }, [employerId]);

  useEffect(() => {
    if (save && address.longitude) {
      const newLocation: Locations = {
        label: name,
        active: isActive,
        remote: isRemote,
        address: address,
        manager: manager,
        createdAt: Timestamp.now(),
        createdBy: auth.user.id,
      };
      addEmployerLocation(employerId, newLocation);

      setName("");
      setManager(null);
      props.closeModal();
    }
  }, [save]);

  useEffect(() => {
    console.log(address);
    setMapSource(
      "https://maps.google.com/maps?q=" +
        address.latitude +
        ", " +
        address.longitude +
        "&z=15&output=embed",
    );
  }, [address]);

  const handleDone = () => {
    console.log("done");
  };

  return (
    <div className="flex flex-col gap-2 mb-4">
      <div className="flex gap-1 items-center">
        <label>Location Name</label>
      </div>
      <div className="flex gap-1 items-center">
        <input
          type="text"
          className="w-full shadow-md"
          required
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-1 items-center">
        <label>Manager</label>
      </div>
      <div className="flex gap-1 items-center mb-4">
        <select
          onChange={(e) => {
            setManager(e.target.value);
          }}
          className="w-full shadow-md"
        >
          <option
            label={"Please select an employee"}
            selected={true}
            disabled={true}
            hidden={true}
          />
          {Object.keys(employees).length ? (
            employees.map((employee: any) => (
              <option value={employee.id} key={employee.id}>
                {employee.preferredName}
              </option>
            ))
          ) : (
            <option disabled={true}>N/A</option>
          )}
        </select>
      </div>
      <div className="flex gap-1 items-center">
        <label>Status</label>
      </div>
      <div className="flex space-x-4">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            checked={isActive}
            onChange={(e) => setIsActive(true)}
          />
          <span className="ml-2 text-gray-700">Active</span>
        </label>

        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            checked={!isActive}
            onChange={(e) => setIsActive(false)}
          />
          <span className="ml-2 text-gray-700">Inactive</span>
        </label>
      </div>
      <div className="flex gap-1 items-center">
        <label>Location Type</label>
      </div>
      <div className="flex space-x-4">
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            checked={!isRemote}
            onChange={(e) => setIsRemote(false)}
          />
          <span className="ml-2 text-gray-700">On-Site/Hybrid/Remote</span>
        </label>
        <label className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio h-4 w-4 text-indigo-600"
            checked={isRemote}
            onChange={(e) => setIsRemote(true)}
          />
          <span className="ml-2 text-gray-700">Remote Only</span>
        </label>
      </div>
      <div className="flex gap-1 items-center">
        <label>Address</label>
      </div>
      {!address?.latitude ? (
        <div className="w-full mb-4">
          <AddressForm
            id={employerId}
            addressLabel="primary"
            onSubmit={(data: any) => {
              setAddress(data);
            }}
            onDone={handleDone}
            returnValue={true}
          />
        </div>
      ) : (
        <div className="mx-auto center-items mb-4 w-full">
          <iframe
            className="w-full"
            src={mapSource}
            width="360"
            height="270"
            frameBorder="0"
            style={{ border: 0 }}
          ></iframe>
        </div>
      )}
      <button
        onClick={() => {
          setSave(true);
        }}
        className=" bg-instant-teams-teal-Main mx-auto w-1/2 text-white rounded-md text-lg"
      >
        Add Location
      </button>
    </div>
  );
};

export default CreateEmployerLocation;

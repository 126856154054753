import React, { useEffect } from "react";
import { useAuth } from "../../../../providers/auth";
import { useParams } from "react-router";
import {
  useApplication,
  useApplicationPositions,
} from "../../../../data/applications";
import ReqSkillItemBasic from "../../parts/ReqSkillItemBasic";
import RedactedSeekerInfo from "../../../seeker/externalView/parts/redactedSeekerInfo";
import GreenUnderlineHeading from "../../../custom-ui/headings/heading";
import Meta from "../../../custom-ui/meta/Meta";
import SeekerPositionListItem from "../../../seeker/details/parts/seekerPositionListItem";
import ApplicationPositionItem from "../../../application/ApplicationPositionItem";

import { useAllSharedNotesByCollectionAndId } from "../../../../data/notes";
import {
  collectionNames,
  subCollectionNames,
} from "../../../../data/dictionary/collectionNames";
import CompanyNotesListing from "../../../employerView/pages/CompanyNotesListing";
import NoteDisplay from "../../../notes/note-display";
import SeekerNoteDisplay from "../../../seeker/details/parts/seekerNoteDisplay";
import Tooltip from "../../../custom-ui/tooltip/tooltip";
import useModal from "../../../../hooks/useModal";
import GeneralNote from "../../../custom-ui/input-fields/modals/GeneralNote";
import SeekerInfo from "../../../seeker/details/parts/seekerInfo";
import { Link } from "react-router-dom";
import DialogWrapper from "../../../custom-ui/dialog/Dialog";
import RescindOfferModal from "../../parts/RescindOfferModal";
import ScheduleMeetingActions from "../../../live/ScheduleMeetingActions";
import { useEmployer } from "../../../../data/employer";
import { useJobDescription } from "../../../../data/jobDescriptions";
import AnalyticsLogger from "../../../../providers/analyticsLogger";
import DeclineApplication from "../../parts/DeclineApplication";
import { useNavigate, useLocation } from "react-router-dom";
import { createNotification } from "../../../../data/notifications";
import { getUser } from "../../../../providers/database";
import { useState } from "react";
import ExternalNotesApplicationView from "../../../application/parts/ExternalNotesApplicationView";
import ToastAlert from "../../../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";

const ExternalJobApplicantDetails = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { notes } = subCollectionNames;
  const { applications, offers } = collectionNames;
  const params = useParams();
  const { applicationId = "" } = params ?? {};
  const { data: jobApplicationData, status: jobApplicationDataStatus } =
    useApplication(applicationId);

  const { data: jobDescriptionData } = useJobDescription(
    jobApplicationData?.jobId,
  );

  const { data: employerData } = useEmployer(jobApplicationData?.employer);

  const { isShowing: isShowingNote, toggle: toggleNote } = useModal();
  const { isShowing: rescindOffer, toggle: toggleRescindOffer } = useModal();

  const { requiredSkills = [] } = jobApplicationData ?? {};

  const { data: positions } = useApplicationPositions(applicationId);
  const [shouldNotesFetch, setShouldNotesFetch] = useState(true);

  const handleDone = () => {
    toggleNote();
  };
  useEffect(() => {
    if (user.claims.employerId === employerData?.id) {
      const name = getUser(jobApplicationData.resumeId).then((res) => {
        console.log(res);
        createNotification({
          title: "Employer Viewed Application",
          body: `Employer ${employerData.displayName} viewed ${res.name}'s application for ${jobDescriptionData.title}`,
          createdBy: "system",
          isPushNotification: false,
          recipientId: employerData.TAC,
          employerId: employerData.id,
        });
      });
      //
    }
  }, []);

  const handleDeclineApplicationToaster = () => {
    toast.success("Application Declined");
  };

  const handleDeclineModal = () => {
    setShouldNotesFetch(false);
    toggleRescindOffer();
  };

  useEffect(() => {
    if (user.claims.employerId === employerData?.id) {
      console.log("EMPLOYER VIEWING APPLICATION", jobApplicationData.resumeId);
      const name = getUser(jobApplicationData.resumeId).then((res) => {
        createNotification({
          title: "Employer Viewed Application",
          body: `Employer ${employerData.displayName} viewed ${res.name}'s application for ${jobDescriptionData.title}`,
          createdBy: "system",
          isPushNotification: false,
          recipientId: employerData.TAC,
          employerId: employerData.id,
        });
      });
    }
  }, []);

  return (
    <div>
      <Meta title="Job Applicant Details" />
      <AnalyticsLogger
        type={collectionNames.applications}
        typeId={applicationId}
      />
      {jobApplicationDataStatus === "success" && (
        <div className="grid grid-cols-2 gap-1 pb-12">
          {/* 1st Left */}
          {jobApplicationData?.status === "offered" ? (
            <div className="">
              <SeekerInfo
                imageVisible={true}
                userId={jobApplicationData?.resumeId}
              />
            </div>
          ) : (
            <div className="col-span-1 md:col-span-1">
              <RedactedSeekerInfo
                userId={jobApplicationData.resumeId}
                imageVisible={false}
              />
            </div>
          )}
          {/* 1st Right */}
          {jobApplicationData?.status !== "declined" ? (
            <div className="flex flex-col items-center justify-center space-y-5 ">
              {jobApplicationData?.status === "offered" ? (
                <Link
                  to={`/offer-letter/${jobApplicationData?.jobOfferId}`}
                  target="_blank"
                >
                  <button className="py-1 w-80 text-instant-teams-blue-Main font-semibold uppercase border border-instant-teams-blue-Main rounded-md">
                    View Offer Letter
                  </button>
                </Link>
              ) : (
                <div className="w-80 ">
                  <ScheduleMeetingActions
                    refType="application-meeting" // different types of meetings assoc w/ reftypes
                    refId={applicationId}
                    inviteeId={jobApplicationData?.resumeId}
                    title={`${employerData?.displayName} - ${jobDescriptionData?.title}`}
                    extended={{
                      employerId: jobApplicationData?.employer,
                      jobId: jobApplicationData?.jobId,
                    }}
                  />
                </div>
              )}
              <button
                onClick={handleDeclineModal}
                className=" py-2 w-80 text-instant-teams-blue-Main font-semibold uppercase border border-instant-teams-blue-Main rounded-md"
              >
                Decline
              </button>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center text-instant-teams-teal-Main font-semibold">
                You declined this application.
              </div>
            </>
          )}
          {/* 2nd Left */}
          <div>
            <GreenUnderlineHeading title="Skills" />
            <div className="grid grid-cols-4 gap-2">
              {requiredSkills &&
                requiredSkills.map((skill: any) => (
                  <ReqSkillItemBasic
                    assessment={skill}
                    key={skill.assessmentId}
                    userId={jobApplicationData.resumeId}
                  />
                ))}
            </div>
            <div className="w-full ">
              <GreenUnderlineHeading title="Positions" />
              {positions &&
                positions.length > 0 &&
                positions.map((position: any) => {
                  if (position.active === true) {
                    return (
                      <ApplicationPositionItem
                        key={position?.id}
                        jobId={jobApplicationData?.jobId}
                        applicationId={applicationId}
                        positionApplication={position}
                        applicationData={jobApplicationData}
                      />
                    );
                  }
                })}
            </div>
          </div>
          {/* 2nd Right */}
          <div className="">
            {/* having issues adding GreenUnderlineHeading here. The notes icon moves under instead of being on the same line */}
            {!rescindOffer && (
              <ExternalNotesApplicationView application={jobApplicationData} />
            )}
            {isShowingNote && (
              <GeneralNote
                onDone={handleDone}
                collection={collectionNames.applications}
                id={applicationId}
              />
            )}
          </div>
        </div>
      )}
      {rescindOffer ? (
        <DialogWrapper
          title="Decline Application"
          size="h-1/2 w-1/3"
          onClose={toggleRescindOffer}
        >
          <DeclineApplication
            collection={collectionNames.applications}
            toggleRescindApplication={toggleRescindOffer}
            passedApplicationId={applicationId}
          />
        </DialogWrapper>
      ) : null}
      <ToastAlert />
    </div>
  );
};

export default ExternalJobApplicantDetails;

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEmployer } from "../../../data/employer";
import { useJobDescription } from "../../../data/jobDescriptions";
import { useGlobalState } from "../../../providers/globalState/GlobalStateProvider";
import PositionMetrics from "../../../charts/positionsMetrics";
import JobApplicationTrends from "../../../charts/jobApplicationTrends";
import { Link } from "react-router-dom";
import SingularCount from "../../../charts/SingularCount";
import PositionApplicantMetrics from "../../../charts/positionsApplicantsMetrics";
import OffersMade from "../parts/OffersMade";
import useModal from '../../../hooks/useModal';
import { useAuth } from '../../../providers/auth';
import { useShareByTypeAndId } from '../../../data/share';
import DialogWrapper from '../../custom-ui/dialog/Dialog';
import EditSocialMeta from '../../socials/EditSocailMeta';
import JobListingSocials from '../parts/JobListingSocials';
import ApplicationCompletionMetrics from '../../../charts/applicationCompletionMetrics';


function JobOverview() {
  const params = useParams();
  const jobId = params.id ?? "";
  const { user } = useAuth();
  const { data: share = {} } = useShareByTypeAndId("job", jobId);
  const { globalState, setGlobalState } = useGlobalState();
  const { data: job = {} } = useJobDescription(jobId);
  const { data: employer = {} } = useEmployer(job?.employer);


  useEffect(() => {
    const title = job?.title ?? "";
    const employerName = employer?.displayName ?? "";
    if (title && employerName) {
      setGlobalState({
        ...globalState,
        pageTitle: employerName + " - " + title,
      });
    }
  }, [job?.title, employer?.displayName]);

  return (
    <div className="w-full pb-36">
      <div className="text-center grid grid-flow-col grid-cols-4 gap-4">
        <div className="col-span-2">
          <div className="grid grid-cols-4 gap-4">
            <SingularCount
              title={"New Applications"}
              collection={"Applications"}
              field={"jobId"}
              status={"ready"}
              name={"appsReceived"}
              id={params.id}
            />
            <SingularCount
              title={"Under Review"}
              collection={"Applications"}
              field={"jobId"}
              status={"underReview"}
              name={"appsUnderReview"}
              id={params.id}
            />
            <SingularCount
              title={"Ready To Present"}
              collection={"Applications"}
              field={"jobId"}
              status={"bench"}
              name={"appsReadyPresented"}
              id={params.id}
            />
            <SingularCount
              title={"Presented"}
              collection={"Applications"}
              field={"jobId"}
              status={"presented"}
              name={"appsPresented"}
              id={params.id}
            />
          </div>
          <ApplicationCompletionMetrics jobId={jobId} />
          <PositionMetrics jobId={jobId} />
          <PositionApplicantMetrics jobId={jobId} />
          <JobApplicationTrends jobId={jobId} />
        </div>
        <div className="col-span-2">
          <JobListingSocials id={jobId} />          
          <OffersMade jobId={jobId} />
        </div>
      </div>
    </div>
  );
}

export default JobOverview;

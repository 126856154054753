import { useEffect, useState } from "react";
import { Chat } from "../../dataTypes/Messages";
import {
  newestMessage,
  useAllMessagesByChatId,
  markChatRead,
} from "../../data/messages";
import { useUser } from "../../data/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { DisplayTime, DisplayTimestamp } from '../../util/timestamps/timestamp';

const SeekerMessageTabContents = ({ chat }: { chat: Chat }) => {
  const userId: string =
    localStorage.getItem("__user_id")?.replace(/"/g, "") || "";
  const[title, setTitle] = useState("");
  const[avatar, setAvatar] = useState("");
  const [lastMessage, setLastMessage] = useState({});
  const [messageIsUnread, setMessageIsUnread] = useState(
    chat?.unreadUsers?.includes(userId),
  );
  const { recipientId, id: chatId } = chat;
  const { data: recipient } = useUser(recipientId);
  const { data: messageData } = useAllMessagesByChatId(chatId);
  const { data: senderData } = useUser(lastMessage?.senderId);
  const { data: userData } = useUser(userId);

  
  const handleMessageData = async () => {
    const message = await newestMessage(chatId);
    setLastMessage(message?.data);
  };

  const handleClick = () => {
    markChatRead(userId, chatId);
  };

  useEffect(() => {
    setMessageIsUnread(chat?.unreadUsers?.includes(userId));
  }, [chat, userId]);

  useEffect(() => {
    handleMessageData();
  }, [recipientId, chatId, messageData]);

  const getTitle = () => {
    switch (chat?.group) {
      case 'tac':
         setTitle(`Talent Acquisition Consultant`);
         setAvatar("/images/message/tac.png");
         return;
      case 'csss':
        setTitle(`Career Seeker Support Specialist`);
        setAvatar("/images/message/csss.png");
        return;
      case 'sadmin':
        setTitle(`Technical Support`);
        setAvatar("/images/message/helpdesk.png");
        return;
      default:
        return '';
    }
  };

  useEffect(() => {
    getTitle();
  }, []);

  return (
    <div
      className="pb-2 pt-2 flex justify-center items-center cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-1/5 flex justify-center ">
        {avatar && (
          <img
            src={avatar}
            alt=""
            className={`rounded-full h-12 w-12 ${
              messageIsUnread ? " border-4 border-green-600" : ""
            }`}
          />
        )}        
      </div>
      <div className="w-4/5 pr-4 h-1/2">
        <div className="text-sm font-black flex items-center relative">
          {title}
        </div>
        <div className="text-xs truncate text-ellipsis ">
            {lastMessage?.body !== "" && (
              lastMessage?.body
            )}
            {lastMessage && lastMessage?.image !== undefined &&lastMessage?.image !== null && (
              <img src={lastMessage.image} className="w-8" />
            )}
            {lastMessage?.link !== null && lastMessage?.link !== undefined && (
              "Web Link"
            )}
            {lastMessage?.file !== null && lastMessage?.file !== undefined &&(
             "File Download"
            )}
        </div>
        <div className='flex-1 text-xxs'>{DisplayTimestamp(lastMessage?.createdAt)}</div>
      </div>
    </div>
  );
};

export default SeekerMessageTabContents;

import { useEffect, useState } from "react";
import { useAuth } from "../../../../providers/auth";
import { updateUser } from "../../../../data/user";
import { updateUserOnboardedStatus } from "../../../../data/user";

import MilitaryConnected from "./MilitaryConnected";
import ProgressDots from "../../../custom-ui/progress-dots/ProgressDots";
import Resume from "./Resume";
import SuccessCircleCheck from "../../../custom-ui/success/SuccessCircleCheck";
import WorkPreferences from "./WorkPreferences";
import AddressForm from "../../../address/AddressForm";
import { useNavigate } from "react-router-dom";

function SeekerOnboardingIndex() {
  const auth = useAuth();
  const userId = auth?.user?.id;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 4;
  const nextStep = () => setActiveStep((prevState: number) => ++prevState);
  const [countDown, setCountDown] = useState(7);

  useEffect(() => {
    if (totalSteps <= activeStep) {
      console.log(
        "Running update to onboarded status!!",
        totalSteps <= activeStep,
      );
      //set onboarding complete
      updateUserOnboardedStatus(userId, true)
        .then(() => {
          console.log("Done  onboarding");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep === 5) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
    if (countDown < 0) {
      auth.signout();
    }
  });

  const renderActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        return (
          <>
            <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
              Military-Connected?
            </h1>
            <MilitaryConnected userId={userId} handleDone={nextStep}>
              <div className="my-4">
                <ProgressDots totalSteps={totalSteps} activeStep={activeStep} />
              </div>
            </MilitaryConnected>
          </>
        );
      case 2:
        return (
          <>
            <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
              Address
            </h1>
            <AddressForm
              id={userId}
              buttonVariant="arrowButton"
              address={auth.user.address}
              addressLabel="primary"
              onSubmit={updateUser}
              onDone={nextStep}
            >
              <div className="flex flex-col justify-center items-center">
                <p className="xl:w-3/4 text-instant-teams-blue-Main italic text-center">
                  If you identified as a military-connected career seeker, your
                  State of Residence (provided on previous screen) and Physical
                  Location might be different. Please indicate current physical
                  Home Location on this screen.
                </p>
                <div className="my-4">
                  <ProgressDots
                    totalSteps={totalSteps}
                    activeStep={activeStep}
                  />
                </div>
              </div>
            </AddressForm>
          </>
        );
      case 3:
        return (
          <>
            <h1 className="mt-12 text-2xl font-bold mb-2 text-instant-teams-teal-Main">
              Work Preferences
            </h1>

            <WorkPreferences userId={userId} handleDone={nextStep}>
              <div className="my-4">
                <ProgressDots totalSteps={totalSteps} activeStep={activeStep} />
              </div>
            </WorkPreferences>
          </>
        );
      case 4:
        return (
          <>
            <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
              Resume
            </h1>
            <Resume userId={userId} handleDone={nextStep}>
              <div className="my-4">
                <ProgressDots totalSteps={totalSteps} activeStep={activeStep} />
              </div>
            </Resume>
          </>
        );
      case 5:
        return (
          <>
            <h1 className="mt-12 text-2xl  font-bold pb-8 text-instant-teams-teal-Main">
              Complete!
            </h1>
            <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
              <SuccessCircleCheck message="">
                <div className="text-instant-teams-gray-D1 text-center">
                  <p className="mb-4">
                    Thanks for joining us and completing your initial account
                    set up.
                  </p>
                  <p>Please sign in to your account to view available jobs.</p>
                  <p className="mt-2">{`You will be redirected to login in ${countDown} seconds.`}</p>
                </div>
              </SuccessCircleCheck>
            </div>
          </>
        );
    }
  };
  return (
    <>
      <div className="flex justify-center min-h-screen bg-white overflow-hidden">
        <div className="m-4 items-center  w-1/2 xs:w-full sm:w-full flex flex-col  md:w-1/2  justify-center">
          {renderActiveStep(activeStep)}
        </div>
      </div>
    </>
  );
}

export default SeekerOnboardingIndex;

import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { wordpressClient } from "../../external/wordpress/wordpressClient";
import Meta from "../../components/custom-ui/meta/Meta";
import { useAuth } from "../../providers/auth";
import GeneralInfoPostDisplay from "../../components/general-info/GeneralInfoPostDisplay";
import { Tab } from "@headlessui/react";

function GeneralInfoPage() {
  const [nodes, setNodes] = useState();
  const usertype = useAuth()?.user?.claims?.type;
  const parent = usertype === "A" ? 6 : usertype === "E" ? 7 : 5;
  const parentInt = Number.isInteger(parent)
    ? parent
    : Math.floor(parseFloat(parent));

  const getNodes = gql`
    query nodeQuery($parent: Int!) {
      categories(where: { parent: $parent }) {
        edges {
          node {
            id
            name
            posts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(getNodes, {
    variables: { parent: parentInt },
    client: wordpressClient,
  });

  useEffect(() => {
    setNodes(data?.categories?.edges);
  }, [data]);

  return (
    <div className="h-screen">
      <Meta title="Frequently Asked Questions" />
      <h3>Hello World</h3>
      <Tab.Group>
        <div className="flex mt-40 mx-auto  h-3/4 w-4/5 ">
          <Tab.List className="flex flex-col h-full w-full items-start basis-1/4 bg-instant-teams-teal-Main text-white rounded-2xl text-xl pt-4">
            {nodes &&
              nodes?.length > 0 &&
              nodes.map(({ node, index }) => (
                <Tab
                  key={node?.id}
                  className={({ selected }) =>
                    `${
                      selected
                        ? "bg-instant-teams-teal-D1 text-white border-l-4 border-0 border-green-400"
                        : ""
                    } text-left hover:bg-instant-teams-teal-D1 h-12 w-full pl-4 hover:border-l-4 hover:border-green-400`
                  }
                  // onClick={() => setSelectedTab(index)}
                >
                  {node?.name}
                </Tab>
              ))}
          </Tab.List>

          <Tab.Panels className="basis-3/4 h-full no-scrollbar overflow-scroll ">
            {nodes &&
              nodes?.length > 0 &&
              nodes.map(({ node }) => {
                if (node?.posts?.edges && node?.posts?.edges?.length > 0) {
                  return (
                    <Tab.Panel
                      key={node?.id}
                      className="flex flex-col pl-10 h-auto overflow-visible"
                    >
                      <GeneralInfoPostDisplay
                        posts={node?.posts?.edges}
                        key={node?.id}
                      />
                    </Tab.Panel>
                  );
                }
                return <Tab.Panel>No Data</Tab.Panel>;
              })}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}

export default GeneralInfoPage;

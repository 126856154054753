import React, { useEffect } from 'react';
import {useEnrollmentSyncFailures} from "../../../data/academy";
import { DisplayTimestamp } from '../../../util/timestamps/timestamp';
import GreenUnderlineHeading from '../../custom-ui/headings/heading';

function EnrollmentSyncFailures() {
  const { data: failures } = useEnrollmentSyncFailures();

  useEffect(() => {
    console.log("SYNC Failures: ",failures);
  }, [failures]);

  return (
    <>
      <div className="w-full">
        <GreenUnderlineHeading title={"Enrollment Sync Failures"} />
        <div className="w-full">
          {failures && failures?.length > 0 ?
            failures.map((value, index) => {
              const href = "/learning/assessment/" + value.assessmentId + "/test-results"
              return (
                <div key={index} className="w-full border-b border-gray-300">
                  <p><a className="text-red-600" href={href} target={"_blank"}>{value.name}</a> - {DisplayTimestamp(value?.timestamp)}</p>
                  <p className='mb-2'>{value?.error || "No Error Listed"}</p>
                </div>
              );
            }):(
              <p>No Failures</p>
            )}
        </div>
      </div>
    </>
  );
}

export default EnrollmentSyncFailures;

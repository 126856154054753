import Meta from "../custom-ui/meta/Meta";
import GreenUnderlineHeading from "../custom-ui/headings/heading";
import { useLookup, useFindLookup } from "../../data/lookup";
import { LookUp } from "../../dataTypes/LookUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTimeZone, getTimeFromMinute } from "../../util/timezone/timezone";
import { useJobPositionDetails } from "../../data/positions";
import { sanitizeMarkup } from "../../util/sanitize/sanitize-html";
import "../../styles/dangerouslySet.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { formatMoney } from '../../util/currency/currency';
import { useEmployerLocation } from '../../data/employer';


function JobListingSharePosition({position}:{position: any}) {
  const{ data: classification = [] } = useFindLookup("classification", position?.information?.classification);
  const{ data: model = [] } = useFindLookup("model", position?.information?.model);
  const{ data: location } = useEmployerLocation(position.employer, position?.information?.locationId);
  const [payData, setPayData] = useState([]);
  const [classLabel, setClassLabel] = useState("");
  const [modelLabel, setModelLabel] = useState("");
  const [shiftScheduleStart, shiftScheduleEnd] = position?.shiftSchedule
    ?.range ?? [undefined, undefined];

    useEffect(() => {
      const information = position?.information;
      const payType = information?.payType;
      const payCollection = [];
      if(payType.hasOwnProperty("SAL")) {
        const rec = {
          title: "Salary",
          display: `$${formatMoney(payType.SAL[0], 0, ".", ",")} - $${formatMoney(payType.SAL[1], 0, ".", ",")}`,
        };
        payCollection.push(rec);
      }
      if(payType.hasOwnProperty("HRLY")) {
        const rec = {
          title: "Hourly",
          display: `$${formatMoney(payType.HRLY[0], 2, ".", ",")} - $${formatMoney(payType.HRLY[1], 2, ".", ",")}`,
        };
        payCollection.push(rec);
      }
      if(payType.hasOwnProperty("COMM")) {
        const rec = {
          title: "Commission",
          display: `${payType.COMM}`
        };
        payCollection.push(rec);
      }
      setPayData(payCollection);
    }, [position]);

    useEffect(() => {
      if(classification.length > 0) {
        setClassLabel(classification[0].label);
      }
    },[classification]);
    
    useEffect(() => {
      if(model.length > 0) {
        setModelLabel(model[0].label);
      }
    },[model]);

  // render matched schedule
  const day = (hours: LookUp) => (
    <div className="w-full" key={hours?.id}>
      <p><span className='min-w-12 w-12 mr-4 text-right inline-block'>{hours?.code}</span>{`${getTimeFromMinute(shiftScheduleStart)} - ${getTimeFromMinute(
          shiftScheduleEnd,
        )}`}<span className='min-w-12 w-12 ml-4 text-left inline-block'>{getTimeZone(location?.timezone?.id)}</span></p>
    </div>
  );

  return (
    <div className="w-full rounded-xl h-full shadow-xl">
      <div className="font-semibold text-center text-xl mt-4 bg-instant-teams-teal-Main p-2 rounded-t-xl text-white">{position?.positionName}</div>
      <div className="px-6">
        <div className="font-semibold text-xl text-instant-teams-blue-Main text-center grid grid-cols-2 gap-4 mt-4 px-6">
          <div>{classLabel}</div>
          <div>{modelLabel}</div>
          <div className="text-center min-h-20 col-span-2">
            {payData?.map((pay: any) => {
              return (
                <div key={pay.title} className="w-full">
                  {pay.title === "Commission"? (
                    <>
                      <p className="w-full">plus {pay.title}</p>
                    </>
                  ) : (
                    <p className="w-full">{pay.title} - {pay.display}</p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
          <div className="uppercase text-xl text-center w-full mt-4 text-instant-teams-teal-Main font-bold mb-4">
            Required Hours
          </div>
          {position?.shiftSchedule?.shiftOption?.code !== "CUSTOM" &&
            position?.shiftSchedule?.days &&
            position?.shiftSchedule?.days.length > 0 && (
              <div className="w-full items-center text-center mb-12">
                {position?.shiftSchedule?.days?.map(day)}
              </div>
            )} 
          {position?.shiftSchedule?.shiftOption?.code === "CUSTOM" && (
            <div className="w-full items-center text-center mb-12">
              <div
                className="dangerously"
                dangerouslySetInnerHTML={sanitizeMarkup(
                  position?.shiftSchedule?.shiftOption?.description,
                )}
              ></div>
            </div>
          )}    
      </div>      
    </div>
  );
}

export default JobListingSharePosition;

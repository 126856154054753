import { useState } from "react";
import ForwardArrowButton from "../../../custom-ui/button/ForwardArrowButton";
import { useUserByEmail } from "../../../../data/user";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { errorLogger } from "../../../../providers/clientLogger";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useLookup } from "../../../../data/lookup";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { Origin, AuthStatus } from "../../../../dataTypes/AuthOrigin";

import { createAuthOrigin, useAuthOrigin } from "../../../../data/authOrigin";

function SeekerInfo({
  onDone,
  children,
}: {
  onDone: Function;
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [emailCheckTrigger, setEmailCheckTrigger] = useState(false);

  const [authOriginQueryTrigger, setAuthOriginQueryTrigger] = useState(false);

  const seekerInfoSchema = z.object({
    email: z.string().email({ message: "Please enter valid email" }),
    name: z
      .string({ required_error: "Name is required" })
      .nonempty("Name is required"),
    preferredName: z.string().min(1, "Password confirmation is required"),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms & conditions" }),
    }),
    phone: z.string(),
    referredBy: z.string(),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    control,
    watch,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      preferredName: "",
      //terms: false,
      phone: "",
      referredBy: "",
    },
    resolver: zodResolver(seekerInfoSchema),
  });
  const watchEmail = watch("email") ?? "";
  const {
    data: partnerList = [],
    status: partnerQueryStatus = "",
    error: partnerQueryError,
  } = useLookup("partner");

  const { data: authOriginRecord = [] } = useAuthOrigin(
    watchEmail?.toLowerCase(),
    Origin.seekerWeb,
    authOriginQueryTrigger,
  );

  const saveSeekerInfoAndAdvanceForm = (data: SeekerInfoSchema) => {
    setPending(true);
    const hasAuthOriginRecord = !!authOriginRecord.length;
    // write to AuthOrigin if a record does not exist, then
    // set seekerInfo to state and proceed to next step
    if (!hasAuthOriginRecord) {
      const { email } = data;
      createAuthOrigin({
        email: email.toLowerCase(),
        origin: Origin.seekerWeb,
        status: AuthStatus.start,
        lastUpdated: Timestamp.now(),
      })
        .then(() => {
          onDone(data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setPending(false));
      return;
    }
    // an authOrigin record already exists.
    // set seekerInfo to state and proceed to next step.

    onDone(data);
    setPending(false);
  };

  const { data: existingEmail } = useUserByEmail(
    watchEmail.toLowerCase(),
    emailCheckTrigger,
  );

  const emailExists = () => {
    if (Array.isArray(existingEmail)) {
      return !!existingEmail.length;
    }
    return false;
  };

  type SeekerInfoSchema = z.infer<typeof seekerInfoSchema>;

  return (
    <div className="w-full">
      {formAlert && (
        <div className="mb-4 text-red-600">{formAlert.message}</div>
      )}
      <form
        onSubmit={handleSubmit(saveSeekerInfoAndAdvanceForm)}
        autoComplete="off"
      >
        <div className="mb-2">
          <input
            className="py-1 px-3 w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            placeholder="Email"
            type="email"
            name="email"
            ref={register({
              required: "Please enter an email",
            })}
            onBlur={() => {
              setEmailCheckTrigger(true);
              setAuthOriginQueryTrigger(true);
            }}
            onFocus={() => setEmailCheckTrigger(false)}
          />

          {errors.email && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
        {emailExists() && (
          <div className="my-2 p-2 rounded bg-amber-200 ">
            <p className="text-center text-sm font-bold">
              Looks like you may already have an account.
            </p>
            <button
              onClick={() => {
                navigate("/auth/signin");
              }}
              className="text-instant-teams-grey-D1 underline background-transparent px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
              Login with your <span className="font-bold">Instant Teams</span>{" "}
              account or <span className="font-bold">12 Million Plus</span>{" "}
              Account
            </button>
          </div>
        )}
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.name ? "border-red-600" : ""
            }`}
            placeholder="Full Name"
            name="name"
            type="text"
            ref={register({ required: "Please enter your full name." })}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>
        <div className="mb-2">
          <input
            className={`py-1 px-3 w-full leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
              errors.name ? "border-red-600" : ""
            }`}
            placeholder="Preferred Name"
            name="preferredName"
            type="text"
            ref={register()}
          />
        </div>
        <div className="mb-2">
          <PhoneInputWithCountry
            className="py-1 px-3 w-full rounded-md leading-8 bg-white border border-gray-300 outline-none focus:border-instant-teams-blue-Main focus:ring-1"
            name="phone"
            international
            defaultCountry="US"
            countryCallingCodeEditable={false}
            control={control}
            rules={{ required: true }}
          />

          {errors.phone && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.phone.message}
            </p>
          )}
        </div>

        <div className="flex flex-col items-center w-full">
          <select
            className="py-1 px-3 w-full leading-8  rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            name="referredBy"
            ref={register()}
          >
            <option selected disabled value="">
              How did you hear about us?
            </option>
            {partnerList.map((referral: any) => {
              return (
                <option key={referral.id} value={referral.id}>
                  {referral.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mt-4 mb-2 ">
          <div className="flex items-start ">
            <input
              className={`py-3 px-3 leading-8 bg-white rounded border border-gray-300 outline-none focus:border-blue-500 focus:ring-1 ${
                errors.terms ? "border-red-600" : ""
              }`}
              id="terms"
              type="checkbox"
              name="terms"
              ref={register({
                required: "Please agree to our terms of service.",
              })}
            />
            <label
              htmlFor="terms"
              className="ml-2 text-left text-instant-teams-gray-D1"
            >
              <span>By creating an account, you agree to our </span>
              <br />
              <a
                className="underline"
                href={
                  "https://instantteams.com/marketplace-terms-and-conditions/"
                }
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>{" "}
              <span>and have read our </span>
              <a
                className="underline"
                href={"https://instantteams.com/privacy-policy/"}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {errors.terms && (
            <p className="mt-1 text-sm text-center text-red-600">
              {errors.terms.message}
            </p>
          )}
        </div>
        <div className="flex flex-col justify-center items-center">
          {children}
          <ForwardArrowButton pending={pending} type="submit" />
        </div>
      </form>
    </div>
  );
}

export default SeekerInfo;

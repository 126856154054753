import { useRef } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { createUser } from "../../../../providers/database";
import {
  addEmployerDepartment,
  useEmployerUsers,
} from "../../../../data/employer";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../../../providers/auth";
import { useCurrentUserEmployerId } from "../../../../hooks/useCurrentUserEmployerId";

const CreateEmployerDepartment = (props: any) => {
  const userEmployerId = useCurrentUserEmployerId();
  const auth = useAuth();
  const params = useParams();
  const [name, setName] = useState("");
  const [manager, setManager] = useState(null);
  const {
    data: employees = [],
    status: employeesStatus,
    error: employeesError,
  } = useEmployerUsers(params.employerId ?? userEmployerId);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    addEmployerDepartment(params.employerId ?? userEmployerId, {
      label: name,
      manager: manager,
      active: true,
      createdAt: Timestamp.now(),
      createdBy: auth.user.id,
    });
    setName("");
    setManager(null);
    props.closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-2 mb-4">
        <div className="flex gap-1 items-center">
          <input
            type="text"
            placeholder="Name"
            className="w-full shadow-md"
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-1 items-center">
          <label>Manager</label>
        </div>
        <div className="flex gap-1 items-center mb-4">
          <select
            onChange={(e) => {
              setManager(e.target.value);
            }}
            className="w-full shadow-md"
          >
            <option
              label={"Please select an employee"}
              selected={true}
              disabled={true}
              hidden={true}
            ></option>
            {Object.keys(employees).length ? (
              employees.map((employee: any) => (
                <option value={employee.id} key={employee.id}>
                  {employee.preferredName}
                </option>
              ))
            ) : (
              <option disabled={true}>N/A</option>
            )}
          </select>
        </div>
        <button
          type="submit"
          className=" bg-instant-teams-teal-Main mx-auto w-1/2 text-white rounded-md text-lg"
        >
          Create Department
        </button>
      </div>
    </form>
  );
};

export default CreateEmployerDepartment;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getJobOffer } from "../../../data/offers";
import Meta from "../../custom-ui/meta/Meta";
import DialogWrapper from "../../custom-ui/dialog/Dialog";
import useModal from "../../../hooks/useModal";
import RescindOfferModal from "../parts/RescindOfferModal";
import { collectionNames } from "../../../data/dictionary/collectionNames";

const OfferLetterPreview = () => {
  const { offers } = collectionNames;
  const params = useParams();
  const jobOfferId = params.offerId;
  const [offer, setOffer] = useState({});
  const [viewOffer, setViewOffer] = useState(false);
  const { isShowing: rescindOffer, toggle: toggleRescindOffer } = useModal();

  useEffect(() => {
    async function fetchData() {
      let res = await getJobOffer(jobOfferId || "");
      setOffer(res);
    }
    if (jobOfferId) {
      fetchData();
    }
  }, [jobOfferId]);

  useEffect(() => {
    if (offer && offer?.embeddedPreviewUrl) {
      setViewOffer(true);
      // @ts-ignore
      const signWellEmbed = new window.SignWellEmbed({
        url: offer?.embeddedEditUrl,
        containerId: "signwellContainer",
        events: {
          completed: (e: any) => {
            console.log("completed event: ", e);
          },
          closed: (e: any) => {
            console.log("closed event: ", e);
          },
        },
      });
      signWellEmbed.open();
    } else {
      setViewOffer(false);
    }
  }, [offer]);

  return (
    <>
      <Meta title="Offer Letter" />
      <div className=" h-screen pt-36 pr-36 overflow-hidden pl-36">        
        {viewOffer ? (
          <>
            <button
              onClick={toggleRescindOffer}
              className=" mb-4 flex ml-auto py-2 px-24 bg-instant-teams-blue-Main uppercase text-white rounded-md cursor-pointer hover:text-instant-teams-blue-Main hover:border hover:bg-white hover:border-instant-teams-blue-Main"
            >
              Rescind Offer
            </button>
            <div id="signwellContainer" className="h-screen"></div>
          </>
        ):(
          <div>No Offer Available</div>
        )}
        
      </div>
      {rescindOffer ? (
        <DialogWrapper
          title="Rescind Offer"
          size="h-1/2 w-1/3"
          onClose={toggleRescindOffer}
        >
          <RescindOfferModal
            collection={offers}
            toggleRescindOffer={toggleRescindOffer}
          />
        </DialogWrapper>
      ) : null}
    </>
  );
};

export default OfferLetterPreview;

import Meta from "../../components/custom-ui/meta/Meta";
import SeekerCompanyItem from "../../components/seekerView/SeekerCompanyItem";
import WedgeSpinner from "../../components/spinners/wedges-spinner";
import { useAllActiveEmployers, useAllEmployers } from "../../data/employer";
import { requireAuth } from "../../providers/auth";

function SeekerCompaniesPage() {
  const {
    data: employerData,
    status: employerStatus,
    isLoading,
  } = useAllActiveEmployers({});

  if (isLoading)
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <WedgeSpinner />
      </div>
    );
  return (
    <>
      <Meta title="Companies" />
      <section className="pt-40 pb-16 w-3/5 mx-auto flex flex-col place-items-center space-y-2">
        {employerData &&
          employerData.map((employer: any) => {
            return <SeekerCompanyItem employer={employer} />;
          })}
      </section>
    </>
  );
}

export default requireAuth(SeekerCompaniesPage);

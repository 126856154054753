import { useState } from "react";
import { useAuth } from "../../providers/auth";
import { useUser } from "../../data/user";
import { cancelMeeting, useMeetingsOneOnOne } from "../../data/meetings";
import useModal from "../../hooks/useModal";
import DialogWrapper from "../custom-ui/dialog/Dialog";
import ScheduleMeetingOneOnOne from "./ScheduleMeetingOneOnOne";
import SuccessCircleCheck from "../custom-ui/success/SuccessCircleCheck";
import { trim } from "lodash";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";

function ScheduleMeetingActions({
  refType,
  refId,
  inviteeId,
  title,
  extended,
}: {
  refType: string;
  refId: string;
  title: string;
  extended: any;
  inviteeId: string;
}) {
  // logged in user
  const { user: authUser } = useAuth();
  // determine if the logged in user has any "active" meetings scheduled with the current invitee being viewed
  // an active interview has a status of "pending", "sent", "scheduled", or "reschedule"
  // if the logged in user has no meetings with this seeker then allow them to schedule a meeting

  const { data: meetingData = [], status: meetingQueryStatus } =
    useMeetingsOneOnOne({
      refId,
      refType,
      invitedBy: authUser.id,
      inviteeId,
    });

  // invitee
  const { data: inviteeData } = useUser(inviteeId);

  const {
    status: meetingStatus,
    meetingDetails,
    id: meetingId,
    calendly, // calendly event
  } = meetingData[0] ?? {};

  const { event } = calendly ?? {};

  const isCalendlyEvent = !!event;

  console.log(inviteeData);
  console.log(meetingData);

  const { toggle: toggleDialog, isShowing: isShowingDialog } = useModal();
  const {
    toggle: toggleCancelRescheduleDialog,
    isShowing: isShowingCancelRescheduleDialog,
  } = useModal();

  const [cancelOrReschedule, setCancelOrReschedule] = useState();
  const [cancelOrRescheduleReason, setCancelOrRescheduleReason] = useState();

  const cancelScheduledMeeting = () => {
    cancelMeeting({
      meetingId: meetingId,
      status: "cancelled",
      cancelReason: trim(cancelOrRescheduleReason, "\n"),
    })
      .then(() => {
        toggleCancelRescheduleDialog();
        console.log("Cancelled meeting");
      })
      .catch((error) => {
        console.log("Problem cancelling meeting: ", error);
      });
  };

  const handleSuccessfullInvitation = () => {
    toast.success("Invitation Sent");
    toggleDialog();
  };

  switch (meetingStatus) {
    case "pending":
      return (
        <>
          <button
            disabled={true}
            className="w-full  min-h-12 h-12 text-white uppercase bg-instant-teams-gray-D1 rounded border-0  focus:outline-none"
          >
            Sending Invitation...
          </button>
        </>
      );
    case "sent":
      return (
        <button
          disabled={true}
          className="w-full  min-h-12 h-12 text-white uppercase bg-instant-teams-gray-D1 rounded border-0  focus:outline-none"
        >
          Awaiting Response
        </button>
      );
    case "reschedule":
    case "scheduled":
      return (
        <>
          {meetingStatus === "reschedule" && (
            <div className="mb-2 text-instant-teams-teal-D2 font-bold">
              <p>
                A request to reschedule this meeting has been made. Cancel this
                meeting to reschedule.
              </p>
            </div>
          )}
          <button
            className="w-full  min-h-12 h-12 text-white uppercase bg-instant-teams-red-Main rounded border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
            onClick={() => {
              setCancelOrReschedule("cancel");
              toggleCancelRescheduleDialog();
            }}
          >
            Cancel Interview
          </button>
          {isShowingCancelRescheduleDialog && (
            // This dialog handles both cancellation and rescheduling
            <DialogWrapper
              title="Cancel/Reschedule Meeting"
              onClose={() => {
                toggleCancelRescheduleDialog();
                // clear reason
                setCancelOrRescheduleReason("");
              }}
            >
              <>
                {cancelOrReschedule === "cancel" && (
                  <div>
                    <p className="mb-4 text-instant-teams-teal-D2 font-bold text-xl">
                      Are you sure that you want to{" "}
                      <span className="text-instant-teams-red-Main">
                        cancel
                      </span>{" "}
                      your meeting with {inviteeData?.name}?
                    </p>
                    <p className="text-instant-teams-teal-D2 font-bold text-lg">
                      {meetingDetails?.title}
                    </p>

                    <div className="mt-10 ">
                      <label className="sr-only">Reason for decline</label>
                      <textarea
                        placeholder={`Add an optional message `}
                        className="w-full rounded-xl leading-8"
                        onChange={(e) =>
                          setCancelOrRescheduleReason(e.target.value)
                        }
                      >
                        {cancelOrRescheduleReason}
                      </textarea>
                    </div>
                    <div className="mt-5 flex  space-x-2">
                      <button
                        className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-red-Main rounded border-0 hover:bg-instant-teams-red-L1 focus:outline-none"
                        onClick={cancelScheduledMeeting}
                      >
                        Yes Cancel
                      </button>
                      <button
                        className="w-full mb-5 min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                        onClick={toggleCancelRescheduleDialog}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </>
            </DialogWrapper>
          )}
        </>
      );

    // No meeting data was returned for this meeting context, no meeting exists, show schedule
    default:
      return (
        <>
          <button
            className={
              refType === "application-meeting"
                ? "uppercase w-full min-h-12 h-12 text-white bg-instant-teams-blue-Main rounded border-0 hover:bg-instant-teams-blue-D1 focus:outline-none"
                : " uppercase w-3/4 min-h-12 h-12 text-instant-teams-blue-Main rounded bg-white border-2 border-instant-teams-blue-Main hover:bg-instant-teams-blue-D1 hover:text-white focus:outline-none"
            }
            onClick={toggleDialog}
          >
            {refType === "meeting" && "SCHEDULE MEETING"}
            {refType === "application-meeting" && " Schedule Live Interview"}
          </button>
          {isShowingDialog && inviteeId && (
            <DialogWrapper
              title="Schedule Interview"
              onClose={() => {
                toggleDialog();
              }}
            >
              <ScheduleMeetingOneOnOne
                refType={refType}
                refId={refId}
                title={title}
                inviteeId={inviteeId}
                onSuccess={handleSuccessfullInvitation}
                extended={extended}
              />
            </DialogWrapper>
          )}
          <ToastAlert />
        </>
      );
  }
}

export default ScheduleMeetingActions;

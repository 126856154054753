/* eslint-disable no-unused-vars */
import { getValue } from "firebase/remote-config";

import { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";

import { useRole } from "../../hooks/useRole";

import ExternalLinkIcon from "../custom-ui/icons/ExternalLinkIcon";

function Footer() {
  const { userClaims, fetchVersionNumber, remoteConfig, user } = useAuth();
  // const [role, setRole] = useState("");
  const [versionNumber, setVersionNumber] = useState("");
  const [uid, setUid] = useState(null);

  const getRole = useRole();
  const role = getRole(userClaims?.claims?.role, true);
  useEffect(() => {
    setUid(user?.uid);
  }, [user]);

  useEffect(() => {
    const getVersionNumber = fetchVersionNumber();

    getVersionNumber.then(() => {
      setVersionNumber(getValue(remoteConfig, "version_number"));
    });

    return () => setVersionNumber("");
  }, [remoteConfig, fetchVersionNumber]);

  return (
    <footer className="flex justify-between text-xs fixed bottom-0 left-0 right-0 top-auto h-6 py-1 px-3 bg-instant-teams-teal-Main text-instant-teams-gray-Main z-50">
      <div className="flex">
        <div>Instant Teams</div>
        <div>
          {userClaims?.claims != undefined ? (
            <div>{`- ${role} - ${uid !== null ? uid : ""}`}</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex">
        <div className="pr-2 ">
          <ExternalLinkIcon
            linkText="Terms & Conditions"
            href="https://instantteams.com/marketplace-terms-and-conditions/"
          />
        </div>
        <div className="">
          <ExternalLinkIcon
            linkText="Privacy Policy"
            href="https://instantteams.com/privacy-policy/"
          />
        </div>
      </div>
      {/* TODO: fetch from DB */}
      <div className="">{versionNumber?._value}</div>
    </footer>
  );
}

export default Footer;
